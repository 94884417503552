import React, { useEffect, useState } from 'react'
import { Button, Input, message, Modal, Select, Spin } from 'antd'
import { connect } from 'react-redux'
import { getDataAction, getHouseDataAction, getHousesAction, handleInteriorDesignAction, sendNotificationAction, updateUserAction } from '../../redux/dataDuck'
import text from '../text'
import { Link } from 'react-router-dom'
import ReactImageMagnify from 'react-image-magnify'
import ConfirmationModal from '../common/ConfirmationModal'
import { handleConfirmationBoxAction } from '../../redux/appDuck'
import { Document, Image, Page, PDFViewer, StyleSheet, Text, View } from '@react-pdf/renderer'
let { Option } = Select

const InteriorDesign = ({sendNotification, info, dataUpdated, history, fetching, language, getData, updateUser, handleInfo, toggleModal, getHouse, house, fetchingHouse}) => {

    let [background, setBackground] = useState('/img/intDesign/countertops/GraphiteCemento.jpg')
    let [docModal, setDocModal] = useState(false)

    let handleSubmit = () => {
        if( 
            !info.kitchenCountertop ||
            !info.bathroomCountertop ||
            !info.bathroomCabinet || 
            !info.kitchenBottomCabinet ||
            !info.kitchenTopCabinet
            ) return message.error(text.interiorDesign.missingSelection[language])
        toggleModal()
    }

    let confirmAction = async () => {
        sendNotification('intDesign')
        await updateUser()
        toggleModal()
        handleModal()
    }

    let handleModal = () => {
        Modal.success({
            title: text.interiorDesign.thanksMessage[language],
            onOk() {
                setDocModal(true)
            }
        })
    }

    let handleDoc = () => {
        if( 
            !info.kitchenCountertop ||
            !info.bathroomCountertop ||
            !info.bathroomCabinet || 
            !info.kitchenBottomCabinet ||
            !info.kitchenTopCabinet
            ) return message.error(text.interiorDesign.missingSelection[language])
        setDocModal(true)
    }

    let handleBackground = (option, value) => {
        switch(value){
            case 'Aniz':
                handleInfo(option, value)
                return setBackground('/img/intDesign/cabinets/aniz.jpg')
            case 'Avellana':
                handleInfo(option, value)
                return setBackground('/img/intDesign/cabinets/avellana.jpg')
            case 'Nogal':
                handleInfo(option, value)
                return setBackground('/img/intDesign/cabinets/nogal.jpg')
            case 'Paja':
                handleInfo(option, value)
                return setBackground('/img/intDesign/cabinets/paja.jpg')
            case 'Rattan':
                handleInfo(option, value)
                return setBackground('/img/intDesign/cabinets/rattan.jpg')
            case 'Graphite Cemento':
                handleInfo(option, value)
                return setBackground('/img/intDesign/countertops/GraphiteCemento.jpg')
            case 'Limestone':
                handleInfo(option, value)
                return setBackground('/img/intDesign/countertops/Limestone.jpg')
            case 'Ornamental':
                handleInfo(option, value)
                return setBackground('/img/intDesign/countertops/Ornamental.jpg')
            case 'Santa Cecilia':
                handleInfo(option, value)
                return setBackground('/img/intDesign/countertops/SantaCecilia.jpg')
            case 'Sand White':
                handleInfo(option, value)
                return setBackground('/img/intDesign/countertops/SandWhite.jpg')
            case 'Snow White':
                handleInfo(option, value)
                return setBackground('/img/intDesign/countertops/SnowWhite.jpg')
            case '':
                handleInfo(option, value)
                return 
            // case 'Aspen Beige':
            //     handleInfo(option, value)
            //     return setBackground('/img/intDesign/demo/floorDemoA.webp')
            // case 'Nevada Gris':
            //     handleInfo(option, value)
            //     return setBackground('/img/intDesign/demo/floorDemoB.webp')
            // case 'Emblem Grey':
            //     handleInfo(option, value)
            //     return setBackground('/img/intDesign/demo/floorDemoC.webp')
            // case 'Emblem Brown':
            //     handleInfo(option, value)
            //     return setBackground('/img/intDesign/demo/floorDemoD.webp')
            // case 'Khalos':
            //     handleInfo(option, value)
            //     return setBackground('/img/intDesign/demo/tileExtraDemo.webp')
            // case 'sameAsFloor':
            //     if(info.floor) {
            //         handleInfo(option, info.floor)
            //         return handleBackground(info.floor)
            //     }
            //     return message.error(text.interiorDesign.missingFloorSelect[language])
            default: 
                return
        }
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        if(info.lotId >= 0) getHouse()
    }, [info.lotId])

    const styles = StyleSheet.create({
        page: {
          flexDirection: 'column',
          backgroundColor: 'white',
          justifyContent: 'flex-start',
          padding: 16,
          width: '100%'
        },
        section: {
          marginTop: 10,
          padding: '0 10px'
        },
        title: {
            fontSize: 17,
            fontWeight: 'bold',
            marginBottom: 0
        },  
        dataText: {
            fontSize: 11,
            margin: '10px 0',
            width: '100%',
            fontWeight: 800
        },
        text: {
            fontSize: 11,
            margin: '5px 10px',
            width: '100%',
            fontWeight: 800
        },
        subtitle: {
            marginTop: 10,
            fontSize: 10,
            margin: '5px 0',
            textDecoration: 'underline'
        },
        firstText: {
            fontSize: 10,
            fontWeight: 300
        },
        logoContainer: {
            width: '100%'
          },
        logo: {
            width: '120px',
            margin: '0 auto',
            backgroundColor: 'white'
          },
          option: {
              width: '50px',
              backgroundColor: 'grey'
          }
      })

    return (
        <div className='application-container'>
             {
                fetching ? 
                    <div className='spinner-full-page'><Spin size='large'/></div>
                    :
                    <div className="application-content no-justify">
                        <div>
                            <div className="app-top-icon">
                                <img src="/img/sidebar/intDesign.svg" alt="profile"/>
                                <p>{text.sidebar.interiorDesign[language]}</p>
                            </div>
                            <h2>{text.sidebarSubtitle.interiorDesign[language]}</h2>
                        </div>
                        
                        <div className='padded-app-container form-container'>
                            <div className="int-design-container">
                                <div className="int-design-left">
                                    <ReactImageMagnify
                                    {...{
                                        smallImage: {
                                            alt: 'Demo',
                                            isFluidWidth: true,
                                            src: background
                                        },
                                        largeImage: {
                                            src: background,
                                            width: 1200,
                                            height: 1800
                                        },
                                        enlargedImageContainerDimensions: {
                                            width: 300,
                                            height: '100%'
                                        }
                                    }} 
                                    />
                                </div>
                                <div className="int-design-right">
                                    <div className='int-design-select'>
                                        <p>{text.interiorDesign.kitchenCabinets[language]}</p>
                                        <small>{text.interiorDesign.topCabinet[language]}</small>
                                        <div className="sample-container">
                                            <div className={info.kitchenTopCabinet === 'Aniz' ? 'sample-item active-sample' : 'sample-item'} onClick={() => handleBackground('kitchenTopCabinet', 'Aniz')} style={{backgroundImage: "url(/img/intDesign/cabinets/aniz.jpg"}}>
                                                <p>Aniz</p>
                                            </div>
                                            <div className={info.kitchenTopCabinet === 'Avellana' ? 'sample-item active-sample' : 'sample-item'} onClick={() => handleBackground('kitchenTopCabinet', 'Avellana')} style={{backgroundImage: "url(/img/intDesign/cabinets/avellana.jpg"}}>
                                                <p>Avellana</p>                                
                                            </div>
                                            <div className={info.kitchenTopCabinet === 'Nogal' ? 'sample-item active-sample' : 'sample-item'} onClick={() => handleBackground('kitchenTopCabinet', 'Nogal')} style={{backgroundImage: "url(/img/intDesign/cabinets/nogal.jpg"}}>
                                                <p>Nogal</p>
                                            </div>
                                            <div className={info.kitchenTopCabinet === 'Paja' ? 'sample-item active-sample' : 'sample-item'} onClick={() => handleBackground('kitchenTopCabinet', 'Paja')} style={{backgroundImage: "url(/img/intDesign/cabinets/paja.jpg"}}>
                                                <p>Paja</p>
                                            </div>
                                        </div>
                                        <small>{text.interiorDesign.bottomCabinet[language]}</small>
                                        <div className="sample-container">
                                            <div className={info.kitchenBottomCabinet === 'Aniz' ? 'sample-item active-sample' : 'sample-item'} onClick={() => handleBackground('kitchenBottomCabinet', 'Aniz')} style={{backgroundImage: "url(/img/intDesign/cabinets/aniz.jpg"}}>
                                                <p>Aniz</p>
                                            </div>
                                            <div className={info.kitchenBottomCabinet === 'Avellana' ? 'sample-item active-sample' : 'sample-item'} onClick={() => handleBackground('kitchenBottomCabinet', 'Avellana')} style={{backgroundImage: "url(/img/intDesign/cabinets/avellana.jpg"}}>
                                                <p>Avellana</p> 
                                            </div>
                                            <div className={info.kitchenBottomCabinet === 'Nogal' ? 'sample-item active-sample' : 'sample-item'} onClick={() => handleBackground('kitchenBottomCabinet', 'Nogal')} style={{backgroundImage: "url(/img/intDesign/cabinets/nogal.jpg"}}>
                                                <p>Nogal</p>
                                            </div>
                                            <div className={info.kitchenBottomCabinet === 'Paja' ? 'sample-item active-sample' : 'sample-item'} onClick={() => handleBackground('kitchenBottomCabinet', 'Paja')} style={{backgroundImage: "url(/img/intDesign/cabinets/paja.jpg"}}>
                                                <p>Paja</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='int-design-select'>
                                        <p>{text.interiorDesign.bathroomCabinets[language]}</p>
                                        <div className="sample-container">
                                            {
                                                !info.kitchenTopCabinet || !info.kitchenBottomCabinet ? 
                                                    <>
                                                        <small>{text.interiorDesign.selectCabinet[language]}</small>
                                                    </>
                                                :
                                                    <>
                                                        <div className={info.bathroomCabinet === info.kitchenTopCabinet ? 'sample-item active-sample' : 'sample-item'} onClick={() => handleBackground('bathroomCabinet', info.kitchenTopCabinet)} style={{backgroundImage: `url(/img/intDesign/cabinets/${info.kitchenTopCabinet.toLowerCase()}.jpg`}}>
                                                            <p>{info.kitchenTopCabinet}</p>
                                                        </div>
                                                        <div className={info.bathroomCabinet === info.kitchenBottomCabinet ? 'sample-item active-sample' : 'sample-item'} onClick={() => handleBackground('bathroomCabinet', info.kitchenBottomCabinet)} style={{backgroundImage: `url(/img/intDesign/cabinets/${info.kitchenBottomCabinet.toLowerCase()}.jpg`}}>
                                                            <p>{info.kitchenBottomCabinet}</p>
                                                        </div>
                                                    </>
                                            }
                                        </div>
                                    </div>
                                    <div className='int-design-select'>
                                        <p>{text.interiorDesign.kitchenCountertop[language]}</p>
                                        <div className="sample-container">
                                            <div className={info.kitchenCountertop === 'Graphite Cemento' ? 'sample-item active-sample' : 'sample-item'} onClick={() => {handleBackground('kitchenCountertop', 'Graphite Cemento'); handleBackground('bathroomCountertop', 'Graphite Cemento')} } style={{backgroundImage: "url(/img/intDesign/countertops/GraphiteCemento.jpg"}}>
                                                <p>Graphite Cemento</p>
                                            </div>
                                            <div className={info.kitchenCountertop === 'Limestone' ? 'sample-item active-sample' : 'sample-item'} onClick={() => {handleBackground('kitchenCountertop', 'Limestone'); handleBackground('bathroomCountertop', 'Limestone')}} style={{backgroundImage: "url(/img/intDesign/countertops/Limestone.jpg"}}>
                                                <p>Limestone</p>
                                            </div>
                                            <div className={info.kitchenCountertop === 'Ornamental' ? 'sample-item active-sample' : 'sample-item'} onClick={() => {handleBackground('kitchenCountertop', 'Ornamental'); handleBackground('bathroomCountertop', 'Ornamental')}} style={{backgroundImage: "url(/img/intDesign/countertops/Ornamental.jpg"}}>
                                                <p>Ornamental</p>
                                            </div>
                                            <div className={info.kitchenCountertop === 'Santa Cecilia' ? 'sample-item active-sample' : 'sample-item'} onClick={() => {handleBackground('kitchenCountertop', 'Santa Cecilia'); handleBackground('bathroomCountertop', 'Santa Cecilia')}} style={{backgroundImage: "url(/img/intDesign/countertops/SantaCecilia.jpg"}}>
                                                <p>Santa Cecilia</p>
                                            </div>
                                            <div className={info.kitchenCountertop === 'Sand White' ? 'sample-item active-sample' : 'sample-item'} onClick={() => {handleBackground('kitchenCountertop', 'Sand White'); handleBackground('bathroomCountertop', 'Sand White')}} style={{backgroundImage: "url(/img/intDesign/countertops/SandWhite.jpg"}}>
                                                <p>Sand White</p>
                                            </div>
                                            <div className={info.kitchenCountertop === 'Snow White' ? 'sample-item active-sample' : 'sample-item'} onClick={() => {handleBackground('kitchenCountertop', 'Snow White'); handleBackground('bathroomCountertop', 'Snow White')}} style={{backgroundImage: "url(/img/intDesign/countertops/SnowWhite.jpg"}}>
                                                <p>Snow White</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='int-design-select'>
                                        <p>{text.interiorDesign.bathroomCountertop[language]}</p>
                                        <div className="sample-container">
                                            {
                                                info.bathroomCountertop ? 
                                                    <div className='sample-item active-sample' style={{backgroundImage: `url(/img/intDesign/countertops/${info.bathroomCountertop.split(' ').join('')}.jpg`}}>
                                                        <p>{info.kitchenCountertop}</p>
                                                    </div>
                                                :  
                                                    <small>Select a kitchen countertop</small>
                                            }
                                        </div>
                                    </div>
                                    {/* <div className='int-design-select'>
                                        <p>{text.interiorDesign.flooring[language]}</p>
                                        <div className="sample-container">
                                            
                                        </div>
                                    </div>
                                    <div className='int-design-select'>
                                        <p>{text.interiorDesign.showerTile[language]}</p>
                                        <div className="sample-container">
                                            
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className='multiple-buttons small-buttons bottom-buttons'>
                            <Link to='/buy'><Button loading={false} className='nav-button'>{text.button.returnCaps[language]}</Button></Link>
                            <Button onClick={handleSubmit} loading={false} className='nav-button'>{text.button.continue[language]}</Button>
                            {/* <Button onClick={handleDoc} loading={false} className='nav-button'>{text.button.print[language]}</Button> */}
                        </div>
                    </div>
            }
            <ConfirmationModal confirmAction={confirmAction}>
                <div className="modal-container-confirm">
                    <p>{text.interiorDesign.kitchenTop[language]}: <br/> <img src={`/img/intDesign/cabinets/${info.kitchenTopCabinet && info.kitchenTopCabinet.toLowerCase()}.jpg`} alt='thumb'/> <span>{info.kitchenTopCabinet ? info.kitchenTopCabinet : info.kitchenBottomCabinet}</span></p>
                    <p>{text.interiorDesign.kitchenBottom[language]}: <br/> <img src={`/img/intDesign/cabinets/${info.kitchenBottomCabinet && info.kitchenBottomCabinet.toLowerCase()}.jpg`} alt='thumb'/> <span>{info.kitchenBottomCabinet ? info.kitchenBottomCabinet : info.kitchenTopCabinet}</span></p>
                    <p>{text.interiorDesign.bathCab[language]}: <br/> <img src={`/img/intDesign/cabinets/${info.bathroomCabinet && info.bathroomCabinet.toLowerCase()}.jpg`} alt='thumb'/> <span>{info.bathroomCabinet}</span></p>
                    <p>{text.interiorDesign.kitchenCounter[language]}: <br/> <img src={`/img/intDesign/countertops/${info.kitchenCountertop && info.kitchenCountertop.split(' ').join('')}.jpg`} alt='thumb'/> <span>{info.kitchenCountertop}</span></p>
                    <p>{text.interiorDesign.bathCounter[language]}: <br/> <img src={`/img/intDesign/countertops/${info.bathroomCountertop && info.bathroomCountertop.split(' ').join('')}.jpg`} alt='thumb'/> <span>{info.bathroomCountertop}</span></p>
                    {/* <p>{text.interiorDesign.floors[language]}: <span>{info.floor}</span></p>
                    <p>{text.interiorDesign.showerTile[language]}: <span>{info.showerTile}</span></p> */}
                    <h3>{text.interiorDesign.warning[language]}</h3>
                </div>
            </ConfirmationModal>
            {
                !fetchingHouse &&
                    <div >
                        <Modal
                            title={''}
                            visible={docModal}
                            onCancel={() => setDocModal(false)}
                            okText={'OK'}
                            onOk={() => setDocModal(false)}
                            className='doc-modal'
                        >
                            <div className="modal-container-doc">
                            <PDFViewer>
                                <Document>
                                <Page size="A4" style={styles.page} page>
                                    <View style={styles.logoContainer}>
                                        <Image style={styles.logo} src="https://app.puntapiedra.com/img/common/logo-dark.png" />
                                    </View>
                                    <View style={styles.section}>
                                        <Text style={styles.dataText}>{text.interiorDesign.buyersName[language]}: 
                                            <Text style={styles.text}> {info.name} {info.lastName}</Text>
                                        </Text>
                                        <Text style={styles.dataText}>{text.chooseHouse.inputLotNumber[language]}: 
                                            <Text style={styles.text}> {house.lotNumber}</Text>
                                        </Text>
                                        <Text style={styles.dataText}>{text.chooseHouse.block[language]}: 
                                            <Text style={styles.text}> {info.block}</Text>
                                        </Text>
                                        <Text style={styles.dataText}>{text.chooseHouse.inputLotSize[language]}: 
                                            <Text style={styles.text}> {house.lotSize} m2</Text>
                                        </Text>
                                        <Text style={styles.dataText}>{text.chooseHouse.inputLotModel[language]}: 
                                            <Text style={styles.text}> {house.villa}</Text>
                                        </Text>
                                    </View>
                                    <View style={styles.section}>
                                        <Text style={styles.title}>{text.interiorDesign.docFinishes[language]}</Text>
                                    </View>
                                    <View style={styles.section}>
                                        <Text style={styles.firstText}>{text.interiorDesign.kitchenTop[language]}: </Text>
                                        <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '10px '}}>
                                            <Image style={styles.option} src={`https://app.puntapiedra.com/img/intDesign/cabinets/${info.kitchenTopCabinet && info.kitchenTopCabinet.toLowerCase()}.jpg`} />
                                            <Text style={styles.text}>{info.kitchenTopCabinet ? info.kitchenTopCabinet : info.kitchenBottomCabinet}</Text>
                                        </View>
                                        <Text style={styles.firstText}>{text.interiorDesign.kitchenBottom[language]}: </Text>
                                        <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '10px '}}>
                                            <Image style={styles.option} src={`https://app.puntapiedra.com/img/intDesign/cabinets/${info.kitchenBottomCabinet && info.kitchenBottomCabinet.toLowerCase()}.jpg`} />
                                            <Text style={styles.text}>{info.kitchenBottomCabinet ? info.kitchenBottomCabinet : info.kitchenTopCabinet}</Text>
                                        </View>
                                        <Text style={styles.firstText}>{text.interiorDesign.bathCab[language]}: </Text>
                                        <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '10px '}}>
                                            <Image style={styles.option} src={`https://app.puntapiedra.com/img/intDesign/cabinets/${info.kitchenTopCabinet && info.kitchenTopCabinet.toLowerCase()}.jpg`} />
                                            <Text style={styles.text}>{info.bathroomCabinet}</Text>
                                        </View>
                                        <Text style={styles.firstText}>{text.interiorDesign.kitchenCounter[language]}: </Text>
                                        <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '10px '}}>
                                            <Image style={styles.option} src={`https://app.puntapiedra.com/img/intDesign/countertops/${info.kitchenCountertop && info.kitchenCountertop.split(' ').join('')}.jpg`} />
                                            <Text style={styles.text}>{info.kitchenCountertop}</Text>
                                        </View>
                                        <Text style={styles.firstText}>{text.interiorDesign.bathCounter[language]}: </Text>
                                        <View style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '10px '}}>
                                            <Image style={styles.option} src={`https://app.puntapiedra.com/img/intDesign/countertops/${info.bathroomCountertop && info.bathroomCountertop.split(' ').join('')}.jpg`} />
                                            <Text style={styles.text}>{info.bathroomCountertop}</Text>
                                        </View>
                                    </View>
                                </Page>
                                </Document>
                            </PDFViewer>
                            </div>
                        </Modal>
                    </div>
            }
        </div>
    )
}

let mapState = ({
    app: {language},
    user: {fetching},
    data: {info, dataUpdated, house, fetchingHouse}
}) => ({
    language,
    fetching,
    info,
    dataUpdated,
    house, 
    fetchingHouse
})

export default connect(
    mapState,
    {
        getData: getDataAction,
        updateUser: updateUserAction,
        handleInfo: handleInteriorDesignAction,
        toggleModal: handleConfirmationBoxAction,
        sendNotification: sendNotificationAction,
        getHouse: getHouseDataAction
    }
)(InteriorDesign)
