import React, { useEffect, useState } from 'react'
import { Button, Spin } from 'antd'
import { connect } from 'react-redux'
import { getDataAction, updateUserAction } from '../../redux/dataDuck'
import text from '../text'
import { Link } from 'react-router-dom'

const PropietaryService = ({info, dataUpdated, history, fetching, language, getData, updateUser}) => {

    let handleSubmit = typeInput => {

    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <div className='application-container'>
             {
                fetching ? 
                    <div className='spinner-full-page'><Spin size='large'/></div>
                    :
                    <div className="application-content no-justify">
                        <div style={{marginBottom: '2rem'}}>
                            <div className="app-top-icon">
                                <img src="/img/sidebar/ownerServ.svg" alt="profile"/>
                                <p>{text.sidebar.propietaryService[language]}</p>
                            </div>
                        </div>
                        <div className='padded-app-container form-container'>
                            <div className="propietary-cards-container">
                                <Link to='/propietary-service/hoa' className="propietary-card">
                                    <img src="/img/propietary/hoa.svg" alt="propietary"/>
                                    <p>{text.propietaryServices.hoa[language]}</p>
                                </Link>
                                <Link to='/propietary-service/house-cleaning' className="propietary-card">
                                    <img src="/img/propietary/cleaning.svg" alt="propietary"/>
                                    <p>{text.propietaryServices.houseCleaning[language]}</p>
                                </Link>
                                <Link to='/propietary-service/manteinance' className="propietary-card">
                                    <img src="/img/propietary/manteinance.svg" alt="propietary"/>
                                    <p>{text.propietaryServices.manteinanceServices[language]}</p>
                                </Link>
                                <Link to='/propietary-service/cable' className="propietary-card">
                                    <img src="/img/propietary/cable.svg" alt="propietary"/>
                                    <p>{text.propietaryServices.cable[language]}</p>
                                </Link>
                                <Link to='/propietary-service/public-services' className="propietary-card">
                                    <img src="/img/propietary/public.svg" alt="propietary"/>
                                    <p>{text.propietaryServices.publicServices[language]}</p>
                                </Link>
                                <Link to='/propietary-service/interior-design' className="propietary-card">
                                    <img src="/img/propietary/decoration.svg" alt="propietary"/>
                                    <p>{text.propietaryServices.interiorDesign[language]}</p>
                                </Link>
                                <Link to='/propietary-service/faq' className="propietary-card">
                                    <img src="/img/faq/FAQ-servicios.svg" alt="propietary"/>
                                    <p>{text.sidebar.faq[language]}</p>
                                </Link>
                                <Link to='/propietary-service/important-phones' className="propietary-card">
                                    <img src="/img/propietary/phone.svg" alt="propietary"/>
                                    <p>{text.propietaryServices.importantPhones[language]}</p>
                                </Link>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

let mapState = ({
    app: {language},
    user: {fetching},
    data: {info, dataUpdated}
}) => ({
    language,
    fetching,
    info,
    dataUpdated
})

export default connect(
    mapState,
    {
        getData: getDataAction,
        updateUser: updateUserAction
    }
)(PropietaryService)
