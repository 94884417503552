import React, { useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { checkSessionAction } from './redux/userDuck'
import Login from './components/auth/Login'
import RegisterFormLayout from './components/auth/RegisterFormLayout'
import ForgotPassword from './components/auth/forgotPassword/ForgotPassword'
import ForgotPasswordToken from './components/auth/forgotPassword/ForgotPasswordToken'
import Profile from './components/home/Profile'
import ChooseHouse from './components/home/ChooseHouse'
import BuyHouse from './components/home/BuyHouse'
import UserData from './components/home/UserData'
import AddHouse from './components/admin/AddHouse'
import Documentation from './components/home/Documentation'
import Finance from './components/home/Finance'
import FinantialProfilesData from './components/home/financeProfiles/FinantialProfilesData'
import FinancialForm from './components/home/financeProfiles/FinancialForm'
import InteriorDesign from './components/home/InteriorDesign'
import PropietaryService from './components/home/PropietaryService'
import FAQ from './components/home/FAQ'
import Help from './components/home/Help'
import FaqInfo from './components/home/faqComponents/FaqInfo'
import PropietaryDetails from './components/home/propietaryComponent/PropietaryDetails'
import { getLanguageAction } from './redux/appDuck'
import AdminRegister from './components/auth/AdminRegister'
import SelectClient from './components/home/adminComponents/SelectClient'
import AdminUserProfile from './components/home/adminComponents/AdminUserProfile'
import AdminDocuments from './components/home/adminComponents/AdminDocuments'
import AdminChoose from './components/home/adminComponents/AdminChoose'
import AdminFinance from './components/home/adminComponents/AdminFinance'
import AdminPurchase from './components/home/adminComponents/AdminPurchase'
import AdminFinishes from './components/home/adminComponents/AdminFinishes'
import Register from './components/auth/Register'
import HelpMessage from './components/home/helpComponents/HelpMessage'
import AdminHelp from './components/home/adminComponents/AdminHelp'
import AdminHelpAnswer from './components/home/adminComponents/AdminHelpAnswer'

let PrivateRoute = ({logged, path, component, ...remainingProps}) => {
    if(logged) return <Route path={path} component={component} {...remainingProps}/>
    return <Redirect to='/login' {...remainingProps}/>
}

let AdminRoute = ({userType, logged, path, component, ...remainingProps}) => {
    if(logged && (userType === 'admin' || userType === 'agent' || userType === 'provider') ) return <Route path={path} component={component} {...remainingProps}/>
    return <Redirect to='/login' {...remainingProps}/>
}

let AuthRoute = ({logged, path, component, ...remainingProps}) => {
    if(logged) return <Redirect to='/' {...remainingProps}/>
    return <Route path={path} component={component} {...remainingProps}/> 
}

let Routes = ({info, logged, checkSession, getLanguage}) => {

    let { userType } = info

    useEffect(() => {
        checkSession()
        // eslint-disable-next-line
    },[])

    useEffect(() => {
        if(info.language) getLanguage()
    }, [info])

    return <Switch>
        <AuthRoute exact path='/register' logged={logged} component={Register}/>
        <AuthRoute path='/login' logged={logged} component={Login}/>
        <AuthRoute path='/register/:type' logged={logged} component={RegisterFormLayout}/>
        <AuthRoute path='/admin-register' logged={logged} component={AdminRegister}/>
        <AuthRoute exact path='/recover-password' logged={logged} component={ForgotPassword}/>
        <AuthRoute path='/recover-password/token' logged={logged} component={ForgotPasswordToken}/>
        <PrivateRoute exact path='/' logged={logged} component={userType === 'agent' || userType === 'admin' || userType === 'provider' ? SelectClient : Profile}/>
        <PrivateRoute path='/profile' logged={logged} component={Profile}/>
        <PrivateRoute path='/choose' logged={logged} component={ChooseHouse}/>
        <PrivateRoute path='/buy' logged={logged} component={BuyHouse}/>
        <PrivateRoute path='/data' logged={logged} component={UserData}/>
        <PrivateRoute path='/documentation' logged={logged} component={Documentation}/>
        <PrivateRoute exact path='/financing' logged={logged} component={Finance}/>
        <PrivateRoute path='/financing/:profile' logged={logged} component={FinantialProfilesData}/>
        <PrivateRoute path='/financing-form' logged={logged} component={FinancialForm}/>
        <PrivateRoute path='/interior-design' logged={logged} component={InteriorDesign}/>
        <PrivateRoute exact path='/propietary-service' logged={logged} component={PropietaryService}/>
        <PrivateRoute path='/propietary-service/faq' logged={logged} component={FaqInfo}/>
        <PrivateRoute path='/propietary-service/:service' logged={logged} component={PropietaryDetails}/>
        <PrivateRoute exact path='/help' logged={logged} component={Help}/>
        <PrivateRoute path='/help/:id' logged={logged} component={HelpMessage}/>
        <AdminRoute path='/admin/addHouse' userType={userType} logged={logged} component={AddHouse}/>
        <AdminRoute path='/admin/select-client' userType={userType} logged={logged} component={SelectClient}/>
        <AdminRoute path='/admin/data' userType={userType} logged={logged} component={AdminUserProfile}/>
        <AdminRoute path='/admin/documentation' userType={userType} logged={logged} component={AdminDocuments}/>
        <AdminRoute path='/admin/choose' userType={userType} logged={logged} component={AdminChoose}/>
        <AdminRoute path='/admin/financing' userType={userType} logged={logged} component={AdminFinance}/>
        <AdminRoute path='/admin/buy' userType={userType} logged={logged} component={AdminPurchase}/>
        <AdminRoute path='/admin/interior-design' userType={userType} logged={logged} component={AdminFinishes}/>
        <AdminRoute exact path='/admin/help' userType={userType} logged={logged} component={AdminHelp}/>
        <AdminRoute path='/admin/help/:id' userType={userType} logged={logged} component={AdminHelpAnswer}/>
    </Switch>
}

let mapState = ({user: {logged, info}}) => ({logged, info})

export default connect(
    mapState,
    {
        checkSession: checkSessionAction,
        getLanguage: getLanguageAction
    }
)(Routes)