import { Cookies } from "react-cookie"
import { linkAgent, updateClient, getClientData, deleteFile, getData, getFile, getHouseData, getHouses, getUserDocs, getUsers, sendNotification, updateUser, uploadFile } from "../services/api"

let initialData = {
    info: {
        creditRequest: {}
    },
    fetchingData: false,
    fetchingHouse: true,
    fetchingDocs: false,
    error: {},
    dataUpdated: false,
    house: {},
    houses: [],
    docs: [],
    files: {},
    selectedFile: {},
    notificationSent: false,
    users: [],
    selectedUser: '',
    selectedUserData: {},
    maskedSSN: '',
    maskedCreditSSN: ''
}
// ADMIN
let GET_USER_DATA = 'GET_DATA'
let GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS'
let GET_USER_DATA_ERROR = 'GET_USER_DATA_ERROR'

let GET_USERS = 'GET_USERS'
let GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
let GET_USERS_ERROR = 'GET_USERS_ERROR'

let HANDLE_SELECTED_USER = 'HANDLE_SELECTED_USER'

// USERS
let GET_DATA = 'GET_DATA'
let GET_DATA_SUCCESS = 'GET_DATA_SUCCESS'
let GET_DATA_SUCCESS_SSN = 'GET_DATA_SUCCESS_SSN'
let GET_DATA_ERROR = 'GET_DATA_ERROR'

let GET_DOCS = 'GET_DOCS'
let GET_DOCS_SUCCESS = 'GET_DOCS_SUCCESS'
let GET_DOCS_ERROR = 'GET_DOCS_ERROR'

let GET_HOUSE = 'GET_HOUSE'
let GET_HOUSE_SUCCESS = 'GET_HOUSE_SUCCESS'
let GET_HOUSE_ERROR = 'GET_HOUSE_ERROR'

let GET_HOUSES = 'GET_HOUSES'
let GET_HOUSES_SUCCESS = 'GET_HOUSES_SUCCESS'
let GET_HOUSES_ERROR = 'GET_HOUSES_ERROR'

let UPDATE_USER = 'UPDATE_USER'
let UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
let UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'

let HANDLE_INFO = 'HANDLE_INFO'
let HANDLE_HOUSE = 'HANDLE_HOUSE'
let HANDLE_FILE = 'HANDLE_FILE'
let GET_FILE = 'GET_FILE'
let DELETE_FILE = 'DELETE_FILE'

let SEND_NOTIFICATION = 'SEND_NOTIFICATION'
let SEND_NOTIFICATION_SUCCESS = 'SEND_NOTIFICATION_SUCCESS'
let SEND_NOTIFICATION_ERROR = 'SEND_NOTIFICATION_ERROR'
let CLEAR_SSN = 'CLEAR_SSN'
let CLEAR_CREDIT_SSN = 'CLEAR_CREDIT_SSN'

let reducer = (state = initialData, action) => {
    switch(action.type){
        // ADMIN
        case GET_USER_DATA:
            return {...state, fetchingData: true, selectedUserData: {}, error: {}}
        case GET_USER_DATA_SUCCESS:
            return {...state, fetchingData: false, selectedUserData: action.payload}
        case GET_USER_DATA_ERROR:
            return {...state, fetchingData: false, error: action.payload}

        case GET_USERS:
            return {...state, fetchingData: true, users: [], error: {}}
        case GET_USERS_SUCCESS:
            return {...state, fetchingData: false, users: action.payload}
        case GET_USERS_ERROR:
            return {...state, fetchingData: false, error: action.payload}

        case HANDLE_SELECTED_USER:
            return {...state, selectedUser: action.payload}
        case CLEAR_SSN:
            return {...state, info: {...state.info, ssn: ''}, maskedSSN: ''}
        case CLEAR_CREDIT_SSN:
            return {...state, info: {...state.info, creditRequest: {...state.info.creditRequest, ssn: ''}}, maskedCreditSSN: ''}
        
            // USERS
        case GET_DATA:
            return {...state, notificationSent: false, dataUpdated: false, fetchingData: true, info: {creditRequest: {}}, error: {}}
        case GET_DATA_SUCCESS:
            return {...state, dataUpdated: false, fetchingData: false, info: action.payload}
        case GET_DATA_SUCCESS_SSN:
            return {...state, dataUpdated: false, fetchingData: false, info: action.payload.data, maskedSSN: action.payload.maskedSSN, maskedCreditSSN: action.payload.maskedCreditSSN}
        case GET_DATA_ERROR:
            return {...state, dataUpdated: false, fetchingData: false, error: action.payload}
            
        case GET_DOCS:
            return {...state, fetchingDocs: true, docs: {}, error: {}}
        case GET_DOCS_SUCCESS:
            return {...state, fetchingDocs: false, docs: action.payload}
        case GET_DOCS_ERROR:
            return {...state, fetchingDocs: false, error: action.payload}

        case GET_HOUSE:
            return {...state, fetchingHouse: true, house: {}, error: {}}
        case GET_HOUSE_SUCCESS:
            return {...state, fetchingHouse: false, house: action.payload}
        case GET_HOUSE_ERROR:
            return {...state, fetchingHouse: false, error: action.payload}

        case GET_HOUSES:
            return {...state, notificationSent: false, dataUpdated: false, fetchingHouse: true, houses: [], error: {}}
        case GET_HOUSES_SUCCESS:
            return {...state, fetchingHouse: false, houses: action.payload}
        case GET_HOUSES_ERROR:
            return {...state, fetchingHouse: false, error: action.payload}

        case UPDATE_USER:
            return {...state, dataUpdated: false, fetching: true, error: {}}
        case UPDATE_USER_SUCCESS:
            return {...state, dataUpdated: true, fetching: false}
        case UPDATE_USER_ERROR:
            return {...state, fetching: false, error: action.payload, info: {}}

        case HANDLE_INFO:
            return {...state, info: action.payload}
        case HANDLE_HOUSE:
            return {...state, info: action.payload}
        case GET_FILE:
            return {...state, selectedFile: action.payload}
        case HANDLE_FILE:
            return {...state, info: action.payload}
        case DELETE_FILE:
            return {...state, info: action.payload}
        
        case SEND_NOTIFICATION:
            return {...state, notificationSent: false}
        case SEND_NOTIFICATION_SUCCESS:
            return {...state, notificationSent: true, info: {...state.info, agentNotified: true}}
        case SEND_NOTIFICATION_ERROR:
            return {...state, notificationSent: false}

        default:
            return {...state}
    }
}

export default reducer

// ADMIN
export let approveFinancingAction = () => (dispatch, getState) => {
    dispatch({
        type: UPDATE_USER
    })
    let { selectedUser, selectedUserData } = getState().data
    let completeData = {...selectedUserData, step: 4}
    updateClient(selectedUser, completeData)
        .then(res => {
            dispatch({
                type: SEND_NOTIFICATION
            })
            if(selectedUser.language === 'english'){
                sendNotification(selectedUserData.email, 'approved')
                    .then(res => {
                        console.log(res.data)
                        dispatch({
                            type: SEND_NOTIFICATION_SUCCESS
                        })
                    })
                    .catch(err => dispatch({
                        type: SEND_NOTIFICATION_ERROR
                    }))
                dispatch({
                    type: UPDATE_USER_SUCCESS,
                    payload: res.data
                })
                return
            }
            sendNotification(selectedUserData.email, 'approvedSPA')
                .then(res => {
                    console.log(res.data)
                    dispatch({
                        type: SEND_NOTIFICATION_SUCCESS
                    })
                })
                .catch(err => dispatch({
                    type: SEND_NOTIFICATION_ERROR
                }))
            dispatch({
                type: UPDATE_USER_SUCCESS,
                payload: res.data
            })
        })
        .catch(err => dispatch({
            type: UPDATE_USER_ERROR,
            payload: err.response
        }))
}

export let getClientDataAction = () => (dispatch, getState) => {
    let { selectedUser } = getState().data
    dispatch({
        type: GET_USER_DATA
    })
    getClientData({selectedUser})
        .then(res => dispatch({
            type: GET_USER_DATA_SUCCESS,
            payload: res.data
        }))
        .catch(err => dispatch({
            type: GET_USER_DATA_ERROR,
            payload: err.response
        }))
}

export let handleSelectedUserAction = id => dispatch => {
    dispatch({
        type: HANDLE_SELECTED_USER,
        payload: id
    })
}

export let getUsersAction = () => dispatch => {
    dispatch({
        type: GET_USERS
    })
    getUsers()
        .then(res => dispatch({
            type: GET_USERS_SUCCESS,
            payload: res.data
        }))
        .catch(err => dispatch({
            type: GET_USERS_ERROR,
            payload: err.response
        }))
}

export let clearSSNAction = credit => dispatch => {
    if(credit){
        return dispatch({
            type: CLEAR_CREDIT_SSN
        })
    }
    dispatch({
        type: CLEAR_SSN
    })
}

// USER

export let sendNotificationAction = data => (dispatch, getState) => {
    let { info: {email} } = getState().user
    dispatch({
        type: SEND_NOTIFICATION
    })
    sendNotification(email, data)
        .then(res => {
            console.log(res.data)
            dispatch({
                type: SEND_NOTIFICATION_SUCCESS
            })
        })
        .catch(err => dispatch({
            type: SEND_NOTIFICATION_ERROR
        }))
}

export let deleteFileAction = name => (dispatch, getState) => {
    let { info: {email} } = getState().user
    deleteFile(name, email)
        .then(user => dispatch({
            type: DELETE_FILE,
            payload: user.data
        }))
        .catch(err => dispatch({
            type: GET_DATA_ERROR,
            payload: err.response
        }))

}

export let getFileAction = name => (dispatch, getState) => {
    let { info: {email} } = getState().user
    let extension = name.substring(name.length - 3)
    getFile(name, email)
        .then(res => {
            if(extension === 'pdf'){
                let file = new Blob([res.data], {type: 'application/pdf'})
                let fileURL = URL.createObjectURL(file)
                return window.open(fileURL)
            }
            let file = new Blob([res.data], {type: `image/${extension}`})
            let fileURL = URL.createObjectURL(file)
            return window.open(fileURL)
        })
        .catch(err => dispatch({
            type: GET_FILE,
            payload: err.response
        }))
}

export let handleFileAction = e => (dispatch, getState) => {
    let { info: {email} } = getState().user
    let { info } = getState().data
    let { files, name } = e.target
    let file = new FormData()
    file.append('file', files[0])
    file.append('name', name)
    uploadFile(file, email)
    let newInfo = {...info, files: {...info.files, [name]: files[0].name}}
    dispatch({
        type: HANDLE_FILE,
        payload: newInfo
    })
    sendNotification(email, 'document')
}

export let handleDocAction = (incomingFile, type) => (dispatch, getState) => {
    let { info: {email} } = getState().user
    let { info } = getState().data
    let file = new FormData()
    file.append('file', incomingFile)
    file.append('name', type)
    uploadFile(file, email)
    let newInfo = {...info, files: {...info.files, [type]: incomingFile.name}}
    dispatch({
        type: HANDLE_FILE,
        payload: newInfo
    })
}

export let handleAdditionalHouseAction = (additionalBlock, additionalLotId) => (dispatch, getState) => {
    let { info } = getState().data
    if(additionalBlock === 'none'){
        dispatch({
            type: HANDLE_HOUSE,
            payload: {...info, additionalBlock: null, additionalLotId: null}
        })
        return
    }
    if(!additionalBlock){
        dispatch({
            type: HANDLE_HOUSE,
            payload: {...info, additionalLotId}
        })
        return
    }
    dispatch({
        type: HANDLE_HOUSE,
        payload: {...info, additionalBlock, additionalLotId: null}
    })
}

export let handleHouseAction = (block, lotId) => (dispatch, getState) => {
    let { info } = getState().data
    if(!block){
        dispatch({
            type: HANDLE_HOUSE,
            payload: {...info, lotId}
        })
        return
    }
    dispatch({
        type: HANDLE_HOUSE,
        payload: {...info, block, lotId: null}
    })
}

export let handleStepAction = pageValue => (dispatch, getState) => {
    dispatch({
        type: UPDATE_USER
    })
    let { info } = getState().data
    let completeData = {...info, step: pageValue}
    updateUser(completeData)
        .then(res => dispatch({
            type: UPDATE_USER_SUCCESS,
            payload: res.data
        }))
        .catch(err => dispatch({
            type: UPDATE_USER_ERROR,
            payload: err.response
        }))
}

export let getHousesAction = () => dispatch => {
    let id = new Cookies().get('id')
    if(!id) return dispatch({
        type: GET_HOUSES_ERROR,
        payload: {status: 403}
    })
    dispatch({
        type: GET_HOUSES
    })
    getHouses()
        .then(res => dispatch({
            type: GET_HOUSES_SUCCESS,
            payload: res.data
        }))
        .catch(err => dispatch({
            type: GET_HOUSES_ERROR,
            payload: err.response
        }))
}

export let getHouseDataAction = () => (dispatch, getState) => {
    let { info:{ lotId } } = getState().data
    let id = new Cookies().get('id')
    if(!id) return dispatch({
        type: GET_HOUSE_ERROR,
        payload: {status: 403}
    })
    dispatch({
        type: GET_HOUSE
    })
    getHouseData({id, lotId})
        .then(res => dispatch({
            type: GET_HOUSE_SUCCESS,
            payload: res.data
        }))
        .catch(err => dispatch({
            type: GET_HOUSE_ERROR,
            payload: err.response
        }))
}

export let updateUserAction = additionalData => (dispatch, getState) => {
    dispatch({
        type: UPDATE_USER
    })
    let { info } = getState().data
    let completeData = {...info, ...additionalData}
    updateUser(completeData)
        .then(res => dispatch({
            type: UPDATE_USER_SUCCESS
        }))
        .catch(err => dispatch({
            type: UPDATE_USER_ERROR,
            payload: err.response
        }))
    return 'done'
}

export let handleHouseApprovalsAction = (blueprints, price) => (dispatch, getState) => {
    let { info } = getState().data
    if(!blueprints){
        dispatch({
            type: HANDLE_INFO,
            payload: {...info, priceApproved: price.target.checked}
        })
        return
    }
    dispatch({
        type: HANDLE_INFO,
        payload: {...info, villaApproved: blueprints.target.checked}
    })
}

export let handleFinantialDataAction = event => (dispatch, getState) => {
    let { info } = getState().data
    let { target } = event
    dispatch({
        type: HANDLE_INFO,
        payload: {...info, creditRequest: {...info.creditRequest, [target.name]:target.value}}
    })
}

export let handleInfoAction = (event, country, state) => (dispatch, getState) => {
    let { info } = getState().data
    let { target } = event
    if(target) {
        dispatch({
            type: HANDLE_INFO,
            payload: { ...info, [target.name]:target.value }
        })
        return
    }
    if(country){
        dispatch({
            type: HANDLE_INFO,
            payload: { ...info, country:event }
        })
        return
    }
    if(state){
        dispatch({
            type: HANDLE_INFO,
            payload: { ...info, state:event }
        })
        return
    }
    dispatch({
        type: HANDLE_INFO,
        payload: { ...info, birthDate:event }
    })
}

export let handleInteriorDesignAction = (area, value) => (dispatch, getState) => {
    let { info } = getState().data
    dispatch({
        type: HANDLE_INFO,
        payload: {...info, [area]: value}
    })
}

export let getUserDocsAction = () => dispatch => {
    let id = new Cookies().get('id')
    if(!id) return dispatch({
        type: GET_DOCS_ERROR,
        payload: {status: 403}
    })
    dispatch({
        type: GET_DOCS
    })
    getUserDocs({id})
        .then(res => dispatch({
            type: GET_DOCS_SUCCESS,
            payload: res.data
        }))
        .catch(err => dispatch({
            type: GET_DOCS_ERROR,
            payload: err.response
        }))
}

export let getDataAction = () => (dispatch, getState) => {
    let id = new Cookies().get('id')
    if(!id) return dispatch({
        type: GET_DATA_ERROR,
        payload: {status: 403}
    })
    dispatch({
        type: GET_DATA
    })
    getData({id})
        .then(res => {
            let { data } = res
            if(res.data.ssn){
                if(res.data.creditRequest){
                    if(res.data.creditRequest.ssn){
                        let maskedSSN = 'xxxx'+res.data.ssn[6]+res.data.ssn[7]+res.data.ssn[8]+res.data.ssn[9]
                        let maskedCreditSSN = 'xxxx'+res.data.creditRequest.ssn[6]+res.data.creditRequest.ssn[7]+res.data.creditRequest.ssn[8]+res.data.creditRequest.ssn[9]
                        dispatch({
                            type: GET_DATA_SUCCESS_SSN,
                            payload: {data, maskedSSN, maskedCreditSSN}
                        })
                    }
                    return
                }
                let maskedSSN = 'xxxx'+res.data.ssn[6]+res.data.ssn[7]+res.data.ssn[8]+res.data.ssn[9]
                dispatch({
                    type: GET_DATA_SUCCESS_SSN,
                    payload: {data, maskedSSN}
                })
                return
            }
            dispatch({
                type: GET_DATA_SUCCESS,
                payload: res.data
            })
        })
        .catch(err => dispatch({
            type: GET_DATA_ERROR,
            payload: err.response
        }))
}