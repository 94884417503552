import Modal from 'antd/lib/modal/Modal'
import React from 'react'
import { connect } from 'react-redux'
import { handleConfirmationBoxAction } from '../../redux/appDuck'
import text from '../text'

const ConfirmationModal = ({confirmAction, language, confirmationBox, toggleBox, children}) => {
    return (
        <Modal
            title={text.modal.confirmText[language]}
            visible={confirmationBox}
            onOk={confirmAction}
            onCancel={toggleBox}
            cancelText={text.modal.cancelButton[language]}
            okText={text.modal.okButton[language]}
        >
            {children}
        </Modal>
    )
}

let mapState = ({
    app: {language, confirmationBox}
}) => ({
    language,
    confirmationBox
})

export default connect(
    mapState,
    {
        toggleBox: handleConfirmationBoxAction
    }
)(ConfirmationModal)
