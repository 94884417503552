import React from 'react';
import Navbar from './components/common/Navbar';
import Footer from './components/common/Footer';
import Routes from './Routes';
import { Layout } from 'antd';
const { Content } = Layout

function App() {
  return (
    <Layout>
      <Navbar/>
      <Content>
        <Routes/>
      </Content>
      <Footer/>
    </Layout>
  );
}

export default App;
