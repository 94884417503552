import { Button, Input, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getClientDataAction, getHousesAction } from '../../../redux/dataDuck'
import text from '../../text'

const AdminChoose = ({houses, language, fetching, selectedUserData, error, getData, getHouses}) => {

    let [selectedHouse, setSelectedHouse] = useState({})
    let [selectedAdditional, setSelectedAdditional] = useState({})

    useEffect(() => {
        getHouses()
        getData()
    }, [])

    useEffect(() => {
        if(selectedUserData.lotId !== null && selectedUserData.lotId !== undefined && houses.length) setSelectedHouse(houses[selectedUserData.lotId])
        if(selectedUserData.additionalLotId !== null && selectedUserData.additionalLotId !== undefined && houses.length) setSelectedAdditional(houses[selectedUserData.additionalLotId])
    }, [selectedUserData, houses])

    return (
        <div className='application-container'>
             {
                fetching ? 
                    <div className='spinner-full-page'><Spin size='large'/></div>
                    :
                    <div className="application-content">
                        <div>
                            <div className="app-top-icon">
                                <img src="/img/sidebar/choose.png" alt="profile"/>
                                <p>{text.sidebar.userChosenVilla[language]}</p>
                            </div>
                            <h2>{selectedUserData.name} {selectedUserData.lastName}</h2>
                        </div>
                        <div className="admin-house-choose">
                            <p>{text.chooseHouse.block[language]}</p>
                            <Input style={{width: '320px'}} value={selectedUserData.block} name='lotBlock' placeholder={text.chooseHouse.inputLotSize[language]}/>
                            <p>{text.chooseHouse.inputLotNumber[language]}</p>
                            <Input style={{width: '320px'}} value={selectedHouse.lotNumber} name='lotNumber' placeholder={text.chooseHouse.inputLotNumber[language]}/>
                            <p>{text.chooseHouse.inputLotSize[language]}</p>
                            <Input style={{width: '320px'}} value={selectedHouse.lotSize} suffix={<small>m<sup>2</sup></small>} name='lotSize' placeholder={text.chooseHouse.inputLotSize[language]}/>
                            <p>{text.chooseHouse.inputLotModel[language]}</p>
                            <Input style={{width: '320px'}} value={selectedHouse.villa ? `Villa ${selectedHouse.villa}` : null} name='villa' placeholder={text.chooseHouse.inputLotModel[language]}/>
                            {
                                selectedUserData.additionalLotId ? 
                                    <>
                                    <p className='divider-word'>{text.chooseHouse.addLot[language]}</p>
                                    <p>{text.chooseHouse.block[language]}</p>
                                    <Input style={{width: '320px'}} value={selectedUserData.additionalBlock} name='lotBlock' placeholder={text.chooseHouse.inputLotSize[language]}/>
                                    <p>{text.chooseHouse.inputLotNumber[language]}</p>
                                    <Input style={{width: '320px'}} value={selectedAdditional.lotNumber} name='lotNumber' placeholder={text.chooseHouse.inputLotNumber[language]}/>
                                    <br/><br/>
                                    </>
                                :
                                    null
                            }
                        </div>
                        <div className="multiple-buttons">
                            <Link to='/admin/documentation'><Button loading={fetching} className='nav-button'>{text.button.returnCaps[language]}</Button></Link>
                            <Link to='/admin/financing'><Button className='nav-button'>{text.button.continue[language]}</Button></Link>
                        </div>
                    </div>
            }
        </div>
    )
}

let mapState = ({
    app: {language},
    data: {fetching, selectedUserData, error, houses}
}) => ({
    language,
    fetching,
    selectedUserData,
    error,
    houses
})

export default connect(
    mapState,
    {
        getData: getClientDataAction,
        getHouses: getHousesAction
    }
)(AdminChoose)
