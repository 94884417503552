import { Modal } from 'antd'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import text from '../text'

const Footer = ({language}) => {

    let openModal = () => {
        Modal.info({
            content: (
              <div>
                <p>{text.footer.text[language]}</p>
              </div>
            ),
            className:'terms-modal',
            onOk() {},
          })
    }

    return (
        <div className='footer-container'>
            <div className="footer-left">
               <p>{text.footer.condensedText[language]} <span onClick={openModal} className='read-more'>{text.footer.readMore[language]}</span></p>
            </div>
            <div className="footer-right">
                <img src="/img/common/logo-compact.svg" alt="Punta Piedra"/>
            </div>
        </div>
    )
}

let mapState = ({
    app: {language}
}) => ({
    language
})

export default connect(
    mapState,
    {

    }
)(Footer)
