import { signup, login, logout, token, checkCode, changePass, changePassword, linkAgent, checkEmail} from "../services/api"
import { Cookies } from "react-cookie"
import { getLanguageAction } from "./appDuck"

let initialData = {
    fetching: true,
    info: {},
    error: {},
    logged: new Cookies().get('id') ? true : false,
    tokenSent: false,
    validCode: false,
    passwordChanged: false,
    notRepeatedEmail: false
}

let LOGIN = 'LOGIN'
let LOGIN_SUCCESS = 'LOGIN_SUCCESS'
let LOGIN_ERROR = 'LOGIN_ERROR'

let CHECK_EMAIL = 'CHECK_EMAIL'
let CHECK_EMAIL_SUCCESS = 'CHECK_EMAIL_SUCCESS'
let CHECK_EMAIL_ERROR = 'CHECK_EMAIL_ERROR'

let SIGNUP = 'SIGNUP'
let SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
let SIGNUP_ERROR = 'SIGNUP_ERROR'

let SEND_TOKEN = 'SEND_TOKEN'
let SEND_TOKEN_SUCCESS = 'SEND_TOKEN_SUCCESS'
let SEND_TOKEN_ERROR = 'SEND_TOKEN_ERROR'

let CHECK_CODE = 'CHECK_CODE'
let CHECK_CODE_SUCCESS = 'CHECK_CODE_SUCCESS'
let CHECK_CODE_ERROR = 'CHECK_CODE_ERROR'

let CHANGE_PASSWORD = 'CHANGE_PASSWORD'
let CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
let CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR'

let LOGOUT = 'LOGOUT'

let CHECK_SESSION = 'CHECK_SESSION'

let reducer = (state = initialData, action) => {
    switch(action.type){

        case LOGIN:
            return {...state, fetching: true, info: initialData.info, error: {}}
        case LOGIN_SUCCESS:
            return {...state, fetching: false}
        case LOGIN_ERROR:
            return {...state, fetching: false, error: action.payload, info: {}}

        case CHECK_EMAIL:
            return {...state, fetching: true, notRepeatedEmail: false, error: {}}
        case CHECK_EMAIL_SUCCESS:
            return {...state, fetching: false, notRepeatedEmail: true,}
        case CHECK_EMAIL_ERROR:
            return {...state, fetching: false, error: action.payload, notRepeatedEmail: false}

        case SIGNUP:
            return {...state, notRepeatedEmail: false, fetching: true, info: initialData.info, error: {}}
        case SIGNUP_SUCCESS:
            return {...state, fetching: false}
        case SIGNUP_ERROR:
            return {...state, fetching: false, error: action.payload, info: {}}

        case SEND_TOKEN:
            return {...state, tokenSent: false, fetching: true, error: {}}
        case SEND_TOKEN_SUCCESS:
            return {...state, tokenSent: true, fetching: false}
        case SEND_TOKEN_ERROR:
            return {...state, error: action.payload, fetching: false}

        case CHECK_CODE:
            return {...state, validCode: false, fetching: true, error: {}}
        case CHECK_CODE_SUCCESS:
            return {...state, validCode: true, fetching: false}
        case CHECK_CODE_ERROR:
            return {...state, error: action.payload, fetching: false}

        case CHANGE_PASSWORD:
            return {...state, passwordChanged: false, fetching: true, error: {}}
        case CHANGE_PASSWORD_SUCCESS:
            return {...state, passwordChanged: true, fetching: false}
        case CHANGE_PASSWORD_ERROR:
            return {...state, error: action.payload, fetching: false}

        case LOGOUT:
            return {...state, info: initialData.info, logged: false}

        case CHECK_SESSION:
            return {...state, logged: action.payload.logged, info: action.payload.info, fetching: action.payload.fetching}
        default:
            return {...state}
    }
}

export default reducer

export let changePasswordAction = password => dispatch => {
    let email = new Cookies().get('recoveryEmail')
    if(!email) dispatch({
        type: CHANGE_PASSWORD_ERROR,
        payload: {status: 404}
    })
    dispatch({
        type: CHANGE_PASSWORD
    })
    changePassword({email, password})
        .then(res => dispatch({
            type: CHANGE_PASSWORD_SUCCESS
        }))
        .catch(err => dispatch({
            type: CHANGE_PASSWORD_ERROR,
            payload: err.response
        }))
}

export let checkTokenAction = token => dispatch => {
    let email = new Cookies().get('recoveryEmail')
    if(!email) dispatch({
        type: CHECK_CODE_ERROR,
        payload: {status: 404}
    })
    dispatch({
        type: CHECK_CODE
    })
    checkCode({email, token})
        .then(res => dispatch({
            type: CHECK_CODE_SUCCESS
        }))
        .catch(err => dispatch({
            type: CHECK_CODE_ERROR,
            payload: err.response
        }))
}

export let sendTokenAction = email => dispatch => {
    new Cookies().set('recoveryEmail', email, {maxAge: 60 * 60 * 2})
    dispatch({
        type: SEND_TOKEN
    })
    token({email})
        .then(res => dispatch({
            type: SEND_TOKEN_SUCCESS
        }))
        .catch(err => dispatch({
            type: SEND_TOKEN_ERROR,
            payload: err.response
        }))
} 

export let checkEmailAction = email => dispatch => {
    dispatch({
        type: CHECK_EMAIL
    })
    checkEmail(email)
        .then(res => dispatch({
            type: CHECK_EMAIL_ERROR,
            payload: res
        }))
        .catch(err => dispatch({
            type: CHECK_EMAIL_SUCCESS
        }))
}

export let signupAction = signupData => dispatch => {
    dispatch({
        type: SIGNUP
    })
    signup(signupData)
        .then(res => {
            let { data } = res
            new Cookies().set('id', data.token, {maxAge: 60 * 60 * 24})
            new Cookies().set('user', data.userInfo, {maxAge: 60 * 60 * 24})
            new Cookies().set('language', data.userInfo.language, {maxAge: 60 * 60 * 24})
            dispatch({
                type: SIGNUP_SUCCESS
            })
            dispatch({
                type: CHECK_SESSION,
                payload: {logged: true, info: data.userInfo, fetching: false}
            })
            linkAgent(data.userInfo.customerAgent, data.userInfo._id)
        })
        .catch(err => dispatch({
            type: SIGNUP_ERROR,
            payload: err.response
        }))
}

export let checkSessionAction = () => dispatch => {
    let jwt = new Cookies().get('id')
    let user = new Cookies().get('user')
    if(jwt){ 
        user && dispatch({
            type: CHECK_SESSION,
            payload: {logged: true, info: user, fetching: false}
        })
        return
    }
    return dispatch({
        type: CHECK_SESSION,
        payload: {logged: false, info: {}, fetching: false}
    })
}

export let loginAction = userData => dispatch => {
    dispatch({
        type: LOGIN
    })
    login(userData)
        .then(res => {
            let { data } = res
            new Cookies().set('id', data.token, {maxAge: 60 * 60 * 24})
            new Cookies().set('user', data.userInfo, {maxAge: 60 * 60 * 24})
            new Cookies().set('language', data.userInfo.language, {maxAge: 60 * 60 * 24})
            dispatch({
                type: LOGIN_SUCCESS
            })
            dispatch({
                type: CHECK_SESSION,
                payload: {logged: true, info: data.userInfo, fetching: false}
            })
        })
        .catch(err => dispatch({
            type: LOGIN_ERROR,
            payload: err.response
        }))
}

export let logoutAction = () => dispatch => {
    logout()
    new Cookies().remove('id')
    new Cookies().remove('user')
    new Cookies().remove('language')
    dispatch({
        type: LOGOUT
    })
}