import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'
import { Provider } from 'react-redux'
import generateStore from './redux/store'
import 'antd/dist/antd.css';
import './styles.scss';
let store = generateStore()

let WithRouter = () => <BrowserRouter><App/></BrowserRouter>
let WithStore = () => <Provider store={store}><WithRouter/></Provider>
let WithCookies = () => <CookiesProvider><WithStore/></CookiesProvider>

ReactDOM.render(
    <WithCookies/>, document.getElementById('root')
)

serviceWorker.unregister()
