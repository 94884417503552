import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import text from '../text'
import AgentData from './registerComponents/AgentData'
import BasicData from './registerComponents/BasicData'
import ProviderData from './registerComponents/ProviderData'

let RegisterForm = ({match, language}) => {
    
    let { params } = match
    
    return (
        <div className='padded-container register-container'>
            <div className="left-register register-form-bg">
                <img src="/img/common/logo-white.png" alt="punta piedra logo"/>
            </div>
            <div className="right-register-block">
                <img className='title-icon' src="/img/register/profile.png" alt="Perfil"/>
                <p className='title'>{text.register.portalTitle[language]}</p>
                <p className='subtitle'>
                    {params.type === 'buyer' && text.register.portalSubtitleBuyer[language]}
                    {params.type === 'agent' && text.register.portalSubtitleAgent[language]}
                    {params.type === 'provider' && text.register.portalSubtitleProvider[language]}
                </p>
                {params.type === 'buyer' && <BasicData/>}
                {params.type === 'agent' && <AgentData/>}
                {params.type === 'provider' && <ProviderData/>}
                {/* <div className="auth-bottom-text">
                    {params.type !== 'buyer' && <p><Link to='/register/buyer'>{text.register.signLinkBuyer[language]}</Link></p>}
                    {params.type !== 'agent' && <p><Link to='/register/agent'>{text.register.signLinkAgent[language]}</Link></p>}
                    {params.type !== 'provider' && <p><Link to='/register/provider'>{text.register.signLinkProvider[language]}</Link></p>}
                </div> */}
            </div>
        </div>
    )
}

let mapState = ({
    app: {language}
}) => ({
    language
})

export default connect(
    mapState,
    {

    }
)(RegisterForm)