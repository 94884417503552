import { getAgents } from "../services/api"

let initialState = {
    fetchingGeneral: false,
    errorGeneral: {},
    agents: []
}

let GET_AGENTS = 'GET_AGENTS'
let GET_AGENTS_SUCCESS = 'GET_AGENTS_SUCCESS'
let GET_AGENTS_ERROR = 'GET_AGENTS_ERROR'

let reducer = (state = initialState, action) => {
    switch(action.type){
        case GET_AGENTS:
            return {...state, fetchingGeneral: true, error: {}}
        case GET_AGENTS_SUCCESS:
            return {...state, fetchingGeneral: false,  agents: action.payload}
        case GET_AGENTS_ERROR:
            return {...state, fetchingGeneral: false, error: action.payload}
        default: 
            return {...state}
    }
}

export default reducer

export let getAgentsAction = () => dispatch => {
    dispatch({
        type: GET_AGENTS
    })
    getAgents()
        .then(res => dispatch({
            type: GET_AGENTS_SUCCESS,
            payload: res.data
        }))
        .catch(err => dispatch({
            type: GET_AGENTS_ERROR,
            payload: err.response
        }))
}