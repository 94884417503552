import React, { useEffect, useState } from 'react'
import { Button, Spin } from 'antd'
import { connect } from 'react-redux'
import { getDataAction, updateUserAction } from '../../redux/dataDuck'
import text from '../text'
import { Link } from 'react-router-dom'

const Finance = ({info, dataUpdated, history, fetching, language, getData, updateUser}) => {


    let [type, setType] = useState('')

    let handleSubmit = typeInput => {
        updateUser({economicProfile: typeInput})
        setType(typeInput)
    }

    useEffect(() => {
            if(!fetching && info.economicProfile) return history.push(`/financing/${info.economicProfile}`)
    }, [info])

    useEffect(() => {
            if(dataUpdated && type) history.push(`/financing/${type}`)
    }, [dataUpdated])

    useEffect(() => {
        getData()
    }, [])

    return (
        <div className='application-container'>
             {
                fetching ? 
                    <div className='spinner-full-page'><Spin size='large'/></div>
                    :
                    <div className="application-content">
                        <div>
                            <div className="app-top-icon">
                                <img src="/img/sidebar/finance.svg" alt="profile"/>
                                <p>{text.sidebar.financing[language]}</p>
                            </div>
                        <h2>{text.sidebarSubtitle.financing[language]}</h2>
                        </div>
                        <div className='padded-app-container form-container condensed-inputs'>
                            <p onClick={() => handleSubmit('nat-salaried')} className='economic-profile-selector'>{text.financing.nationalSalaried[language]}</p>
                            <p onClick={() => handleSubmit('nat-independent')} className='economic-profile-selector'>{text.financing.nationalIndependent[language]}</p>
                            <p onClick={() => handleSubmit('ame-salaried')} className='economic-profile-selector'>{text.financing.northAmericanSalaried[language]}</p>
                            <p onClick={() => handleSubmit('ame-independent')} className='economic-profile-selector'>{text.financing.northAmericanIndependent[language]}</p>
                            <p onClick={() => handleSubmit('company')} className='economic-profile-selector'>{text.financing.company[language]}</p>
                        </div>
                        <div className="multiple-buttons">
                            <Link to='/choose'><Button className='nav-button'>{text.button.returnCaps[language]}</Button></Link>
                        </div>
                    </div>
            }
        </div>
    )
}

let mapState = ({
    app: {language},
    user: {fetching},
    data: {info, dataUpdated}
}) => ({
    language,
    fetching,
    info,
    dataUpdated
})

export default connect(
    mapState,
    {
        getData: getDataAction,
        updateUser: updateUserAction
    }
)(Finance)
