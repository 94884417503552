import React, { useEffect, useState } from 'react'
import { Button, Collapse, Spin } from 'antd'
import { connect } from 'react-redux'
import { getDataAction, updateUserAction } from '../../../redux/dataDuck'
import text from '../../text'
import { Link } from 'react-router-dom'
let { Panel } = Collapse

const PropietaryDetails = ({match, info, dataUpdated, history, fetching, language, getData, updateUser}) => {

    let { params } = match
    let [title, setTitle] = useState('')

    let handleTitle = () => {
        switch(params.service){
            case 'hoa':
                return setTitle(text.propietaryServices.hoa[language])
            case 'house-cleaning':
                return setTitle(text.propietaryServices.houseCleaning[language])
            case 'manteinance':
                return setTitle(text.propietaryServices.manteinanceServices[language])
            case 'cable':
                return setTitle(text.propietaryServices.cable[language])
            case 'public-services':
                return setTitle(text.propietaryServices.publicServices[language])
            case 'interior-design':
                return setTitle(text.propietaryServices.interiorDesign[language])
            case 'important-phones':
                return setTitle(text.propietaryServices.importantPhones[language])
            default: 
                return
        }
    }

    let handleSubmit = typeInput => {

    }

    useEffect(() => {
        getData()
        handleTitle()
    }, [])

    return (
        <div className='application-container'>
             {
                fetching ? 
                    <div className='spinner-full-page'><Spin size='large'/></div>
                    :
                    <div className="application-content">
                        <div>
                            <div className="app-top-icon">
                                <img src="/img/sidebar/ownerServ.svg" alt="profile"/>
                                <p>{text.sidebar.propietaryService[language]}</p>
                            </div>
                            <p className='subpage-title'>{title}</p>
                        </div>
                        <div className='padded-app-container form-container condensed-inputs'>
                            <div className="accordion-container">
                                {
                                    params.service === 'hoa' ? 
                                        <Collapse defaultActiveKey={['1']}>
                                            <Panel header="Asociación De Colonos De Punta Piedra" key="1">
                                                <p>{text.propietaryServices.contact[language]}: Elizabeth Bañuelos - Administrator</p>
                                                <p>{text.propietaryServices.officePhone[language]}: 646.155.0494</p>
                                                <p>{text.propietaryServices.cellPhone[language]}: 646.151.7549</p>
                                                <p>{text.propietaryServices.email[language]}: admi.puntapiedra@gmail.com</p>
                                            </Panel>
                                        </Collapse>
                                    : params.service === 'house-cleaning' ? 
                                        <Collapse >
                                            <Panel header="Rossy Garcia - Spanish Speaking" key="1">
                                                <p>{text.propietaryServices.contact[language]}: Rossy Garcia - Spanish Speaking</p>
                                                <p>{text.propietaryServices.cellPhone[language]}: 661.616.5888</p>
                                            </Panel>
                                            <Panel header="Maria Contreras - English Speaking" key="2">
                                                <p>{text.propietaryServices.contact[language]}:  Maria Contreras - English Speaking</p>
                                                <p>{text.propietaryServices.cellPhone[language]}: 661.108.3465</p>
                                            </Panel>
                                            <Panel header="Doris - Spanish Speaking" key="3">
                                                <p>{text.propietaryServices.contact[language]}: Doris - Spanish Speaking</p>
                                                <p>{text.propietaryServices.cellPhone[language]}: 646.183.2521</p>
                                            </Panel>
                                        </Collapse>
                                    : params.service === 'manteinance' ? 
                                        <Collapse >
                                            <Panel header="Zavala's Painting and Construction ( english speaking )" key="1">
                                                <p>{text.propietaryServices.contact[language]}: Vicente Zavala</p>
                                                <p>{text.propietaryServices.cellPhone[language]}: 661.116.1411</p>
                                                <p>{text.propietaryServices.email[language]}: vzavalach73@gmail.com</p>
                                            </Panel>
                                            <Panel header="AG Constructora" key="2">
                                                <p>{text.propietaryServices.contact[language]}: Arq. Cesar Armando Grijalba ( english speaking)</p>
                                                <p>{text.propietaryServices.cellPhone[language]}: 664.111.0479</p>
                                                <p>{text.propietaryServices.email[language]}: arq.cgrijalva@gmail.com</p>
                                            </Panel>
                                            <Panel header="Construcciones y Mantenimiento Nuñez (50% english)" key="3">
                                                <p>{text.propietaryServices.contact[language]}: Juan Carlos Nuñez</p>
                                                <p>{text.propietaryServices.cellPhone[language]}: 664.140.0231</p>
                                                <p>{text.propietaryServices.email[language]}: jancarlo_41@hotmail.com</p>
                                            </Panel>
                                        </Collapse>
                                    : params.service === 'cable' ? 
                                        <Collapse >
                                            <Panel header="Centro de Atención TELNOR Rosarito (Internet and Cable)" key="1">
                                                <p>{text.propietaryServices.contact[language]}: Centro de Atención TELNOR Rosarito (Internet and Cable)</p>
                                                <p>{text.propietaryServices.officePhone[language]}: 661.612.1000</p>
                                                <p>https://www.telnor.com/</p>
                                            </Panel>
                                            <Panel header="WiNET ( Internet)" key="2">
                                                <p>{text.propietaryServices.contact[language]}: WiNET ( Internet)</p>
                                                <p>{text.propietaryServices.officePhone[language]}: 661.614.3100</p>
                                                <p>http://winetsystems.net/ocart2/</p>
                                            </Panel>
                                            <Panel header="Frank Rangel Arce ( No Internet)" key="3">
                                                <p>{text.propietaryServices.contact[language]}: Frank Rangel Arce ( No Internet)</p>
                                                <p>DirecTV (US) and Dish Network (US)</p>
                                                <p>{text.propietaryServices.cellPhone[language]}: 664.446.6543</p>
                                            </Panel>
                                        </Collapse>
                                    : params.service === 'public-services' ? 
                                        <Collapse defaultActiveKey={['1']}>
                                            <Panel header="Electricity Company" key="1">
                                                <p>{text.propietaryServices.contact[language]}: CFE (Comisión Federal de Electricidad)</p>
                                                <p>{text.propietaryServices.officePhone[language]}: 646.175.1006</p>
                                                <p>https://www.cfe.mx/</p>
                                            </Panel>
                                            <Panel header="Water Company" key="2">
                                                <p>{text.propietaryServices.contact[language]}: CESPE (Comisión Estatal de Servicio Públicos Ensenada)</p>
                                                <p>{text.propietaryServices.officePhone[language]}: 646.175.1900</p>
                                                <p>http://www.cespe.gob.mx/public/</p>
                                            </Panel>
                                            <Panel header="Gas Company" key="3">
                                                <p>{text.propietaryServices.contact[language]}: Baja Gas &amp; Oil ( AKA Zeta Gas)</p>
                                                <p>{text.propietaryServices.officePhone[language]}: 661.612.1012</p>
                                                <p>https://bajagas.com/</p>
                                            </Panel>
                                            <Panel header="Water Truck" key="4">
                                                <p>{text.propietaryServices.contact[language]}: Pipa Water ( Water Truck)</p>
                                                <p>{text.propietaryServices.officePhone[language]}: Nacho Gonzalez</p>
                                                <p>{text.propietaryServices.cellPhone[language]}: 646.947.7034</p>
                                            </Panel>
                                        </Collapse>
                                    : params.service === 'interior-design' ? 
                                        <Collapse defaultActiveKey={['1']}>
                                            <Panel header="Home Depot 8082 - Rosarito" key="1">
                                                <p>Boulevard Benito Juarez 300, Playas de Rosarito</p>
                                                <p>{text.propietaryServices.storePhone[language]}: 661.614.8140</p>
                                                <p>https://www.homedepot.com.mx/</p>
                                            </Panel>
                                            <Panel header="Rattan de Guadalajara" key="2">
                                                <p>Rosarito, Baja California</p>
                                                <p>{text.propietaryServices.storePhone[language]}: 661.100.1450</p>
                                                <p>http://rattangdl.com/es</p>
                                            </Panel>
                                            <Panel header="Ashley Furniture Homestore Rosarito" key="3">
                                                <p>Blvd. Benito Juárez 348-D Fracc</p>
                                                <p>{text.propietaryServices.storePhone[language]}: 661.104.3270</p>
                                                <p>https://www.ashleyfurniture.com.mx/</p>
                                            </Panel>
                                        </Collapse>
                                    : params.service === 'important-phones' ? 
                                        <Collapse defaultActiveKey={['1']}>
                                            <Panel header="La Misión Local PD - Delegación La Misión" key="1">
                                                <p>{text.propietaryServices.officePhone[language]}: 646.155.0361</p>
                                            </Panel>
                                            <Panel header="Emergency / Emergencia" key="2">
                                                <p>911</p>
                                            </Panel>
                                            <Panel header="Primo Tapia Local PD -Delegación Primo Tapia" key="3">
                                                <p>{text.propietaryServices.officePhone[language]}:661.614.9679</p>
                                                <p>{text.propietaryServices.officePhone[language]}:661.614.9600</p>
                                            </Panel>
                                        </Collapse>
                                    :
                                        null
                                }
                            </div>
                        </div>
                        <br/><br/>
                        <div className='multiple-buttons bottom-buttons'>
                            <Button className='nav-button'><Link to='/propietary-service'>{text.button.returnCaps[language]}</Link></Button>
                        </div>
                    </div>
            }
        </div>
    )
}

let mapState = ({
    app: {language},
    user: {fetching},
    data: {info, dataUpdated}
}) => ({
    language,
    fetching,
    info,
    dataUpdated
})

export default connect(
    mapState,
    {
        getData: getDataAction,
        updateUser: updateUserAction
    }
)(PropietaryDetails)
