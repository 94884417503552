import Modal from 'antd/lib/modal/Modal'
import React from 'react'
import { connect } from 'react-redux'
import { getUserDocsAction } from '../../../redux/dataDuck'
import { Tree } from 'antd'
import text from '../../text'

let { DirectoryTree } = Tree

let treeData = [
    {
        title: 'Documents',
        key: '0',
        children: [
            {
                title: 'Document 1',
                key: '0-0',
                isLeaf: true,
            },
            {
                title: 'Document 2',
                key: '0-1',
                isLeaf: true,
            }
        ]
    }
]

const DocHandler = ({visible, setVisible, fetchingDocs, language, docs, getDocs}) => {
    return (
        <Modal
          visible={visible}
          onCancel={() => setVisible(false)}
        >
            <div className="directory-container">
                <DirectoryTree
                    multiple
                    defaultExpandAll
                    treeData={treeData}
                />
            </div>
        </Modal>
    )
}

let mapState = ({
    app: {language},
    data: {fetchingDocs, docs, dataUpdated}
}) => ({
    language,
    docs,
    dataUpdated,
    fetchingDocs
})

export default connect(
    mapState,
    {
        getDocs: getUserDocsAction,
    }
)(DocHandler)
