import { Button, Input, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { Cookies } from 'react-cookie'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { changePasswordAction, checkTokenAction } from '../../../redux/userDuck'
import text from '../../text'

const ForgotPasswordToken = ({history, language, validCode, passwordChanged, fetching, error, checkCode, changePassword}) => {

    let [token, setToken] = useState('')
    let [password, setPassword] = useState('')
    let email = new Cookies().get('recoveryEmail')

    let handleSubmit = () => {
        if(!email) return history.push('/recover-password')
        if(!token) return message.warning(text.messages.enterTokenError[language])
        checkCode(token)
    }

    let handleNewPassword = () => {
        if(!email) return history.push('/recover-password')
        if(!password || password.length < 6 || !/\d/.test(password)) return message.warning(text.recoverPassword.pwdError[language])
        changePassword(password)
    }

    let checkEnter = e => e.key === 'Enter' && handleSubmit()
    let checkEnterPassword = e => e.key === 'Enter' && handleNewPassword()

    useEffect(() => {
        if(passwordChanged){
            message.success(text.recoverPassword.pwdSuccess[language])
            setTimeout(() => {
                history.push('/login')
            }, 3000)
        }
        if(validCode) return message.success(text.recoverPassword.successToken[language])
        if(error.status === 404) return message.error(text.messages.enterTokenError[language])
        if(error.status === 500) return message.error(text.messages.requestError[language])
    }, [passwordChanged, validCode, error])

    return (
        <div className='padded-container register-container'>
            <div className="left-register register-bg">
                <img src="/img/common/logo-white.png" alt="punta piedra logo"/>
            </div>
            <div className="right-register">
                <img className='title-icon' src="/img/register/profile.png" alt="Perfil"/>
                <p className='title'>{text.register.portalTitle[language]}</p>
                <p className='subtitle'>{text.register.forgotSubtitle[language]}</p>
                {
                    !validCode ? 
                        <>
                            <div className="recover-password-text">
                                <h2>{text.recoverPassword.recoverMessage[language]}</h2>
                                <h3>{text.recoverPassword.successEmail[language]}</h3>
                                <small>{}</small>
                            </div>        
                            <div className="recover-password-input">
                                <Input onKeyPress={checkEnter} onChange={e => setToken(e.target.value)} value={token} placeholder={text.recoverPassword.enterToken[language]}/>
                                <Button loading={fetching} onClick={handleSubmit} className='nav-button'>{text.button.next[language]}</Button>
                            </div>
                        </>
                    :
                        <>
                            <div className="recover-password-text">
                                <h2>{text.recoverPassword.recoverMessage[language]}</h2>
                                <h3>{text.recoverPassword.pwdIndicator[language]}</h3>
                            </div>        
                            <div className="recover-password-input">
                                <Input onKeyPress={checkEnterPassword} onChange={e => setPassword(e.target.value)} value={password} placeholder={text.recoverPassword.enterNewPassword[language]}/>
                                <Button loading={fetching} onClick={handleNewPassword} className='nav-button'>{text.button.send[language]}</Button>
                                {passwordChanged && <small>{text.recoverPassword.redirect[language]}</small>}
                            </div>
                        </>
                }
                
                <div className="auth-bottom-text">
                    <p>{text.register.login[language]} <Link to='/login'>{text.register.loginLink[language]}</Link></p>
                    <p>{text.form.register[language]} <Link to='/register/buyer'>{text.form.registerLink[language]}</Link></p>
                </div>
            </div>
        </div>
    )
}

let mapState = ({
    app: {language},
    user: {validCode, passwordChanged,  fetching, error} 
}) => ({
    language,
    validCode,
    passwordChanged,
    fetching,
    error
})

export default connect(
    mapState,
    {
        checkCode: checkTokenAction,
        changePassword: changePasswordAction
    }
)(ForgotPasswordToken)
