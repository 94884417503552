import React, { useEffect, useState } from 'react'
import { Button, Collapse, Spin } from 'antd'
import { connect } from 'react-redux'
import { getDataAction, updateUserAction } from '../../../redux/dataDuck'
import text from '../../text'
import { Link } from 'react-router-dom'
import questions from './questions.js'
import questionsSPA from './questionsSPA.js'

let { Panel } = Collapse

const Faqinfo = ({fetching, language}) => {

    return (
        <div className='application-container'>
             {
                fetching ? 
                    <div className='spinner-full-page'><Spin size='large'/></div>
                    :
                    <div className="application-content no-justify">
                        <div>
                            <div className="app-top-icon">
                                <img src="/img/sidebar/faq.svg" alt="profile"/>
                                <p>{text.sidebar.faq[language]}</p>
                            </div>
                        </div>
                        <div className='padded-app-container form-container condensed-inputs'>
                            <div className="accordion-container">
                                <Collapse>
                                    {
                                        language === 'english' ? 
                                            questions.map((element, ix) => 
                                                <Panel header={element.question} key={ix + 1}>
                                                    {element.answers.map((answer, ix2) => <>
                                                        <p>{answer.text && answer.text}</p>
                                                        {
                                                            answer.location ? 
                                                                <>
                                                                    <p><span style={{fontWeight: 'bold'}}>Location: </span>{answer.location.indexOf('http') !== -1 ? <a href={answer.location} rel="noopener noreferrer" target='_blank'>{answer.location}</a> : answer.location}</p>
                                                                </>
                                                            :
                                                                null
                                                        }
                                                        {
                                                            answer.phone ? 
                                                                <>
                                                                    <p>Phone:</p>
                                                                    <p>{answer.phone}</p>
                                                                </>
                                                            :
                                                                null
                                                        }
                                                        {ix2 < element.answers.length - 1 ? <hr/> : null}
                                                    </>)}
                                                </Panel>
                                            )
                                        :
                                            questionsSPA.map((element, ix) => 
                                                <Panel header={element.question} key={ix + 1}>
                                                    {element.answers.map((answer, ix2) => <>
                                                        <p>{answer.text && answer.text}</p>
                                                        {
                                                            answer.location ? 
                                                                <>
                                                                    <p><span style={{fontWeight: 'bold'}}>Ubicación: </span>{answer.location.indexOf('http') !== -1 ? <a href={answer.location} rel="noopener noreferrer" target='_blank'>{answer.location}</a> : answer.location}</p>
                                                                </>
                                                            :
                                                                null
                                                        }
                                                        {
                                                            answer.phone ? 
                                                                <>
                                                                    <p>Teléfono:</p>
                                                                    <p>{answer.phone}</p>
                                                                </>
                                                            :
                                                                null
                                                        }
                                                        {ix2 < element.answers.length - 1 ? <hr/> : null}
                                                    </>)}
                                                </Panel>
                                            )                
                                    }
                                </Collapse>
                            </div>
                        </div>
                        <div className='multiple-buttons bottom-buttons'>
                            <Button className='nav-button'><Link to='/propietary-service'>{text.button.returnCaps[language]}</Link></Button>
                        </div>
                    </div>
            }
        </div>
    )
}

let mapState = ({
    app: {language},
    user: {fetching},
}) => ({
    language,
    fetching,
})

export default connect(
    mapState,
    {
        
    }
)(Faqinfo)
