import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { changeLangAction } from '../../redux/appDuck'
import { logoutAction } from '../../redux/userDuck'
import { withRouter } from 'react-router-dom'
import text from '../text'
import { Dropdown, Menu } from 'antd'

const AdminNav = ({location, language, info, logout}) => {

    let menu = () => (
        <Menu>
            <Menu.Item>
                <p className='menu-item-p' onClick={logout}>{text.navbar.logout[language]}</p>
            </Menu.Item>
        </Menu>
    )

    return (
        <div className='auth-nav'>
            <div className="top-nav">
                <Dropdown overlay={menu} trigger={['click']}>
                    <img style={{cursor: 'pointer'}} src="/img/navbar/profile.svg" alt="profile"/>
                </Dropdown>

            </div>
            <div className="left-nav">
                <img className='sidebar-logo' src="/img/common/logo-dark.png" alt="PP logo black"/>
                <div className="sidebar-nav">
                    <Link to='/admin/select-client' className={`sidebar-element${location.pathname === '/admin/select-client' || location.pathname === '/' ? '-active' : ''}`}>
                        <img src={`/img/sidebar/profile${location.pathname === '/admin/select-client' || location.pathname ===  '/' ? '' : '-gray'}.png`} alt="nav element"/>
                        <p>{text.sidebar.chooseUser[language]}</p>
                    </Link>
                    {
                        info.userType === 'admin' ? 
                            <Link to='/admin/data' className={`sidebar-element${location.pathname === '/admin/data' ? '-active' : ''}`}>
                                <img src={`/img/sidebar/data${location.pathname === '/data' ? '' : '-gray'}.svg`} alt="nav element"/>
                                <p>{text.sidebar.userProfile[language]}</p>
                            </Link>
                        : 
                            null
                    }
                    {
                        info.userType === 'agent' || info.userType === 'admin' ? 
                            <>
                                <Link to='/admin/documentation' className={`sidebar-element${location.pathname === '/admin/documentation' ? '-active' : ''}`}>
                                    <img src={`/img/sidebar/documents${location.pathname === '/admin/documentation' ? '' : '-gray'}.svg`} alt="nav element"/>
                                    <p>{text.sidebar.clientDocuments[language]}</p>
                                </Link>
                                <Link to='/admin/financing' className={`sidebar-element${location.pathname.indexOf('/admin/financing') !== -1 ? '-active' : ''}`}>
                                    <img src={`/img/sidebar/finance${location.pathname.indexOf('/admin/financing') !== -1 ? '' : '-gray'}.svg`} alt="nav element"/>
                                    <p>{text.sidebar.userFinance[language]}</p>
                                </Link>
                                <Link to='/admin/help' className={`sidebar-element${location.pathname.indexOf('/admin/help') !== -1 ? '-active' : ''}`}>
                                    <img src={`/img/sidebar/help${location.pathname.indexOf('/admin/help') !== -1 ? '' : '-gray'}.svg`} alt="nav element"/>
                                    <p>{text.sidebar.help[language]}</p>
                                </Link>
                            </>
                        :   
                            null
                    }
                    {
                        info.userType === 'admin' ? 
                            <>
                                <Link to='/admin/choose' className={`sidebar-element${location.pathname === '/admin/choose' ? '-active' : ''}`}>
                                    <img src={`/img/sidebar/choose${location.pathname === '/admin/choose' ? '' : '-gray'}.png`} alt="nav element"/>
                                    <p>{text.sidebar.userChosenVilla[language]}</p>
                                </Link>
                                <Link to='/admin/buy' className={`sidebar-element${location.pathname === '/admin/buy' ? '-active' : ''}`}>
                                    <img src={`/img/sidebar/buy${location.pathname === '/admin/buy' ? '' : '-gray'}.svg`} alt="nav element"/>
                                    <p>{text.sidebar.userBoughtVilla[language]}</p>
                                </Link>
                                <Link to='/admin/interior-design' className={`sidebar-element${location.pathname === '/admin/interior-design' ? '-active' : ''}`}>
                                    <img src={`/img/sidebar/intDesign${location.pathname === '/admin/interior-design' ? '' : '-gray'}.svg`} alt="nav element"/>
                                    <p>{text.sidebar.clientFinishes[language]}</p>
                                </Link>
                            </>
                        :
                            null
                    }
                </div>
            </div>
        </div>
    )
}

let mapState = ({
    app: {language},
    user: {info}
}) => ({
    language,
    info
})

export default connect(
    mapState,
    {
        toggleLanguage: changeLangAction,
        logout: logoutAction
    }
)(withRouter(AdminNav))
