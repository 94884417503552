import React, { useEffect, useState } from 'react'
import { Button, Checkbox, DatePicker, Input, message, Spin } from 'antd'
import { connect } from 'react-redux'
import { getDataAction, handleInfoAction, handleStepAction, sendNotificationAction, updateUserAction } from '../../redux/dataDuck'
import text from '../text'
import { Link } from 'react-router-dom'
import ConfirmationModal from '../common/ConfirmationModal'
import { handleConfirmationBoxAction } from '../../redux/appDuck'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'

const UserData = ({sendNotification, history, info, fetching, dataUpdated, language, getData, handleInfo, updateUser, toggleModal, handleStep}) => {

    let [consent, setConsent] = useState(false)

    let handleSubmit = () => {
        if(
            !info.ssn ||
            !info.civilStatus ||
            !info.occupation ||
            !info.company ||
            !info.position ||
            !info.officePhone
        ) return message.warning(text.messages.missingFields[language])
        toggleModal()
    }

    let confirmAction = async () => {
        await updateUser()
        toggleModal()
        if(info.step === 4) {
            sendNotification('finishProfile')
            handleStep(5)
        }
        history.push('/documentation')
    }

    let handleUpdate = () => {
        if(!consent) return message.error(text.messages.consent[language])
        updateUser()
    }

    useEffect(() => {
        if(dataUpdated) {
            setConsent(false)
            return message.success(text.messages.dataUpdated[language])
        }
    }, [dataUpdated])

    useEffect(() => {
        getData()
    }, [])

    return (
        <div className='application-container'>
             {
                fetching ? 
                    <div className='spinner-full-page'><Spin size='large'/></div>
                    :
                    <div className="application-content">
                        <div>
                            <div className="app-top-icon">
                                <img src="/img/sidebar/data.svg" alt="profile"/>
                                <p>{text.sidebar.data[language]}</p>
                            </div>
                        <h2>{info.name}, {text.sidebarSubtitle.data[language]}</h2>
                        </div>
                        <div className='padded-app-container form-container condensed-inputs'>
                            <div className="multiple-inputs-with-message">
                                <div className="input-with-message">    
                                    <Input.Password iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} onChange={handleInfo} value={info.ssn} name='ssn' placeholder={text.userData.ssn[language]}/>
                                    
                                </div>
                                <div className="input-with-message">
                                    <Input onChange={handleInfo} value={info.civilStatus} name='civilStatus' placeholder={text.userData.civilStatus[language]}/>
                                </div>
                            </div>
                            <div className="multiple-inputs-with-message">
                                <div className="input-with-message">
                                    <Input onChange={handleInfo} value={info.occupation} name='occupation' placeholder={text.userData.occupation[language]}/>
                                    <p>{text.userData.occupationEx[language]}</p>
                                </div>
                                <div className="input-with-message">
                                    <Input onChange={handleInfo} value={info.company} name='company' placeholder={text.userData.company[language]}/>
                                    <p>{text.userData.companyEx[language]}</p>
                                </div>
                            </div>
                            <div className="multiple-inputs-with-message">
                                <div className="input-with-message">
                                    <Input onChange={handleInfo} value={info.position} name='position' placeholder={text.userData.position[language]}/>
                                    <p>{text.userData.positionEx[language]}</p>
                                </div>
                                <div className="input-with-message">
                                    <Input onChange={handleInfo} value={info.officePhone} name='officePhone' placeholder={text.userData.officePhone[language]}/>
                                </div>
                            </div>
                        </div>
                        <div className="checkbox-input">
                            <Checkbox
                                checked={consent}
                                onChange={() => setConsent(!consent)}
                            >
                                {text.register.confirm[language]}
                            </Checkbox>
                        </div>
                        <div className="multiple-buttons bottom-buttons small-buttons">
                            <Link to='/buy'><Button className='nav-button'>{text.button.returnCaps[language]}</Button></Link>
                            <Button onClick={handleUpdate} loading={fetching} className='nav-button'>{text.button.save[language]}</Button>
                            <Button onClick={handleSubmit} loading={fetching} className='nav-button'>{text.button.continue[language]}</Button>
                        </div>
                    </div>
            }
            <ConfirmationModal confirmAction={confirmAction}>
                <div className="modal-container-confirm">
                    <p>{text.userData.ssn[language]}: <span>{info.ssn}</span></p>
                    <p>{text.userData.civilStatus[language]}: <span>{info.civilStatus}</span></p>
                    <p>{text.userData.occupation[language]}: <span>{info.occupation}</span></p>
                    <p>{text.userData.company[language]}: <span>{info.company}</span></p>
                    <p>{text.userData.position[language]}: <span>{info.position}</span></p>
                    <p>{text.userData.officePhone[language]}: <span>{info.officePhone}</span></p>
                </div>
            </ConfirmationModal>
        </div>
    )
}

let mapState = ({
    app: {language},
    user: {fetching},
    data: {info, dataUpdated}
}) => ({
    language,
    fetching,
    info,
    dataUpdated
})

export default connect(
    mapState,
    {
        getData: getDataAction,
        handleInfo: handleInfoAction,
        toggleModal: handleConfirmationBoxAction,
        handleStep: handleStepAction,
        updateUser: updateUserAction,
        sendNotification: sendNotificationAction
    }
)(UserData)
