import { Select, Spin } from 'antd'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getDataAction, getUsersAction, handleSelectedUserAction } from '../../../redux/dataDuck'
import text from '../../text'

let { Option } = Select

const SelectClient = ({info, selectedUser, fetching, users, language, getUsers, handleSelectedUser, getData}) => {

    useEffect(() => {
        getData()
        getUsers()
    }, [])

    return (
        <div className='application-container'>
             {
                fetching ? 
                    <div className='spinner-full-page'><Spin size='large'/></div>
                    :
                    <div className="application-content no-justify">
                        <div>
                            <div className="app-top-icon">
                                <img src="/img/sidebar/profile.png" alt="profile"/>
                                <p>{text.sidebar.chooseUser[language]}</p>
                            </div>
                            <h2>{text.sidebarSubtitle.chooseUser[language]}</h2>
                            <br/><br/>
                            <p>{text.admin.selectUser[language]}</p>
                            <div style={{maxWidth: '300px', margin: '0 auto'}} className="multiple-inputs">
                                <Select  className='country-selector' onChange={handleSelectedUser} value={selectedUser}>
                                    {
                                        users.filter(user => user.userType === 'buyer' && user.customerAgent === info._id).map(
                                            user => <Option key={user._id} value={user._id}>{user.name} {user.lastName}</Option>
                                        )
                                    }
                                </Select>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

let mapState = ({
    data: {info, fetching, users, selectedUser},
    app: {language}
}) => ({
    language,
    info, 
    fetching,
    users,
    selectedUser
})

export default connect(
    mapState,
    {
        getData: getDataAction,
        getUsers: getUsersAction,
        handleSelectedUser: handleSelectedUserAction
    }
)(SelectClient)
