import React, { useEffect, useState } from 'react'
import { Button, Input, message, Spin } from 'antd'
import { connect } from 'react-redux'
import text from '../../text'
import { Link } from 'react-router-dom'
import { getSingleMessageAction, sendMessageAction } from '../../../redux/adminDuck'

const HelpMessage = ({currentMessage, match, getMessage, fetching, language, sendMessage, messageError, messageSent}) => {

    let { params: {id} } = match

    let [mess, setMess] = useState('')

    let handleSubmit = () => {
        if(!mess) return message.error(text.messages.missingFields[language])
        sendMessage(id, mess)
        setMess('')
    }

    useEffect(() => {
        if(!fetching) getMessage(id)
    }, [fetching])

    useEffect(() => {
        if(messageSent) {
            return message.success(text.messages.messageSent[language])
        }
        if(messageError) return message.error(messageError.message)
    }, [messageError, messageSent])

    return (
        <div className='application-container'>
             {
                fetching ? 
                    <div className='spinner-full-page'><Spin size='large'/></div>
                    :
                    <div style={{maxWidth: 650, margin: '0 auto'}} className="application-content">
                        <div>
                            <div className="app-top-icon">
                                <img src="/img/sidebar/help.svg" alt="profile"/>
                                <p>{text.sidebar.help[language]}</p>
                            </div>
                        </div>
                        <div className='padded-app-container form-container'>
                            <div className="help-container">
                                <p className='help-title'>{currentMessage.question}</p>
                                {
                                    currentMessage.answers ? 
                                        currentMessage.answers.map((answer, ix) => Object.entries(answer).map(([key, value]) => <p key={ix}>{key === 'user' ? text.help.user[language] : text.help.agent[language]}: {value}</p>))
                                    :
                                        null
                                }
                                <br/>
                                <Input.TextArea value={mess} onChange={e => setMess(e.target.value)} rows='4' />
                            </div>
                            <div className="multiple-buttons small-buttons">
                                <Link to='/help'><Button className='nav-button'>{text.button.returnCaps[language]}</Button></Link>
                                &nbsp;&nbsp;
                                <Button onClick={handleSubmit} loading={fetching} className='nav-button'>{text.button.send[language]}</Button>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

let mapState = ({
    app: {language},
    user: {fetching},
    admin: {currentMessage, messageError, messageSent}
}) => ({
    language,
    fetching,
    currentMessage, 
    messageError, 
    messageSent
})

export default connect(
    mapState,
    {
        sendMessage: sendMessageAction,
        getMessage: getSingleMessageAction
    }
)(HelpMessage)
