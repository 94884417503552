import React, { useState } from 'react'
import { Dropdown, Menu, PageHeader } from 'antd'
import { connect } from 'react-redux'
import { logoutAction } from '../../redux/userDuck'
import { Link } from 'react-router-dom'
import text from '../text'
import { changeLangAction } from '../../redux/appDuck'
import { HamburgerCollapse } from 'react-animated-burgers'
import { useMediaQuery } from 'react-responsive'
import AuthNav from './AuthNav'
import AdminNav from './AdminNav'

const Navbar = ({info, logged, language, logout, toggleLanguage}) => {

    let [activeBurger, setActiveBurger] = useState(false)

    let isDesktop = useMediaQuery({query: '(min-device-width: 950px)'})
    let menu = (
        <Menu>
            <Menu.Item key='0'>
                <Link to='/'>{text.navbar.index[language]}</Link>
            </Menu.Item>
            <Menu.Item key='1'>
                <Link to='/'>{text.navbar.aboutUs[language]}</Link>
            </Menu.Item>
            <Menu.Item key='2'>
                <Link to='/'>{text.navbar.amenities[language]}</Link>
            </Menu.Item>
        </Menu>
    )

    let loggedMenu = (
        <Menu>
            <Menu.Item key='0'>
                <li onClick={logout}>Logout</li>
            </Menu.Item>
        </Menu>
    )

    return (
        <>
            {
                !logged ?  
                    <PageHeader className='navbar-container'>
                        <Link to='/'><img src="/logo.png" alt="Logo"/></Link>
                        {
                            isDesktop ?
                                <div className='right-nav'>
                                    <ul>
                                        <li><Link to='/'>{text.navbar.index[language]}</Link></li>
                                        <li><Link to='/'>{text.navbar.aboutUs[language]}</Link></li>
                                        <li><Link to='/menities'>{text.navbar.amenities[language]}</Link></li>
                                        <li><Link to='/'>{text.navbar.contact[language]}</Link></li>
                                    </ul>
                                    <p className='language-selector' onClick={toggleLanguage}>
                                        {language === 'english' ? 'ESPAÑOL' : 'ENGLISH'}
                                    </p>
                                </div>
                            :
                                <div className='mobile-right-nav'>
                                    <p className='language-selector' onClick={toggleLanguage}>
                                        {language === 'english' ? 'ESPAÑOL' : 'ENGLISH'}
                                    </p>
                                    <Dropdown overlay={menu} trigger={['click']}>
                                        <HamburgerCollapse isActive={activeBurger} onClick={() => setActiveBurger(!activeBurger)} className='hamburger' buttonWidth={30}/>
                                    </Dropdown>
                                </div>
                        }
                    </PageHeader>
                : info.userType === 'buyer' ? 
                    <AuthNav/>
                : info.userType === 'admin' || info.userType === 'agent' || info.userType === 'provider' ? 
                    <AdminNav/>
                :
                    <AuthNav/>
            }
        </>
    )
}

let mapState = ({
    user: {logged, info}, 
    app: {language}
}) => ({
    logged, 
    language,
    info
})

export default connect(
    mapState,
    {
        logout: logoutAction,
        toggleLanguage: changeLangAction
    }
)(Navbar)