import React, { useEffect, useState } from 'react'
import { Button, Input, message, Spin } from 'antd'
import { connect } from 'react-redux'
import { getDataAction, handleFinantialDataAction, handleStepAction, sendNotificationAction, updateUserAction } from '../../../redux/dataDuck'
import text from '../../text'
import { Link } from 'react-router-dom'
import ConfirmationModal from '../../common/ConfirmationModal'
import { handleConfirmationBoxAction } from '../../../redux/appDuck'
import PreviewModal from '../../common/PreviewModal'

const FinancialForm = ({creditRequest = {}, dataUpdated, notificationSent, fetching, language, getData, updateUser, handleInfo, handleStep, toggleModal, sendNotification}) => {

    let [visible, setVisible] = useState(false)

    let saveData = () => {
        updateUser()
    }

    let handleSubmit = () => {
        if(
            !creditRequest.officePhone ||
            !creditRequest.homePhone ||
            !creditRequest.rfc ||
            !creditRequest.cellPhone ||
            !creditRequest.livingTime ||
            !creditRequest.occupation ||
            !creditRequest.address ||
            !creditRequest.companyName ||
            !creditRequest.companyField ||
            !creditRequest.companyAddress ||
            !creditRequest.companyTime ||
            !creditRequest.functionsDescription ||
            !creditRequest.companyRole ||
            !creditRequest.academicLevel ||
            !creditRequest.economicDependants ||
            !creditRequest.height ||
            !creditRequest.weight ||
            !creditRequest.ssn ||
            !creditRequest.carBrand ||
            !creditRequest.carValue ||
            !creditRequest.realStateNumber ||
            !creditRequest.realStateValue ||
            !creditRequest.contact1Name ||
            !creditRequest.contact1Address ||
            !creditRequest.contact1Phone ||
            !creditRequest.contact2Name ||
            !creditRequest.contact2Address ||
            !creditRequest.contact2Phone ||
            !creditRequest.contact3Name ||
            !creditRequest.contact3Address ||
            !creditRequest.contact3Phone
        ) return message.error(text.messages.missingFields[language])
        toggleModal()
    }

    let confirmAction = () => {
        toggleModal()
        sendNotification('financeForm')
    }

    useEffect(() => {
        if(notificationSent) return message.success(text.financingForm.notification[language])
    }, [notificationSent])

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        if(dataUpdated) return message.success(text.messages.dataUpdated[language])
    }, [dataUpdated])

    return (
        <div className='application-container'>
             {
                fetching ? 
                    <div className='spinner-full-page'><Spin size='large'/></div>
                    :
                    <div className="application-content no-justify">
                        <div>
                            <div className="app-top-icon">
                                <img src="/img/sidebar/finance.svg" alt="profile"/>
                                <p>{text.sidebar.financing[language]}</p>
                            </div>
                            <h2 className='small-title'>{text.financingForm.title[language]}</h2>
                        </div>
                        <div className='padded-app-container form-container condensed-inputs'>
                            <p className='finance-form-subtitle1'>{text.financingForm.fillInputs[language]}</p>
                            <div className="multiple-inputs">
                                <Input maxLength='15' onChange={handleInfo} value={creditRequest.officePhone} name='officePhone' placeholder={text.financingForm.officePhone[language]}/>
                                <Input maxLength='15' onChange={handleInfo} value={creditRequest.homePhone} name='homePhone' placeholder={text.financingForm.homePhone[language]}/>
                                <Input onChange={handleInfo} value={creditRequest.rfc} name='rfc' placeholder={text.financingForm.rfc[language]}/>
                                <Input maxLength='15' onChange={handleInfo} value={creditRequest.cellPhone} name='cellPhone' placeholder={text.financingForm.cellPhone[language]}/>
                            </div>
                            <div className="multiple-inputs">
                                <Input onChange={handleInfo} value={creditRequest.email} name='email' placeholder={text.financingForm.email[language]}/>
                                <Input onChange={handleInfo} value={creditRequest.livingTime} name='livingTime' placeholder={text.financingForm.livingTime[language]}/>
                                <Input onChange={handleInfo} value={creditRequest.occupation} name='occupation' placeholder={text.financingForm.occupation[language]}/>
                            </div>
                            <p className='finance-form-subtitle2'>{text.financingForm.livesInHouse[language]}</p>
                            <div className="multiple-inputs">
                                <Input onChange={handleInfo} value={creditRequest.address} name='address' placeholder={text.financingForm.address[language]}/>
                            </div>
                            <div className="multiple-inputs">
                                <Input onChange={handleInfo} value={creditRequest.companyName} name='companyName' placeholder={text.financingForm.companyName[language]}/>
                                <Input onChange={handleInfo} value={creditRequest.companyField} name='companyField' placeholder={text.financingForm.companyField[language]}/>
                            </div>
                            <div className="multiple-inputs">
                                <Input onChange={handleInfo} value={creditRequest.companyAddress} name='companyAddress' placeholder={text.financingForm.companyAddress[language]}/>
                                <Input onChange={handleInfo} value={creditRequest.companyTime} name='companyTime' placeholder={text.financingForm.companyTime[language]}/>
                            </div>
                            <div className="multiple-inputs">
                                <Input onChange={handleInfo} value={creditRequest.functionsDescription} name='functionsDescription' placeholder={text.financingForm.functionsDescription[language]}/>
                            </div>
                            <div className="multiple-inputs">
                                <Input onChange={handleInfo} value={creditRequest.companyRole} name='companyRole' placeholder={text.financingForm.companyRole[language]}/>
                                <Input onChange={handleInfo} value={creditRequest.academicLevel} name='academicLevel' placeholder={text.financingForm.academicLevel[language]}/>
                                <Input onChange={handleInfo} value={creditRequest.economicDependants} name='economicDependants' placeholder={text.financingForm.economicDependants[language]}/>
                            </div>
                            <div className="multiple-inputs">
                                <Input onChange={handleInfo} value={creditRequest.height} name='height' placeholder={text.financingForm.height[language]}/>
                                <Input onChange={handleInfo} value={creditRequest.weight} name='weight' placeholder={text.financingForm.weight[language]}/>
                                <Input onChange={handleInfo} value={creditRequest.ssn} name='ssn' placeholder={text.financingForm.ssn[language]}/>
                            </div>
                            <div className="multiple-inputs">
                                <Input onChange={handleInfo} value={creditRequest.carBrand} name='carBrand' placeholder={text.financingForm.carBrand[language]}/>
                                <Input onChange={handleInfo} value={creditRequest.carValue} name='carValue' placeholder={text.financingForm.carValue[language]}/>
                            </div>
                            <div className="multiple-inputs">
                                <Input onChange={handleInfo} value={creditRequest.realStateNumber} name='realStateNumber' placeholder={text.financingForm.realStateNumber[language]}/>
                                <Input onChange={handleInfo} value={creditRequest.realStateValue} name='realStateValue' placeholder={text.financingForm.realStateValue[language]}/>
                            </div>
                            <p className='finance-form-subtitle1'>{text.financingForm.referenceTitle[language]}</p>
                            <div className="multiple-inputs">
                                <Input onChange={handleInfo} value={creditRequest.contact1Name} name='contact1Name' placeholder={text.financingForm.contactName[language]}/>
                                <Input onChange={handleInfo} value={creditRequest.contact1Address} name='contact1Address' placeholder={text.financingForm.contactAddress[language]}/>
                                <Input maxLength='15' onChange={handleInfo} value={creditRequest.contact1Phone} name='contact1Phone' placeholder={text.financingForm.contactPhone[language]}/>
                            </div>
                            <div className="multiple-inputs">
                                <Input onChange={handleInfo} value={creditRequest.contact2Name} name='contact2Name' placeholder={text.financingForm.contactName[language]}/>
                                <Input onChange={handleInfo} value={creditRequest.contact2Address} name='contact2Address' placeholder={text.financingForm.contactAddress[language]}/>
                                <Input maxLength='15' onChange={handleInfo} value={creditRequest.contact2Phone} name='contact2Phone' placeholder={text.financingForm.contactPhone[language]}/>
                            </div>
                            <div className="multiple-inputs">
                                <Input onChange={handleInfo} value={creditRequest.contact3Name} name='contact3Name' placeholder={text.financingForm.contactName[language]}/>
                                <Input onChange={handleInfo} value={creditRequest.contact3Address} name='contact3Address' placeholder={text.financingForm.contactAddress[language]}/>
                                <Input maxLength='15' onChange={handleInfo} value={creditRequest.contact3Phone} name='contact3Phone' placeholder={text.financingForm.contactPhone[language]}/>
                            </div>
                            <div className="multiple-inputs">
                                <Input onChange={handleInfo} value={creditRequest.contact4Name} name='contact4Name' placeholder={text.financingForm.contactName[language]}/>
                                <Input onChange={handleInfo} value={creditRequest.contact4Address} name='contact4Address' placeholder={text.financingForm.contactAddress[language]}/>
                                <Input maxLength='15' onChange={handleInfo} value={creditRequest.contact4Phone} name='contact4Phone' placeholder={text.financingForm.contactPhone[language]}/>
                            </div>
                        </div>
                        <div className="multiple-buttons small-buttons">
                            <Button onClick={saveData} className='nav-button'>{text.button.save[language]}</Button>
                            <Button onClick={() => setVisible(true)} className='nav-button'>{text.button.preview[language]}</Button>
                            <Button onClick={handleSubmit} className='nav-button'>{text.button.print[language]}</Button>
                        </div>
                        {notificationSent && <small className='small-info'>{text.financingForm.notification[language]}</small>}
                        <small className='small-info'>{text.financingForm.agent[language]}</small>
                    </div>
            }
            <div className='preview-modal'>
                <PreviewModal visible={visible} setVisible={setVisible}>
                    <div className="modal-container-confirm">
                        <p>{text.financingForm.officePhone[language]}: <span>{creditRequest.officePhone}</span></p>
                        <p>{text.financingForm.homePhone[language]}: <span>{creditRequest.homePhone}</span></p>
                        <p>{text.financingForm.rfc[language]}: <span>{creditRequest.rfc}</span></p>
                        <p>{text.financingForm.cellPhone[language]}: <span>{creditRequest.cellPhone}</span></p>
                        <p>{text.financingForm.livingTime[language]}: <span>{creditRequest.livingTime}</span></p>
                        <p>{text.financingForm.occupation[language]}: <span>{creditRequest.occupation}</span></p>
                        <p>{text.financingForm.address[language]}: <span>{creditRequest.address}</span></p>
                        <p>{text.financingForm.companyName[language]}: <span>{creditRequest.companyName}</span></p>
                        <p>{text.financingForm.companyField[language]}: <span>{creditRequest.companyField}</span></p>
                        <p>{text.financingForm.companyAddress[language]}: <span>{creditRequest.companyAddress}</span></p>
                        <p>{text.financingForm.companyTime[language]}: <span>{creditRequest.companyTime}</span></p>
                        <p>{text.financingForm.functionsDescription[language]}: <span>{creditRequest.functionsDescription}</span></p>
                        <p>{text.financingForm.companyRole[language]}: <span>{creditRequest.companyRole}</span></p>
                        <p>{text.financingForm.academicLevel[language]}: <span>{creditRequest.academicLevel}</span></p>
                        <p>{text.financingForm.economicDependants[language]}: <span>{creditRequest.economicDependants}</span></p>
                        <p>{text.financingForm.height[language]}: <span>{creditRequest.height}</span></p>
                        <p>{text.financingForm.weight[language]}: <span>{creditRequest.weight}</span></p>
                        <p>{text.financingForm.ssn[language]}: <span>{creditRequest.ssn}</span></p>
                        <p>{text.financingForm.carBrand[language]}: <span>{creditRequest.carBrand}</span></p>
                        <p>{text.financingForm.carValue[language]}: <span>{creditRequest.carValue}</span></p>
                        <p>{text.financingForm.realStateNumber[language]}: <span>{creditRequest.realStateNumber}</span></p>
                        <p>{text.financingForm.realStateValue[language]}: <span>{creditRequest.realStateValue}</span></p>
                        <p>{text.financingForm.contactName[language]}: <span>{creditRequest.contact1Name}</span></p>
                        <p>{text.financingForm.contactAddress[language]}: <span>{creditRequest.contact1Address}</span></p>
                        <p>{text.financingForm.contactPhone[language]}: <span>{creditRequest.contact1Phone}</span></p>
                        <p>{text.financingForm.contactName[language]}: <span>{creditRequest.contact2Name}</span></p>
                        <p>{text.financingForm.contactAddress[language]}: <span>{creditRequest.contact2Address}</span></p>
                        <p>{text.financingForm.contactPhone[language]}: <span>{creditRequest.contact2Phone}</span></p>
                        <p>{text.financingForm.contactName[language]}: <span>{creditRequest.contact3Name}</span></p>
                        <p>{text.financingForm.contactAddress[language]}: <span>{creditRequest.contact3Address}</span></p>
                        <p>{text.financingForm.contactPhone[language]}: <span>{creditRequest.contact3Phone}</span></p>
                    </div>
                </PreviewModal>
            </div>
            <ConfirmationModal confirmAction={confirmAction}>
                <div className="modal-container-confirm">
                    <p>{text.financingForm.officePhone[language]}: <span>{creditRequest.officePhone}</span></p>
                    <p>{text.financingForm.homePhone[language]}: <span>{creditRequest.homePhone}</span></p>
                    <p>{text.financingForm.rfc[language]}: <span>{creditRequest.rfc}</span></p>
                    <p>{text.financingForm.cellPhone[language]}: <span>{creditRequest.cellPhone}</span></p>
                    <p>{text.financingForm.livingTime[language]}: <span>{creditRequest.livingTime}</span></p>
                    <p>{text.financingForm.occupation[language]}: <span>{creditRequest.occupation}</span></p>
                    <p>{text.financingForm.address[language]}: <span>{creditRequest.address}</span></p>
                    <p>{text.financingForm.companyName[language]}: <span>{creditRequest.companyName}</span></p>
                    <p>{text.financingForm.companyField[language]}: <span>{creditRequest.companyField}</span></p>
                    <p>{text.financingForm.companyAddress[language]}: <span>{creditRequest.companyAddress}</span></p>
                    <p>{text.financingForm.companyTime[language]}: <span>{creditRequest.companyTime}</span></p>
                    <p>{text.financingForm.functionsDescription[language]}: <span>{creditRequest.functionsDescription}</span></p>
                    <p>{text.financingForm.companyRole[language]}: <span>{creditRequest.companyRole}</span></p>
                    <p>{text.financingForm.academicLevel[language]}: <span>{creditRequest.academicLevel}</span></p>
                    <p>{text.financingForm.economicDependants[language]}: <span>{creditRequest.economicDependants}</span></p>
                    <p>{text.financingForm.height[language]}: <span>{creditRequest.height}</span></p>
                    <p>{text.financingForm.weight[language]}: <span>{creditRequest.weight}</span></p>
                    <p>{text.financingForm.ssn[language]}: <span>{creditRequest.ssn}</span></p>
                    <p>{text.financingForm.carBrand[language]}: <span>{creditRequest.carBrand}</span></p>
                    <p>{text.financingForm.carValue[language]}: <span>{creditRequest.carValue}</span></p>
                    <p>{text.financingForm.realStateNumber[language]}: <span>{creditRequest.realStateNumber}</span></p>
                    <p>{text.financingForm.realStateValue[language]}: <span>{creditRequest.realStateValue}</span></p>
                    <p>{text.financingForm.contactName[language]}: <span>{creditRequest.contact1Name}</span></p>
                    <p>{text.financingForm.contactAddress[language]}: <span>{creditRequest.contact1Address}</span></p>
                    <p>{text.financingForm.contactPhone[language]}: <span>{creditRequest.contact1Phone}</span></p>
                    <p>{text.financingForm.contactName[language]}: <span>{creditRequest.contact2Name}</span></p>
                    <p>{text.financingForm.contactAddress[language]}: <span>{creditRequest.contact2Address}</span></p>
                    <p>{text.financingForm.contactPhone[language]}: <span>{creditRequest.contact2Phone}</span></p>
                    <p>{text.financingForm.contactName[language]}: <span>{creditRequest.contact3Name}</span></p>
                    <p>{text.financingForm.contactAddress[language]}: <span>{creditRequest.contact3Address}</span></p>
                    <p>{text.financingForm.contactPhone[language]}: <span>{creditRequest.contact3Phone}</span></p>
                </div>
            </ConfirmationModal>
        </div>
    )
}

let mapState = ({
    app: {language},
    user: {fetching},
    data: {info: {creditRequest}, dataUpdated, notificationSent}
}) => ({
    language,
    fetching,
    creditRequest,
    dataUpdated,
    notificationSent
})

export default connect(
    mapState,
    {
        getData: getDataAction,
        updateUser: updateUserAction,
        handleInfo: handleFinantialDataAction,
        handleStep: handleStepAction,
        toggleModal: handleConfirmationBoxAction,
        sendNotification: sendNotificationAction
    }
)(FinancialForm)
