import { Button, Input, message } from 'antd'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { addHouseAction, handleHouseDataAction } from '../../redux/adminDuck'
import { getHousesAction } from '../../redux/dataDuck'

const AddHouse = ({houses, houseData, fetching, error, getHouses, houseUploaded, addHouse, handleHouseData}) => {

    let submitHouse = () => {
        if(!houseData.block || !houseData.lotNumber || !houseData.lotSize || !houseData.villa || !houseData.price) return message.error('Missing fields')
        handleHouseData(null, null, houses.length)
        let cruxImages = ['/img/chooseHouse/crux1.jpg', '/img/chooseHouse/crux2.jpg', '/img/chooseHouse/crux3.jpg']
        let ariesImages = ['/img/chooseHouse/aries1.jpg', '/img/chooseHouse/aries2.jpg', '/img/chooseHouse/aries3.jpg']
        let geminisImages = ['/img/chooseHouse/geminis1.jpg', '/img/chooseHouse/geminis2.jpg']
        let taurusImages = ['/img/chooseHouse/taurus1.jpg', '/img/chooseHouse/taurus2.jpg']
        switch(houseData.villa){
            case 'Crux':
                handleHouseData(null, cruxImages)
                return addHouse()
            case 'Aries':
                handleHouseData(null, ariesImages)
                return addHouse()
            case 'Geminis':
                handleHouseData(null, geminisImages)
                return addHouse()
            case 'Taurus':
                handleHouseData(null, taurusImages)
                return addHouse()
            default:
                return message.error('Villas: Crux, Aries, Geminis, Taurus')
        }
    }

    useEffect(() => {
        if(houseUploaded) {
            getHouses()
            return message.success('HOUSE UPLOADED')
        }
    }, [houseUploaded])

    useEffect(() => {
        getHouses()
    }, [])

    return (
        <div className='application-container'>
            <div className="application-content no-justify admin-container">
                <h2>ADD A HOUSE</h2>
                <p>House ID</p>
                <Input disabled value={houses.length}/>
                <p>Block</p>
                <Input onChange={handleHouseData} value={houseData.block} name='block'/>
                <p>Lot Number</p>
                <Input onChange={handleHouseData} value={houseData.lotNumber} name='lotNumber'/>
                <p>Lot size</p>
                <Input onChange={handleHouseData} value={houseData.lotSize} name='lotSize'/>
                <p>Villa</p>
                <Input onChange={handleHouseData} value={houseData.villa} name='villa'/>
                <p>Price</p>
                <Input type='number' onChange={handleHouseData} value={houseData.price} name='price'/>
                <p>English Description</p>
                <Input onChange={handleHouseData} value={houseData.englishDescription} name='englishDescription'/>
                <p>Spanish Description</p>
                <Input onChange={handleHouseData} value={houseData.españolDescription} name='españolDescription'/>
                <p>Bathrooms</p>
                <Input onChange={handleHouseData} value={houseData.bathrooms} name='bathrooms'/>
                <p>Bedrooms</p>
                <Input onChange={handleHouseData} value={houseData.berdrooms} name='bedrooms'/>
                <p>Cars</p>
                <Input onChange={handleHouseData} value={houseData.cars} name='cars'/>
                <Button onClick={submitHouse}>Add house</Button>
            </div>
        </div>
    )
}

let mapState = ({
    admin: {houseData, fetching, error, houseUploaded},
    data: {houses}
}) => ({
    houseData, 
    fetching, 
    error, 
    houseUploaded,
    houses
})

export default connect(
    mapState,
    {
        addHouse: addHouseAction,
        handleHouseData: handleHouseDataAction,
        getHouses: getHousesAction
    }
)(AddHouse)
