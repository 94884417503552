import { Button, Input, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { sendTokenAction } from '../../../redux/userDuck'
import text from '../../text'

const ForgotPassword = ({history, language, tokenSent, fetching, error, sendToken}) => {

    let [email, setEmail] = useState('')

    let handleSubmit = () => {
        let rex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if(!email || !rex.test(email.toLowerCase())) return message.warning(text.messages.incorrectEmail[language])
        sendToken(email)
    }

    let checkEnter = e => e.key === 'Enter' && handleSubmit()

    useEffect(() => {
        if(tokenSent) return history.push('/recover-password/token')
        if(error.status === 500) return message.error(text.messages.requestError[language])
        if(error.status === 404) return message.error(text.messages.requestError[language])
    }, [tokenSent, error])

    return (
        <div className='padded-container register-container'>
            <div className="left-register register-bg">
                <img src="/img/common/logo-white.png" alt="punta piedra logo"/>
            </div>
            <div className="right-register">
                <img className='title-icon' src="/img/register/profile.png" alt="Perfil"/>
                <p className='title'>{text.register.portalTitle[language]}</p>
                <p className='subtitle'>{text.register.forgotSubtitle[language]}</p>
                <div className="recover-password-text">
                    <h2>{text.recoverPassword.title[language]}</h2>
                    <h3>{text.recoverPassword.message[language]}</h3>
                </div>        
                <div className="recover-password-input">
                    <Input onKeyPress={checkEnter} onChange={e => setEmail(e.target.value)} value={email} placeholder={text.login.emailPlaceholder[language]}/>
                    <Button loading={fetching} onClick={handleSubmit} className='nav-button'>{text.button.next[language]}</Button>
                </div>
                <div className="auth-bottom-text">
                    <p>{text.register.login[language]} <Link to='/login'>{text.register.loginLink[language]}</Link></p>
                    <p>{text.form.register[language]} <Link to='/register/buyer'>{text.form.registerLink[language]}</Link></p>
                </div>
            </div>
        </div>
    )
}

let mapState = ({
    app: {language},
    user: {tokenSent, fetching, error} 
}) => ({
    language,
    tokenSent,
    fetching,
    error
})

export default connect(
    mapState,
    {
        sendToken: sendTokenAction
    }
)(ForgotPassword)
