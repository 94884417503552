export default [
    {
        question: '¿Dónde están ubicados los cajeros automáticos más cercanos a Punta Piedra?',
        answers: [
            {
                text: "La Fonda Gary's  - En La mision por la carretera libre que se convierte en la calle principal - Salga en Alisitos si va por la carretera de cuota - https://goo.gl/maps/hz1vpUpFMZtDj5tP6",
                location: 'https://goo.gl/maps/hz1vpUpFMZtDj5tP6',
                phone: ''
            },
            {
                text: "Farmacia Roma - En Primo Tapia por la carretera libre que se convierte en la calle principal, es un pueblito al norte de La Mision - Salga en Cantamar si va por la carretera de cuota - https://g.page/romaprimotapia?share",
                location: 'https://g.page/romaprimotapia?share',
                phone: ''
            },
            {
                text: "El Jefe  - En Primo Tapia por la carretera libre que se convierte en la calle principal, es un pueblito al norte de La Mision - Salga en Cantamar si va por la carretera de cuota - https://goo.gl/maps/NRq5YE4rjANyxZpU8",
                location: 'https://goo.gl/maps/NRq5YE4rjANyxZpU8',
                phone: ''
            },
            {
                text: "Calimax - En Primo Tapia/ Puerto Nuevo por la carretera libre que se convierte en la calle principal - Salga en Puerto Nuevo si va por la carrerera de cuota - https://goo.gl/maps/hbj4i1TZGm5ZtEBJ9",
                location: 'https://goo.gl/maps/hbj4i1TZGm5ZtEBJ9',
                phone: ''
            },
            {
                text: "Gasolinera BP - En Primo Tapia por la carretera libre que se convierte en la calle principal  - Salga en Cantamar si va por la carrerera de cuota - https://goo.gl/maps/Z3w1iPcM4VuzNGT76",
                location: 'https://goo.gl/maps/Z3w1iPcM4VuzNGT76',
                phone: ''
            },

        ],
    },
    {
        question: '¿Dónde están las gasolineras más cercanas a Punta Piedra?',
        answers: [
            {
                text: "Arco - Saliendo de Punta Piedra por la carretera escénica - https://goo.gl/maps/7c9CGvPepWYrRt9f9",
                location: 'Saliendo de Punta Piedra por la carretera escénica',
                phone: ''
            },
            {
                text: "BP - Primo Tapia por la carretera libre que se convierte en la calle principal  - Salga en Cantamar si va por la carretera de cuota - https://goo.gl/maps/kXXPqXvreczd3LUF8",
                location: 'Primo Tapia/ en la salida de Cantamar si va por la carretera de cuota',
                phone: ''
            },
            {
                text: "Chevron - Primo Tapia off free main road - Exit Puerto Nuevo if on toll road - https://g.page/chevron-puertonuevo?share ",
                location: 'Primo Tapia/Puerto Nuevo si va por la carretera de cuota',
                phone: ''
            }
        ],
    },
    {
        question: '¿Dónde están ubicadas las tiendas departamentales o de ropa más cercanas a Punta Piedra?',
        answers: [
            {
                text: "Walmart - Al norte def Rosarito en el centro comercial llamado Pabellon Rosarito - https://goo.gl/maps/Z3w1iPcM4VuzNGT76  ",
                location: 'https://goo.gl/maps/Z3w1iPcM4VuzNGT76',
                phone: ''
            },
            {
                text: "Suburbia - Al norte def Rosarito en el centro comercial llamado Pabellon Rosarito - https://goo.gl/maps/S2WPmeZxYQwG4v4D8",
                location: 'https://goo.gl/maps/S2WPmeZxYQwG4v4D8',
                phone: ''
            },
            {
                text: "Coppell - Al norte def Rosarito en el centro comercial llamado Pabellon Rosarito - https://goo.gl/maps/bTVTk9Sif1Nfh8wq9",
                location: 'https://goo.gl/maps/bTVTk9Sif1Nfh8wq9',
                phone: ''
            },
            {
                text: "Walmart - Al sur de Ensenada en el centro comercial llamado Macroplaza- https://goo.gl/maps/5FpnfjBHGrqSuBBx9",
                location: 'https://goo.gl/maps/5FpnfjBHGrqSuBBx9',
                phone: ''
            },
            {
                text: "Costco - Al sur de Ensenada, cruzando la calle del centro comercial llamado Macroplaza - https://goo.gl/maps/HKrHciNr84At7bH66",
                location: 'https://goo.gl/maps/HKrHciNr84At7bH66',
                phone: ''
            }
        ],
    },
    {
        question: '¿Cuáles son y dónde están los supermercados más cercanos a Punta Piedra?',
        answers: [
            {
                text: "Calimax - En Primo Tapia / Puerto Nuevo por la carretera libre que se convierte en la calle principal- Salga por Puerto Nuevo si va por la carretera de cuota - https://goo.gl/maps/hbj4i1TZGm5ZtEBJ9",
                location: 'https://goo.gl/maps/hbj4i1TZGm5ZtEBJ9',
                phone: ''
            },
            {
                text: "Costco - Al sur de Ensenada, cruzando la calle del centro comercial llamado Macroplaza - https://goo.gl/maps/HKrHciNr84At7bH66",
                location: 'https://goo.gl/maps/HKrHciNr84At7bH66',
                phone: ''
            }
        ],
    },
    {
        question: '¿Cuáles son y dónde están las tiendas de conveniencia o abarrotes más cercanos a Punta Piedra?',
        answers: [
            {
                text: "Oxxo - Saliendo de Punta Piedra por la salida a la carretera escénica - https://goo.gl/maps/vEUomEjasWFPpfH89",
                location: 'https://goo.gl/maps/vEUomEjasWFPpfH89',
                phone: ''
            },
            {
                text: "Oxxo - Alisitos por la carretera libre que se convierte en la calle principal.  Sálgase de la carretera de cuota - https://goo.gl/maps/fe9NWzdKq6vCTzfW9",
                location: 'https://goo.gl/maps/fe9NWzdKq6vCTzfW9',
                phone: ''
            },
            {
                text: "Sub agencia y abarrotes - En Ejido La Mision por la carretera libre https://g.page/Abarroteslamision?share",
                location: 'https://g.page/Abarroteslamision?share',
                phone: ''
            },
            {
                text: "Oxxo - En Ejido La Mision por la carretera libro https://goo.gl/maps/RKiXy1uTiJoDHrna6",
                location: 'https://goo.gl/maps/RKiXy1uTiJoDHrna6',
                phone: ''
            }
        ],
    },
    {
        question: '¿Cuáles son y dónde están las licorerías más cercanas a Punta Piedra?',
        answers: [
            {
                text: "Oxxo - Saliendo de Punta Piedra por la carretera de cuota - https://goo.gl/maps/vEUomEjasWFPpfH89",
                location: 'Oxxo - Saliendo de Punta Piedra por la carretera de cuota - https://goo.gl/maps/vEUomEjasWFPpfH89',
                phone: ''
            },
            {
                text: "Oxxo - Alisitos por la carretera libre. Sálgase de la carretera de cuota - https://goo.gl/maps/fe9NWzdKq6vCTzfW9",
                location: 'https://goo.gl/maps/fe9NWzdKq6vCTzfW9',
                phone: ''
            },
            {
                text: "Sub agencia y abarrotes - En Ejido La Mision por la carretera libre - https://g.page/Abarroteslamision?share",
                location: 'https://g.page/Abarroteslamision?share',
                phone: ''
            },
            {
                text: "Oxxo - En Ejido La Mision por la carretera libre - https://goo.gl/maps/RKiXy1uTiJoDHrna6",
                location: 'https://goo.gl/maps/RKiXy1uTiJoDHrna6',
                phone: ''
            }
        ],
    },
    {
        question: '¿Dónde puedo tirar mi basura?',
        answers: [
            {
                text: "Utilice botes de basura tradicionales que no puedan boltearse fácilmente y colóquelos justo al frente de su casa ",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿Qué pasa si mi bote de basura se llena demasiado? ¿Dónde la puedo tirar?',
        answers: [
            {
                text: "Se cuenta con contenedores en la zona de la playa, a la entrada por el lado izquierdo",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿A quién le llamo si mis servicios no funcionan?',
        answers: [
            {
                text: "Primero llame a la administración del desarrollo para que se determine si es un problema general (646)155-0494 si es algo específico de su casa, por favor llame directamente al servicio en cuestión.",
                location: '',
                phone: '646-155-0494'
            }
        ],
    },
    {
        question: '¿A quién le llamo si mi teléfono no funciona?',
        answers: [
            {
                text: "Primero llame a la administración del desarrollo para que se determine si es un problema general (646) 155-0494 o algo específico de su casa. Si el problema es únicamente en su casa, repórtelo a Telnor (Teléfonos del Noroeste) que es la compañía telefónica. (661)612-1000 - https://goo.gl/maps/MV8fS3UEL1CPxwyw8",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿A quién le llamo si mi se va el agua?',
        answers: [
            {
                text: "Primero llame a la administración del desarrollo para que se determine si es un problema general (646) 155-0494 o algo específico de su casa. Si el problema es únicamente en su casa, repórtelo a CESPE (Comisión Estatal de Servicio Públicos Ensenada) quien brinda servicios de agua en la ciudad. (646)175-1900 - https://goo.gl/maps/xc1GHoD5WqYBQLd1A",
                location: 'https://goo.gl/maps/xc1GHoD5WqYBQLd1A',
                phone: '646-155-0494'
            }
        ],
    },
    {
        question: '¿A quién le llamo si mi se va el servicio de electricidad?',
        answers: [
            {
                text: "Primero llame a la administración del desarrollo para que se determine si es un problema general (646) 155-0494 o algo específico de su casa. Si el problema es únicamente en su casa, repórtelo a la CFE (Comisión Federal de Electricidad) la empresa que provee el servicio eléctrico (646)175-1006 - https://goo.gl/maps/2Xu8cLVQUiQAmaXY7",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: 'What will my monthly electric bills look like?',
        answers: [
            {
                text: "CFE is the government agency who provides electricity services.  They are responsible for maintaining power to your meter, and you are responsible for the lines from the meter to your property.  Fees, of course, depend on usage, and if you keep usage within a certain parameter, cost is less than going over that quota. Tipically, the electricity service for residencial use, is billed every two (2) months.",
                location: 'https://goo.gl/maps/2Xu8cLVQUiQAmaXY7',
                phone: ''
            }
        ],
    },
    {
        question: '¿A quién le llamo si mi se va el servicio de gas?',
        answers: [
            {
                text: "Primero llame a la administración del desarrollo para que se determine si es un problema general (646) 155-0494 o algo específico de su casa. Si el problema es únicamente en su casa, repórtelo a Gas Zeta (661) 612-1012 - https://goo.gl/maps/Hfh4boNmwSBGzqrW9",
                location: 'https://goo.gl/maps/Hfh4boNmwSBGzqrW9',
                phone: ''
            }
        ],
    },
    {
        question: '¿Como marco de Mexico a USA?',
        answers: [
            {
                text: "Para marcar de Mexico a USA, se marca 001 + Lada+ Numero Telefonico",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿Como marco de USA a Mexico?',
        answers: [
            {
                text: "Para Marcar de USA a Mexico, Se marca 01152 + Lada + Numero Telefonico",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿A quién le llamo si tengo una emergencia?',
        answers: [
            {
                text: "Primero llame a la caseta de los guardias (646) 155-0541, Departamento de Policía Local (646) 155-0361 - https://goo.gl/maps/wH6HCM5sj1f721iMA, Línea de Emergencia, marque al 911",
                location: 'https://goo.gl/maps/wH6HCM5sj1f721iMA',
                phone: ''
            }
        ],
    },
    {
        question: '¿Qué mejoras y servicios cubre mi cuota de mantenimiento?',
        answers: [
            {
                text: "En México, generalmente, las oficinas de administración residencial son similares a las de Estados Unidos. La administración debe contar con un manual o reglamento, normalmente las cuotas sirven para pagar por la seguridad, mantenimiento de andadores y áreas comunies.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿Cuáles son mis cuotas de mantenimiento acordadas por la administración?',
        answers: [
            {
                text: "En Punta Piedra Zona Este, la cuota de mantenimiento es de USD$150.00 por mes; si se paga antes de los días 15 de cada mes, pagaría sólo USD$125.00 por mes. En la sección de La Mision Loma la cuota de mantenimiento es de USD$800.00 al año.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿Cómo adquiero el servicio de gas para la casa?',
        answers: [
            {
                text: "En las Zonas Este y Oeste de Punta Piedra, primero debe llamar a la administración (646)155-0494, si el problema no es general, contácte a la empresa Baja Gas (661) 612-1012 en otras comunidades puede contratar el servicio de entrega de gas propano directo a su casa, si utiliza tanque de 5 galones, usted mismo debe ir a llenarlo o intercambiarlo con proveedores locales. ",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿Puedo utilizar mi asador americano aquí?',
        answers: [
            {
                text: "¡Por su puesto que sí! En algunos casos podría necesitar comprar un adaptador para la manguera de su asador americano.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿Cómo pago mis impuestos de la casa (predial)?',
        answers: [
            {
                text: "Los impuesto sobre propiedades se pagan anualmente en las oficinas municipales de gobierno, y también se pueden pagar en línea. Las tarifas anuales, por supuesto, dependen del tamaño y grado de desarrollo de su propiedad.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿Qué impuestos hay aquí?',
        answers: [
            {
                text: "Los impuestos sobre propiedades, los cuales se pagan anualmente, tal como en los Estados Unidos y en otros países, se basan en el tamaño y grado de desarrollo de su propiedad. Tales impuestos son substancialmente menores a los que se pagan en los Estados Unidos.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿A cuánto asciende mi recibo mensual de agua?',
        answers: [
            {
                text: "Dependerá de su consumo. La Comisión Estatal del Agua es la encargada de proveer el servicio.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿A cuánto asciende mi recibo mensual de gas?',
        answers: [
            {
                text: "El gas natural no se utiliza en nueestra área, por lo cual sólo se usa gas propano. Y por supuesto, el costo del servicio depende del uso. Rellenar un tanque de 5 galones, conocido como 'mina', cuesta alrededor de $225.00 pesos.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿A cuánto asciende mi recibo mensual de electricidad?',
        answers: [
            {
                text: "CFE is la agencia gubernamental que provee los servicios de electricidad. Ellos son responsables de tender las líneas eléctricas en la vía pública y responsables de de mantener la línea que alimenta el medidor de su propiedad. Las tarifas, por supuesto, dependen del consumo, si usted mantiene el consumo dentro de ciertos parámetros, sus cuotas se mantendrán más bajas que si sobre pasa los límites normales. Para residencias familiares, el recibo de cobro se emite cada dos (2) meses. ",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿A cuánto asciende mi recibo de teléfono mensual?',
        answers: [
            {
                text: "Los servicios telefónicos, en la mayoría de las zonas, son provistos por TelNor. Ellos le pueden emitir un recibo depago en papel o electrónico. ",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿Cómo pago mis recibos de servicios?',
        answers: [
            {
                text: "La gente para sus recibos en las oficinas de Rosarito o Ensenada, o en los establecimientos autorizados, tales como Farmacia Roma en Primo Tapia, también se piueden pagar en las tiendas de conveniencia Oxxo y 7-eleven. Los pagos se deben efectuar en pesos. Algunos de los recibos se pueden pagar en línea.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿Se puede tomar el agua del grifo (agua corriente o de la llave)?',
        answers: [
            {
                text: "El agua corriente varía en calidad, dependiendo de dónde usted se encuentre. Generalmente, es sólo utilizada para lavar la ropa, los trastes, para bañarse o regar las plantas. Para beber o cocinar, se recomienda utilizar agua embotellada.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿Dónde puedo comprar frutas y verduras?',
        answers: [
            {
                text: "Afortunadamente, los vegetales locales son producidos orgánicamente, ya que los productos de Monsanto no cuenta con autorización para comercializarse en México. Existen muchas fruterías en el área, también se pueden encontrar frutas o verduras en algunos abarrotes.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿Qué tan seguido se va el servicio de electricidad?',
        answers: [
            {
                text: "Raramente sucede. Si llega a pasar, es usualmente como resultado de tormentas severas o debido a accidentes viales donde se derribaron postes eléctricos.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿Qué tan seguido se va el servicio de las líneas telefónicas?',
        answers: [
            {
                text: "Sucede en algunas ocasiones, un poco más frecuentemente que los cortes de electricidad. Usualmente sucede debido a tormentas severas o debido a accidentes viales donde se derribaron postes eléctricos.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿Cómo puedo conseguir canales de televisión de los Estadus Unidos?',
        answers: [
            {
                text: "Algunos expatriados Americanos contratan Netflix u otros servicios de televisión digital en los Estados unidos y traen consigo sus antenas o receptores. ",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿Cómo puedo conseguir un teléfono celular?',
        answers: [
            {
                text: "Si ya cuenta con un teléfono celular de otro país, debería tener servicio en México. Verifique con su proveedor acerca de servicio y tarifas en el exterior de su país.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿Esta propiedad está en Rosarito o en Ensenada?',
        answers: [
            {
                text: "Su agente inmobiliario o sus escrituras le deben poder aclarar a qué minicipio pertenece su propiedad. ",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿Con qué servivcios de emergencia puedo contar? Policia ',
        answers: [
            {
                text: "La Mision y Primo Tapia cuentan con estaciones de policia, además, en ésta habitualmente se realizan rondines por parte de autoridades del estado, federales y militares. ",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿Con qué servivcios de emergencia puedo contar? Bomberos',
        answers: [
            {
                text: "En nuestra área, el servicio de bomberos (protección contra el fuego), es un provisto por una organización de voluntarios, se sugiere darles donaciones generosas.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: "¿Con qué servivcios de emergencia puedo contar? Ambulancias",
        answers: [
            {
                text: "Los servicios de ambulancias es preferible contratarlos mediante la adquisición de membresías con la Cruz Roja, quienes ofrecen servicio de ambulancia a los límites del cruce fronterizo de Tijuana-San Diego.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿Qué pueden hacer las personas que no son Mexicanas con respecto a servicios de salud?',
        answers: [
            {
                text: "Ésta zona de Baja California es popular entre ciudadanos Canadienses y Americanos debido a que se tiene acceso a transporte a San Diego y a los servicios médicos. Los ciudadanos Mexicanos permanentes, tienen acceso al servicio nacional de salud. También puede acceder a servicios de salud privados por lo que se paga por consulta, las cuales son mucho más accesibles en México.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿Hay servicios veterinarios?',
        answers: [
            {
                text: "Además de hospitales veterinarios en las ciudades de Rosarito y ensenada, existe un muy buen veterinario en el área de Puerto Nuevo/El Pescador.  También existen varios expatriados compasivos que brindan servicios de rescate y adopción de mascotas.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿Hay servicios psiquiátricos?',
        answers: [
            {
                text: "En cuanto servicios médicos, lo más conveniente para ciudadanos Americanos es acudir a San Diego. Por otro lado, existen muy buenos psiquiatras y terapistas bilingües en el área. ",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: 'Tengo hijos/nietos pequeños ¿Hay buenas escuelas a donde puedad asistir? ',
        answers: [
            {
                text: "Para familias que se van a mudar a ésta zona, tal como en otras partes del mundo, aqupi existen escuelas públicas y privadas. Algunas de las escuela privadas, las cuales están en Rosarito y en ensenada, ofrecen educaciónbilingüe y en Inglés. También existen instituciones reconocidas que brindan servicios educativos en línea diseñados específicamente para estudiantes que viven en el exterior. ",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿Debo obtener una visa de residencia o una visa de turista? ',
        answers: [
            {
                text: "Las visas de turista son válidas por seis (6) meses; si planea vivir en México de tiempo completo, contar con una visa de residencia no es sólo recomendado, sino requerido.  ",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿Cómo obtengo una visa de residencia? ',
        answers: [
            {
                text: "Consulte la página de la Secretaría de Relaciones Exteriores para conocer los requisitos https://www.gob.mx/tramites/ficha/visa-de-residencia-temporal/SRE260",
                location: 'https://www.gob.mx/tramites/ficha/visa-de-residencia-temporal/SRE260',
                phone: ''
            }
        ],
    },
    {
        question: '¿Cómo obtengo una visa de permanente? ',
        answers: [
            {
                text: "Consulte la página de la Secretaría de Relaciones Exteriores para conocer los requisitos  https://www.gob.mx/sre/acciones-y-programas/visa-de-residencia-permanente",
                location: 'https://www.gob.mx/sre/acciones-y-programas/visa-de-residencia-permanente',
                phone: ''
            }
        ],
    },
    {
        question: '¿Cómo afecta a mi estatus migratorio el que yo sea dueño de una propiedad? ',
        answers: [
            {
                text: "Ser dueño de una propiedad se basa en tener o en eventualmente adquirir su estatus de residencia permanente o ciudadanía.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿Qué hago si se requiere hacer reparaciones en la casa?',
        answers: [
            {
                text: "La administración, su agente inmobiliario e incluso sus nuevos amigos le puedes ayudan a encontrar a los trabajadores idóneos para que le ayuden con lo que requiere para su nueva casa. ",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿Cómo puedo conseguir un buen plomero o electricista? ',
        answers: [
            {
                text: "La administración, su agente inmobiliario e incluso sus nuevos amigos le puedes ayudan a encontrar a los trabajadores idóneos para que le ayuden con lo que requiere para su nueva casa. ",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿Puedo contratar a una persona de servicio doméstico?',
        answers: [
            {
                text: "Abundas las personas que le pueden brindar servicios domésticos, de jardinería o reparaciones. La administración, su agente inmobiliario e incluso sus nuevos amigos le puedes ayudan a encontrar a los trabajadores idóneos para que le ayuden con lo que requiere para su nueva casa. ",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿Puedo contratar a un jardinero?',
        answers: [
            {
                text: "Abundas las personas que le pueden brindar servicios domésticos, de jardinería o reparaciones. La administración, su agente inmobiliario e incluso sus nuevos amigos le puedes ayudan a encontrar a los trabajadores idóneos para que le ayuden con lo que requiere para su nueva casa. ",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿Cuánto cobran?',
        answers: [
            {
                text: "Se recomienda acordar una tarifa en base al trabajo que se va a realizar, no por la hora, ni por el día. También se recomienda que adquiera los materiales para iniciar los trabajos, pero que pague al trabajador una vez que se haya completado la tarea.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿Dónde puedo encontrar a un mecánico?',
        answers: [
            {
                text: "One caveat about Mexico is that friendliness sometimes means people often saying 'I can do that for you'.  Not everyone who calls himself a mechanic is really competent, so check with neighbours and friends for personal referrals.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿Dónde puedo encontrar a alquien que haga reparaciones de RV?',
        answers: [
            {
                text: "Existen un sitio para RV's muy cercano al desarrollo, ellos pueden darle referencias de mecánico que se especializan en repacaciones de RV's.  https://www.campgroundreviews.com/regions/baja-california/ensenada",
                location: 'https://www.campgroundreviews.com/regions/baja-california/ensenada',
                phone: ''
            }
        ],
    },
    {
        question: '¿Dónde están los supermercados?',
        answers: [
            {
                text: "En el área de La Mision, los supermercados más cercanos están en Puerto Nuevo. Gran variedad de opciones están en Rosarito y en ensenada y también existen una gran cantidad de abarrotes locales, adicionalmente, encontrará muchas tiendas de conveniencia OXXO en la zona.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿Tengo que pagar con pesos todo el tiempo o puedo pagar con dólares?',
        answers: [
            {
                text: "La mayoría de los negocios aceptan ambas monedas al momento en que escribimos esta nota. Aunque la mayoría establece su propio tipo de cambio, en general utilizan el tipo de cambio del día, que es un estándar a nivel nacional. En Tijuana, Rosarito, Ensenada y cerca de Primo Tapia, encontrará casas de cambio que le pueden cambiar sus dólares por pesos y viceversa.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿Hay iglesias que celebren misa en Inglés en el área?',
        answers: [
            {
                text: "La respuesta es sí. Existen templos Católicos, Episcopales y Evengélicos en la zona que realizan sus servicios en Inglés. Adicionalmente, también existen tempos Dudistas, de Testigos de Jeová y de los Santos de los Últimos Días en otras partes del estado que podrían o no contar con servicios en Inglés.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: '¿Hay grupos de AA en el área?',
        answers: [
            {
                text: "Existen grupos de tanto de AA como de NA en Tijuana, Rosarito y ensenada, así como en Primo Tapia.",
                location: '',
                phone: ''
            }
        ],
    }
]