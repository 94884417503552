import { Cookies } from "react-cookie"
import { getLanguage } from "../services/api"

let initialData = {
    fetchingApp: false,
    errorApp: {},
    language: new Cookies().get('language') || 'english',
    confirmationBox: false
}

let CHANGE_LANG = 'CHANGE_LANG'

let GET_LANG = 'GET_LANG'
let GET_LANG_SUCCESS = 'GET_LANG_SUCCESS'
let GET_LANG_ERROR = 'GET_LANG_ERROR'

let HANDLE_CONFIRMATION_BOX = 'HANDLE_CONFIRMATION_BOX'

let reducer = (state = initialData, action) => {
    switch(action.type){
        case CHANGE_LANG:
            return {...state, language: action.payload}
        case GET_LANG:
            return {...state, fetchingApp: true}
        case GET_LANG_SUCCESS:
            return {...state, fetchingApp: false, language: action.payload}
        case GET_LANG_ERROR:
            return {...state, fetchingApp: false, errorApp: action.payload}
        case HANDLE_CONFIRMATION_BOX:
            return {...state, confirmationBox: action.payload}
        default:
            return {...state}
    }
}

export default reducer

export let handleConfirmationBoxAction = () => (dispatch, getState) => {
    let { confirmationBox } = getState().app
    dispatch({
        type: HANDLE_CONFIRMATION_BOX,
        payload: !confirmationBox
    })
}

export let changeLangAction = input => (dispatch, getState) => {
    if(input.length){
        new Cookies().set('language', input)
        dispatch({
            type: CHANGE_LANG,
            payload: input
        })
        return
    }
    let { language } = getState().app
    new Cookies().set('language', language === 'english' ? 'español' : 'english')
    dispatch({
        type: CHANGE_LANG,
        payload: language === 'english' ? 'español' : 'english'
    })
}

export let getLanguageAction = () => (dispatch, getState) => {
    let { info } = getState().user
    dispatch({
        type: GET_LANG
    })
    getLanguage({email: info.email})
        .then(res => dispatch({
            type: GET_LANG_SUCCESS,
            payload: res.data.language
        }))
        .catch(err => dispatch({
            type: GET_LANG_ERROR,
            payload: err.response
        }))
}