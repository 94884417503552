import React, { useEffect, useState } from 'react'
import { Button, Input, message, Spin } from 'antd'
import { connect } from 'react-redux'
import { getDataAction, updateUserAction } from '../../redux/dataDuck'
import text from '../text'
import { Link } from 'react-router-dom'
import { getMessageAction, sendQuestionAction } from '../../redux/adminDuck'

const Help = ({getMessages, fetching, language, getData, sendQuestion, messages, messageError, messageSent}) => {

    let [question, setQuestion] = useState('')
    let [sent, setSent] = useState(false)

    let handleSubmit = () => {
        if(!question) return message.error(text.messages.missingFields[language])
        sendQuestion(question)
    }

    useEffect(() => {
        getData()
    }, [messageSent])

    useEffect(() => {
        if(!fetching) getMessages()
    }, [fetching])

    useEffect(() => {
        if(messageSent) {
            getMessages()
            setQuestion('')
            return setSent(true)
        }
        if(messageError) return message.error(messageError.message)
    }, [messageError, messageSent])

    return (
        <div className='application-container'>
             {
                fetching ? 
                    <div className='spinner-full-page'><Spin size='large'/></div>
                    :
                    <div className="application-content">
                        <div>
                            <div className="app-top-icon">
                                <img src="/img/sidebar/help.svg" alt="profile"/>
                                <p>{text.sidebar.help[language]}</p>
                            </div>
                        </div>
                        <div className='padded-app-container form-container'>
                            <div className="help-container">
                                <p className='help-title'>{text.help.title[language]}</p>
                                <Input.TextArea value={question} onChange={e => setQuestion(e.target.value)} rows='4' />
                            </div>
                            <Button style={{marginBottom: '10px'}} onClick={handleSubmit} loading={fetching} className='nav-button'>{text.button.send[language]}</Button>
                            {sent && <><br/><small>{text.help.sent[language]}</small></>}
                        </div>
                        {
                            messages ? 
                                <div>
                                    <small>{text.help.questions[language]}:</small>
                                    <ul className='questions-container'>
                                        {
                                            messages.map(message => <Link to={`/help/${message._id}`}><li key={message._id}>{message.question}</li></Link>)
                                        }
                                    </ul>
                                </div>
                            :
                                null
                        }
                    </div>
            }
        </div>
    )
}

let mapState = ({
    app: {language},
    user: {fetching},
    data: {info, dataUpdated},
    admin: {messages, messageError, messageSent}
}) => ({
    language,
    fetching,
    info,
    dataUpdated,
    messages, 
    messageError, 
    messageSent
})

export default connect(
    mapState,
    {
        getData: getDataAction,
        updateUser: updateUserAction,
        sendQuestion: sendQuestionAction,
        getMessages: getMessageAction
    }
)(Help)
