import React, { useEffect, useState } from 'react'
import { Button, Checkbox, DatePicker, Input, message, Select, Spin } from 'antd'
import { connect } from 'react-redux'
import { clearSSNAction, getDataAction, handleInfoAction, handleStepAction, sendNotificationAction, updateUserAction } from '../../redux/dataDuck'
import { Link } from 'react-router-dom'
import moment from 'moment'
import text from '../text'
import { usaStates, mexStates } from './profileData/States'
import ConfirmationModal from '../common/ConfirmationModal'
import { changeLangAction, handleConfirmationBoxAction } from '../../redux/appDuck'
import { MaskedInput } from 'antd-mask-input'
import { useMediaQuery } from 'react-responsive'

let { Option } = Select

const Profile = ({sendNotification, history, info, fetching, dataUpdated, language, getData, handleInfo, updateUser, handleStep, toggleModal, changeLanguage, maskedSSN, maskedCreditSSN, clearSSN}) => {

    let [consent, setConsent] = useState(false)
    let isSmallMobile = useMediaQuery({query: '(min-device-width: 550px)'})

    let handleSubmit = () => {
        if(!consent) return message.warning(text.messages.consent[language])
        updateUser()
    }

    let checkCompleted = () => {
        if(language === 'español' && !info.secondLastName) return message.warning(text.messages.missingFields[language])
        if(
            !info.country || 
            !info.state || 
            !info.ssn ||
            !info.civilStatus ||
            !info.occupation ||
            !info.company ||
            !info.position ||
            !info.officePhone
        ) return message.warning(text.messages.missingFields[language])
        if(info.officePhone.length < 10) return message.warning(text.messages.wrongInfo[language])
        if(info.ssn.length < 8) return message.warning(text.messages.wrongInfo[language])
        if(!consent) return message.warning(text.messages.consent[language])
        toggleModal()
    }

    let submitAction = async () => {
        await updateUser()
        toggleModal()
        if(info.step === 0) {
            sendNotification('profile')
            handleStep(1)
        }
        history.push('/documentation')
    }

    useEffect(() => {
        if(dataUpdated) {
            setConsent(false)
            return message.success(text.messages.dataUpdated[language])
        }
    }, [dataUpdated])

    useEffect(() => {
        getData()
    }, [])

    return (
        <div className='application-container'>
             {
                fetching ? 
                    <div className='spinner-full-page'><Spin size='large'/></div>
                    :
                    <div className={`application-content no-justify`}>
                        <div>
                            <div className="app-top-icon">
                                <img src="/img/sidebar/profile.png" alt="profile"/>
                                <p>{text.sidebar.profile[language]}</p>
                            </div>
                            <h2>{text.sidebarSubtitle.profile[language]}</h2>
                        </div>
                        <div className='padded-app-container form-container'>
                            <p className='profile-form-subtitle2'>{text.profile.basicInfo[language]}</p>
                            <div className="multiple-inputs">
                                <Input onChange={handleInfo} value={info.name} name='name' placeholder={text.register.firstName[language]}/>
                                <Input className={`${language === 'english' && 'last-input'}`} onChange={handleInfo} value={info.lastName} name='lastName' placeholder={text.register.lastName[language]}/>
                                {language === 'español' && <Input required onChange={handleInfo} value={info.secondLastName} name='secondLastName' placeholder={text.register.secondLastName[language]}/>}
                            </div>
                            {info.userType !== 'buyer' && <Input id='company-app' onChange={handleInfo} value={info.company} name='company' placeholder={text.register.company[language]}/>}
                            <div className="multiple-inputs">
                                <Input onChange={handleInfo} value={info.email} name='email' placeholder={text.register.email[language]}/>
                                {
                                   language === 'english' ?
                                        <MaskedInput className='last-input' mask="(111) 111-1111" name="phone" onChange={handleInfo} value={info.phone} placeholder={text.register.phone[language]}/>
                                    :
                                        <MaskedInput className='last-input' mask="(11) 1111-1111" name="phone" onChange={handleInfo} value={info.phone} placeholder={text.register.phone[language]}/>
                                }
                                <Select className='country-selector' onChange={e => {changeLanguage(e); handleInfo({target: {value: e, name: 'language'}})}} placeholder={text.register.language[language]} value={info.language} defaultValue={info.language}>
                                    <Option value='english'>English</Option>
                                    <Option value='español'>Español</Option>
                                </Select>
                            </div>
                            <div className="multiple-inputs">
                                <Input onChange={handleInfo} value={info.nationality} name='nationality' placeholder={text.profile.nationality[language]}/>
                                <Input onChange={handleInfo} value={info.birthPlace} name='birthPlace' placeholder={text.profile.birthPlace[language]}/>
                                <MaskedInput mask="11/11/1111" name="birthDate" onChange={handleInfo} value={info.birthDate} placeholder={text.profile.birthDate[language]}/>
                            </div>
                            <div className="multiple-inputs">
                                <Input onChange={handleInfo} value={info.street} name='street' placeholder={text.profile.street[language]}/>
                                <Input onChange={handleInfo} value={info.houseNumber} name='houseNumber' placeholder={text.profile.houseNumber[language]}/>
                            </div>
                            <div className="multiple-inputs">
                                <Select className='country-selector' onChange={e => handleInfo(e, 'country')} placeholder={text.profile.country[language]} value={info.country}>
                                    <Option value='usa'>{text.profile.usa[language]}</Option>
                                    <Option value='mex'>{text.profile.mex[language]}</Option>
                                </Select>
                                &nbsp;
                                <Select disabled={!info.country} className='country-selector' onChange={e => handleInfo(e, null, 'state')} placeholder={text.profile.state[language]} value={info.state}>
                                    {
                                        info.country === 'usa' ? 
                                            usaStates.map(
                                                state => <Option key={state} value={state}>{state}</Option>
                                            )
                                        :
                                            mexStates.map(
                                                state => <Option key={state} value={state}>{state}</Option>
                                            )   
                                    }
                                </Select>
                                &nbsp;
                                <Input maxLength='5' onChange={handleInfo} value={info.zip} name='zip' placeholder={text.profile.zip[language]}/>
                            </div>
                            {/* DIVISION */}
                            <p className='profile-form-subtitle2'>{text.profile.workInfo[language]}</p>
                            <div className="multiple-inputs-with-message">
                                <div className="input-with-message">    
                                    {
                                        maskedSSN ? 
                                            <MaskedInput mask="**-**-****" name="ssn" onChange={() => clearSSN()} value={maskedSSN} placeholder={text.userData.ssn[language]}/>
                                        :
                                            <MaskedInput mask="11-11-1111" name="ssn" onChange={handleInfo} value={info.ssn} placeholder={text.userData.ssn[language]}/>

                                    }
                                </div>
                                <Select className='country-selector' onChange={e => handleInfo({target: {value: e, name: 'civilStatus'}})} placeholder={text.userData.civilStatus[language]} value={info.civilStatus} defaultValue={info.civilStatus}>
                                    <Option value='married'>{text.profile.civilStatusOptions.married[language]}</Option>
                                    <Option value='single'>{text.profile.civilStatusOptions.single[language]}</Option>
                                    <Option value='separatedProperty'>{text.profile.civilStatusOptions.separatePropertyMarried[language]}</Option>
                                </Select>
                            </div>
                            <div className="multiple-inputs-with-message">
                                <div className="input-with-message">
                                    <Input onChange={handleInfo} value={info.occupation} name='occupation' placeholder={text.userData.occupation[language]}/>
                                    <p>{text.userData.occupationEx[language]}</p>
                                </div>
                                <div className="input-with-message">
                                    <Input onChange={handleInfo} value={info.company} name='company' placeholder={text.userData.company[language]}/>
                                    <p>{text.userData.companyEx[language]}</p>
                                </div>
                            </div>
                            <div className="multiple-inputs-with-message">
                                <div className="input-with-message">
                                    <Input onChange={handleInfo} value={info.position} name='position' placeholder={text.userData.position[language]}/>
                                    <p>{text.userData.positionEx[language]}</p>
                                </div>
                                <div className="input-with-message">
                                {
                                   language === 'english' ?
                                        <MaskedInput className='last-input' mask="(111) 111-1111" name="officePhone" onChange={handleInfo} value={info.officePhone} placeholder={text.userData.officePhone[language]}/>
                                    :
                                        <MaskedInput className='last-input' mask="(11) 1111-1111" name="officePhone" onChange={handleInfo} value={info.officePhone} placeholder={text.userData.officePhone[language]}/>
                                }
                                </div>
                            </div>
                        </div>
                        <div className="checkbox-input">
                            <Checkbox
                                checked={consent}
                                onChange={() => setConsent(!consent)}
                            >
                                {text.register.confirm[language]}
                            </Checkbox>
                        </div>
                        <div className="small-buttons multiple-buttons bottom-buttons">
                            <Button onClick={handleSubmit} loading={fetching} className='nav-button'>{text.button.save[language]}</Button>
                            <Button onClick={checkCompleted} className='nav-button'>{text.button.continue[language]}</Button>
                        </div>
                    </div>
            }
            <ConfirmationModal confirmAction={submitAction}>
                <div className="modal-container-confirm">
                    <p className='profile-form-subtitle2'>{text.profile.basicInfo[language]}</p>
                    <p>{text.profile.name[language]}: <span>{info.name} {info.lastName} {info.language === 'español' && info.secondLastName}</span></p>
                    <p>{text.profile.email[language]}: <span>{info.email}</span></p>
                    <p>{text.profile.phone[language]}: <span>{info.phone}</span></p>
                    <p>{text.profile.countryInfo[language]}: <span>{info.country === 'mex' ? text.profile.mex[language] : text.profile.usa[language]}</span></p>
                    <p>{text.profile.stateInfo[language]}: <span>{info.state}</span></p>
                    <p className='profile-form-subtitle2'>{text.profile.workInfo[language]}</p>
                    <p>{text.userData.ssn[language]}: <span>{info.ssn}</span></p>
                    <p>{text.userData.civilStatus[language]}: <span>{info.civilStatus}</span></p>
                    <p>{text.userData.occupation[language]}: <span>{info.occupation}</span></p>
                    <p>{text.userData.company[language]}: <span>{info.company}</span></p>
                    <p>{text.userData.position[language]}: <span>{info.position}</span></p>
                    <p>{text.userData.officePhone[language]}: <span>{info.officePhone}</span></p>
                </div>
            </ConfirmationModal>
        </div>
    )
}

let mapState = ({
    app: {language},
    user: {fetching},
    data: {info, dataUpdated, maskedSSN, maskedCreditSSN}
}) => ({
    language,
    fetching,
    info,
    dataUpdated,
    maskedSSN, 
    maskedCreditSSN
})

export default connect(
    mapState,
    {
        getData: getDataAction,
        handleInfo: handleInfoAction,
        updateUser: updateUserAction,
        handleStep: handleStepAction,
        toggleModal: handleConfirmationBoxAction,
        sendNotification: sendNotificationAction,
        changeLanguage: changeLangAction,
        clearSSN: clearSSNAction
    }
)(Profile)
