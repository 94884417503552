import { Button, Input, Spin } from 'antd'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getClientDataAction } from '../../../redux/dataDuck'
import text from '../../text'

const AdminUserProfile = ({language, fetching, selectedUserData, error, getData}) => {

    useEffect(() => {
        getData()
    }, [])
    return (
        <div className='application-container'>
             {
                fetching ? 
                    <div className='spinner-full-page'><Spin size='large'/></div>
                    :
                    <div className="application-content">
                        <div>
                            <div className="app-top-icon">
                                <img src="/img/sidebar/profile.png" alt="profile"/>
                                <p>{text.sidebar.userProfile[language]}</p>
                            </div>
                            <h2>{selectedUserData.name} {selectedUserData.lastName}</h2>
                        </div>
                        <div className='padded-app-container form-container'>
                            <p className='profile-form-subtitle2'>{text.profile.basicInfo[language]}</p>
                            <div className="multiple-inputs">
                                <Input value={selectedUserData.name} name='name' placeholder={text.register.firstName[language]}/>
                                <Input className={`${language === 'english' && 'last-input'}`} value={selectedUserData.lastName} name='lastName' placeholder={text.register.lastName[language]}/>
                                {language === 'español' && <Input required value={selectedUserData.secondLastName} name='secondLastName' placeholder={text.register.secondLastName[language]}/>}
                            </div>
                            {selectedUserData.userType !== 'buyer' && <Input id='company-app' value={selectedUserData.company} name='company' placeholder={text.register.company[language]}/>}
                            <div className="multiple-inputs">
                                <Input value={selectedUserData.email} name='email' placeholder={text.register.email[language]}/>
                                <Input maxLength='15' className='last-input' value={selectedUserData.phone} name='phone' placeholder={text.register.phone[language]}/>
                            </div>
                            <div className="multiple-inputs">
                            <Input value={selectedUserData.country} name='country' placeholder={text.profile.country[language]}/>
                            <Input className='last-input' value={selectedUserData.state} name='state' placeholder={text.profile.state[language]}/>
                            </div>
                            {/* DIVISION */}
                            <p className='profile-form-subtitle2'>{text.profile.workInfo[language]}</p>
                            <div className="multiple-inputs-with-message">
                                <div className="input-with-message">    
                                    <Input value={selectedUserData.ssn} name='ssn' placeholder={text.userData.ssn[language]}/>
                                </div>
                                <div className="input-with-message">
                                    <Input value={selectedUserData.civilStatus} name='civilStatus' placeholder={text.userData.civilStatus[language]}/>
                                </div>
                            </div>
                            <div className="multiple-inputs-with-message">
                                <div className="input-with-message">
                                    <Input value={selectedUserData.occupation} name='occupation' placeholder={text.userData.occupation[language]}/>
                                    <p>{text.userData.occupationEx[language]}</p>
                                </div>
                                <div className="input-with-message">
                                    <Input value={selectedUserData.company} name='company' placeholder={text.userData.company[language]}/>
                                    <p>{text.userData.companyEx[language]}</p>
                                </div>
                            </div>
                            <div className="multiple-inputs-with-message">
                                <div className="input-with-message">
                                    <Input value={selectedUserData.position} name='position' placeholder={text.userData.position[language]}/>
                                    <p>{text.userData.positionEx[language]}</p>
                                </div>
                                <div className="input-with-message">
                                    <Input value={selectedUserData.officePhone} name='officePhone' placeholder={text.userData.officePhone[language]}/>
                                </div>
                            </div>
                        </div>
                        <div className="small-buttons multiple-buttons">
                            <Link to='/admin/select-client'><Button loading={fetching} className='nav-button'>{text.button.returnCaps[language]}</Button></Link>
                            <Link to='/admin/documentation'><Button className='nav-button'>{text.button.continue[language]}</Button></Link>
                        </div>
                    </div>
            }
        </div>
    )
}

let mapState = ({
    app: {language},
    data: {fetching, selectedUserData, error}
}) => ({
    language,
    fetching,
    selectedUserData,
    error
})

export default connect(
    mapState,
    {
        getData: getClientDataAction
    }
)(AdminUserProfile)
