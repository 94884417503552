import React, { useEffect, useState } from 'react'
import { Button, Input, Form, message } from 'antd'
import { connect } from 'react-redux'
import { loginAction } from '../../redux/userDuck'
import text from '../text'

let LoginForm = ({history, language, info, fetching, error, login}) => {

    let [data, setData] = useState({})

    let onFinish = () => {
        let rex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if(!data.email || !rex.test(data.email.toLowerCase())) return message.warning(text.messages.incorrectEmail[language])
        if(!data.password) return message.error(text.messages.incorrectPassword[language])
        login(data)
    }

    let handleData = e => setData({...data, [e.target.name]: e.target.value})

    useEffect(() => {
        if(error.status === 403) return message.error(text.messages.wrongPassword[language])
        if(error.status === 500) return message.error(text.messages.requestError[language])
        if(info.step){
            switch(info.step){
                case 0:
                    if(info.userType === 'admin' || info.userType === 'agent') return history.push('/admin/select-client')
                    return history.push('/profile')
                case 1:
                    return history.push('/choose')
                case 2:
                    return history.push('/financing')
                case 3:
                    return history.push('/buy')
                case 4:
                    return history.push('/data')
                case 5:
                    return history.push('/documentation')
                case 6:
                    return history.push('/interior-design')
                default: 
                    return
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[info, error])

    return (
        <Form
            name="basic"
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
        >
            <Form.Item
                name="email"
                rules={[
                {
                    required: true,
                    message: text.form.required[language],
                },
                ]}
            >
                <Input name='email' onChange={handleData} placeholder={text.login.emailPlaceholder[language]}/>
            </Form.Item>

            <Form.Item
                name="password"
                rules={[
                {
                    required: true,
                    message: text.form.required[language],
                },
                ]}
            >
                <Input.Password name='password' onChange={handleData} placeholder={text.login.passwordPlaceholder[language]}/>
            </Form.Item>
            <Form.Item>
                <Button loading={fetching} className='nav-button' type="primary" htmlType="submit">
                    {text.button.login[language]}
                </Button>
            </Form.Item>
        </Form>
    )
}

const mapState = ({
    user: {info, fetching, error},
    app: {language}
}) => ({
    language,
    info,
    fetching,
    error
})

export default connect(
    mapState, 
    {
        login: loginAction
    }
)(LoginForm)
