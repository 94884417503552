import React, { useEffect, useState } from 'react'
import { Button, Spin } from 'antd'
import { connect } from 'react-redux'
import { getDataAction, updateUserAction } from '../../redux/dataDuck'
import text from '../text'
import { Link } from 'react-router-dom'

const FAQ = ({fetching, language}) => {

    return (
        <div className='application-container'>
             {
                fetching ? 
                    <div className='spinner-full-page'><Spin size='large'/></div>
                    :
                    <div className="application-content">
                        <div>
                            <div className="app-top-icon">
                                <img src="/img/sidebar/faq.svg" alt="profile"/>
                                <p>{text.sidebar.faq[language]}</p>
                            </div>
                        </div>
                        <div className='padded-app-container form-container'>
                            <div className="propietary-cards-container">
                                <Link to='/faq/services' className="propietary-card">
                                    <img src="/img/faq/FAQ-servicios.svg" alt="propietary"/>
                                    <p>{text.propietaryServices.publicServices[language]}</p>
                                </Link>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

let mapState = ({
    app: {language},
    user: {fetching}
}) => ({
    language,
    fetching
})

export default connect(
    mapState,
    {
        
    }
)(FAQ)
