import React, { useEffect } from 'react'
import { message, Spin } from 'antd'
import { connect } from 'react-redux'
import text from '../../text'
import { Link } from 'react-router-dom'
import { getAdminMessageAction } from '../../../redux/adminDuck'

const AdminHelp = ({language, fetching, info, selectedUser, messages, messageError, getMessages}) => {

    useEffect(() => {
        if(!fetching) getMessages(selectedUser)
    }, [fetching])

    useEffect(() => {
        if(messageError) return message.error(messageError.message)
    }, [messageError])

    return (
        <div className='application-container'>
             {
                fetching ? 
                    <div className='spinner-full-page'><Spin size='large'/></div>
                    :
                    <div className="application-content no-justify">
                        <div>
                            <div className="app-top-icon">
                                <img src="/img/sidebar/help.svg" alt="profile"/>
                                <p>{text.sidebar.help[language]}</p>
                            </div>
                        </div>
                        {
                            messages ? 
                                <div>
                                    <small>{text.help.agentQuestions[language]}:</small>
                                    <ul className='questions-container'>
                                        {
                                            messages.filter(message => message.user === selectedUser).map(message => <Link to={`/admin/help/${message._id}`}><li key={message._id}>{message.question}</li></Link>)
                                        }
                                    </ul>
                                </div>
                            :
                                null
                        }
                    </div>
            }
        </div>
    )
}

let mapState = ({
    data: {info, fetching, selectedUser},
    app: {language},
    admin: {messages, messageError}
}) => ({
    language,
    fetching,
    info,
    selectedUser,
    messages, 
    messageError
})

export default connect(
    mapState,
    {
        getMessages: getAdminMessageAction
    }
)(AdminHelp)
