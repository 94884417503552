import React, { useEffect, useState } from 'react'
import { Button, Input, message, Modal, Spin } from 'antd'
import { connect } from 'react-redux'
import { getDataAction, handleInfoAction, updateUserAction, handleFileAction, getFileAction, deleteFileAction, handleFinantialDataAction, sendNotificationAction, handleStepAction, clearSSNAction } from '../../../redux/dataDuck'
import text from '../../text'
import { Link } from 'react-router-dom'
import DocHandler from '../docHandler/DocHandler'
import PreviewModal from '../../common/PreviewModal'
import ConfirmationModal from '../../common/ConfirmationModal'
import { handleConfirmationBoxAction } from '../../../redux/appDuck'
import { MaskedInput } from 'antd-mask-input'
import { Document, Page, PDFViewer, Text, View, StyleSheet, Image } from '@react-pdf/renderer'

const FinanctialProfilesData = ({notificationSent, getFile, match, history, info, fetching, dataUpdated, language, getData, handleInfo, updateUser, handleFile, deleteFile, handleFinancialInfo, toggleModal, sendNotification, handleStep, clearSSN, maskedCreditSSN}) => {

    let [creditRequest, setCreditRequest] = useState({})
    
    let [loading, setLoading] = useState(false)
    let [visibleDocs, setVisibleDocs] = useState(false)
    let [visible, setVisible] = useState(false)
    let [numDocs, setNumDocs] = useState(0)
    let [docModal, setDocModal] = useState(false)
    // params.profile
    let { params } = match

    let saveData = () => {
        updateUser()
    }

    let handleSubmit = () => {
        if(
            !creditRequest.officePhone ||
            !creditRequest.homePhone ||
            !creditRequest.rfc ||
            !creditRequest.cellPhone ||
            !creditRequest.livingTime ||
            !creditRequest.occupation ||
            !creditRequest.address ||
            !creditRequest.companyName ||
            !creditRequest.companyField ||
            !creditRequest.companyAddress ||
            !creditRequest.companyTime ||
            !creditRequest.functionsDescription ||
            !creditRequest.companyRole ||
            !creditRequest.academicLevel ||
            !creditRequest.economicDependants ||
            !creditRequest.height ||
            !creditRequest.weight ||
            !creditRequest.ssn ||
            !creditRequest.carBrand ||
            !creditRequest.carValue ||
            !creditRequest.realStateNumber ||
            !creditRequest.realStateValue ||
            !creditRequest.contact1Name ||
            !creditRequest.contact1Address ||
            !creditRequest.contact1Phone ||
            !creditRequest.contact2Name ||
            !creditRequest.contact2Address ||
            !creditRequest.contact2Phone ||
            !creditRequest.contact3Name ||
            !creditRequest.contact3Address ||
            !creditRequest.contact3Phone
        ) return message.error(text.messages.missingFields[language])
        if(Object.keys(info.files).filter(
            key => info.files[key] !== false && key !== 'passport' && key !== 'driversLicence' && key !== 'addressProof'
        ).length < numDocs) return message.error(text.messages.missingFiles[language])
        toggleModal()
    }

    let confirmAction = () => {
        toggleModal()
        sendNotification('financeForm')
    }

    let changeProfile = () => {
        updateUser({economicProfile: null})
        setLoading(true)
        getData()
    }

    let downloadFile = name => {
        getFile(name)
    }

    let checkDocs = () => {
        switch(params.profile){
            case 'nat-salaried':
                return setNumDocs(text.financing.nationalSalariedDocs.length)
            case 'nat-independent':
                return setNumDocs(text.financing.nationalIndependentDocs.length)
            case 'ame-salaried':
                return setNumDocs(text.financing.americanSalariedDocs.length)
            case 'ame-independent':
                return setNumDocs(text.financing.americanIndependentDocs.length)
            case 'company':
                return setNumDocs(text.financing.companyDocs.length)
            default:
                return
        }
    }

    let toggleDocs = () => {
        if(
            !creditRequest.officePhone ||
            !creditRequest.homePhone ||
            !creditRequest.rfc ||
            !creditRequest.cellPhone ||
            !creditRequest.livingTime ||
            !creditRequest.occupation ||
            !creditRequest.address ||
            !creditRequest.companyName ||
            !creditRequest.companyField ||
            !creditRequest.companyAddress ||
            !creditRequest.companyTime ||
            !creditRequest.functionsDescription ||
            !creditRequest.companyRole ||
            !creditRequest.academicLevel ||
            !creditRequest.economicDependants ||
            !creditRequest.height ||
            !creditRequest.weight ||
            !creditRequest.ssn ||
            !creditRequest.carBrand ||
            !creditRequest.carValue ||
            !creditRequest.realStateNumber ||
            !creditRequest.realStateValue ||
            !creditRequest.contact1Name ||
            !creditRequest.contact1Address ||
            !creditRequest.contact1Phone ||
            !creditRequest.contact2Name ||
            !creditRequest.contact2Address ||
            !creditRequest.contact2Phone ||
            !creditRequest.contact3Name ||
            !creditRequest.contact3Address ||
            !creditRequest.contact3Phone
        ) return message.error(text.messages.missingFields[language])
        setDocModal(!docModal)
    }

    const styles = StyleSheet.create({
        page: {
          flexDirection: 'column',
          backgroundColor: 'white',
          justifyContent: 'flex-start',
          padding: 16,
          width: '100%'
        },
        section: {
          marginTop: 10,
          padding: '0 10px'
        },
        title: {
            fontSize: 17,
            fontWeight: 'bold',
            marginBottom: 0
        },  
        text: {
            fontSize: 11,
            margin: '5px 0',
            width: '100%',
            fontWeight: 800
        },
        subtitle: {
            marginTop: 10,
            fontSize: 10,
            margin: '5px 0',
            textDecoration: 'underline'
        },
        firstText: {
            fontSize: 10,
            fontWeight: 300
        }
      })

    useEffect(() => {
        if( !fetching && info.economicProfile === null) return history.push(`/financing`)
        if(info.creditRequest) return setCreditRequest(info.creditRequest)
    }, [info])

    useEffect(() => {
        if(notificationSent) return message.success(text.financingForm.notification[language])
        if(dataUpdated) return message.success(text.messages.dataUpdated[language])
        if(dataUpdated && loading) return history.push('/financing')
    }, [dataUpdated, notificationSent])

    useEffect(() => {
        getData()
        checkDocs()
    }, [])

    let handleSSN = event => {
        if(event.key === 'Backspace' || event.key === 'Delete') return handleFinancialInfo({target: {name: 'ssn', value: ''}}) 
        handleFinancialInfo(event)
    }

    return (
        <div className='application-container'>
             {
                fetching ? 
                    <div className='spinner-full-page'><Spin size='large'/></div>
                    :
                    <div className="application-content no-justify">
                        <div>
                            <div className="app-top-icon">
                                <img src="/img/sidebar/finance.svg" alt="profile"/>
                                <p>{text.sidebar.financing[language]}</p>
                            </div>
                        <h2>{text.financing.putDataTitle[language]}</h2>
                        </div>
                        <div className='padded-app-container form-container condensed-inputs'>
                            <p className='finance-form-subtitle1'>{text.financingForm.fillInputs[language]}</p>
                            <p className='finance-form-subtitle2'>{text.financingForm.primaryApplicant[language]}</p>
                            <div className="multiple-inputs">
                                {console.log(creditRequest)}
                                {
                                   language === 'english' ?
                                        <MaskedInput className='last-input' mask="(111) 111-1111" name="officePhone" onChange={handleFinancialInfo} value={creditRequest.officePhone} placeholder={text.userData.officePhone[language]}/>
                                    :
                                        <MaskedInput className='last-input' mask="(11) 1111-1111" name="officePhone" onChange={handleFinancialInfo} value={creditRequest.officePhone} placeholder={text.userData.officePhone[language]}/>
                                }
                                {
                                   language === 'english' ?
                                        <MaskedInput className='last-input' mask="(111) 111-1111" name="homePhone" onChange={handleFinancialInfo} value={creditRequest.homePhone} placeholder={text.financingForm.homePhone[language]}/>
                                    :
                                        <MaskedInput className='last-input' mask="(11) 1111-1111" name="homePhone" onChange={handleFinancialInfo} value={creditRequest.homePhone} placeholder={text.financingForm.homePhone[language]}/>
                                }
                                <Input onChange={handleFinancialInfo} value={creditRequest.rfc} name='rfc' placeholder={text.financingForm.rfc[language]}/>
                                {
                                   language === 'english' ?
                                        <MaskedInput className='last-input' mask="(111) 111-1111" name="cellPhone" onChange={handleFinancialInfo} value={creditRequest.cellPhone} placeholder={text.financingForm.cellPhone[language]}/>
                                    :
                                        <MaskedInput className='last-input' mask="(11) 1111-1111" name="cellPhone" onChange={handleFinancialInfo} value={creditRequest.cellPhone} placeholder={text.financingForm.cellPhone[language]}/>
                                }
                            </div>
                            <div className="multiple-inputs">
                                <Input onChange={handleFinancialInfo} value={creditRequest.email} name='email' placeholder={text.financingForm.email[language]}/>
                                <Input onChange={handleFinancialInfo} value={creditRequest.livingTime} name='livingTime' placeholder={text.financingForm.livingTime[language]}/>
                                <Input onChange={handleFinancialInfo} value={creditRequest.occupation} name='occupation' placeholder={text.financingForm.occupation[language]}/>
                            </div>
                            <p className='finance-form-subtitle2'>{text.financingForm.livesInHouse[language]}</p>
                            <div className="multiple-inputs">
                                <Input.TextArea rows='2' onChange={handleFinancialInfo} value={creditRequest.address} name='address' placeholder={text.financingForm.address[language]}/>
                            </div>
                            <div className="multiple-inputs">
                                <Input onChange={handleFinancialInfo} value={creditRequest.companyName} name='companyName' placeholder={text.financingForm.companyName[language]}/>
                                <Input onChange={handleFinancialInfo} value={creditRequest.companyField} name='companyField' placeholder={text.financingForm.companyField[language]}/>
                            </div>
                            <div className="multiple-inputs">
                                <Input onChange={handleFinancialInfo} value={creditRequest.companyAddress} name='companyAddress' placeholder={text.financingForm.companyAddress[language]}/>
                                <Input onChange={handleFinancialInfo} value={creditRequest.companyTime} name='companyTime' placeholder={text.financingForm.companyTime[language]}/>
                            </div>
                            <div className="multiple-inputs">
                                <Input onChange={handleFinancialInfo} value={creditRequest.functionsDescription} name='functionsDescription' placeholder={text.financingForm.functionsDescription[language]}/>
                            </div>
                            <div className="multiple-inputs">
                                <Input onChange={handleFinancialInfo} value={creditRequest.companyRole} name='companyRole' placeholder={text.financingForm.companyRole[language]}/>
                                <Input onChange={handleFinancialInfo} value={creditRequest.academicLevel} name='academicLevel' placeholder={text.financingForm.academicLevel[language]}/>
                                <Input onChange={handleFinancialInfo} value={creditRequest.economicDependants} name='economicDependants' placeholder={text.financingForm.economicDependants[language]}/>
                            </div>
                            <div className="multiple-inputs">
                                <Input onChange={handleFinancialInfo} value={creditRequest.height} name='height' placeholder={text.financingForm.height[language]}/>
                                <Input onChange={handleFinancialInfo} value={creditRequest.weight} name='weight' placeholder={text.financingForm.weight[language]}/>
                                {
                                    maskedCreditSSN ? 
                                        <MaskedInput mask="**-**-****" name="ssn" onChange={() => clearSSN('credit')} value={maskedCreditSSN} placeholder={text.userData.ssn[language]}/>
                                    :
                                        <MaskedInput mask="11-11-1111" name="ssn" onChange={handleFinancialInfo} value={creditRequest.ssn} placeholder={text.userData.ssn[language]}/>

                                }
                            </div>
                            <div className="multiple-inputs">
                                <Input onChange={handleFinancialInfo} value={creditRequest.carBrand} name='carBrand' placeholder={text.financingForm.carBrand[language]}/>
                                <Input onChange={handleFinancialInfo} value={creditRequest.carValue} name='carValue' placeholder={text.financingForm.carValue[language]}/>
                            </div>
                            <div className="multiple-inputs">
                                <Input onChange={handleFinancialInfo} value={creditRequest.realStateNumber} name='realStateNumber' placeholder={text.financingForm.realStateNumber[language]}/>
                                <Input onChange={handleFinancialInfo} value={creditRequest.realStateValue} name='realStateValue' placeholder={text.financingForm.realStateValue[language]}/>
                            </div>
                            <p className='finance-form-subtitle1'>{text.financingForm.referenceTitle[language]}</p>
                            <div className="multiple-inputs">
                                <Input onChange={handleFinancialInfo} value={creditRequest.contact1Name} name='contact1Name' placeholder={text.financingForm.contactName[language]}/>
                                <Input onChange={handleFinancialInfo} value={creditRequest.contact1Address} name='contact1Address' placeholder={text.financingForm.contactAddress[language]}/>
                                <Input maxLength='15' onChange={handleFinancialInfo} value={creditRequest.contact1Phone} name='contact1Phone' placeholder={text.financingForm.contactPhone[language]}/>
                            </div>
                            <div className="multiple-inputs">
                                <Input onChange={handleFinancialInfo} value={creditRequest.contact2Name} name='contact2Name' placeholder={text.financingForm.contactName[language]}/>
                                <Input onChange={handleFinancialInfo} value={creditRequest.contact2Address} name='contact2Address' placeholder={text.financingForm.contactAddress[language]}/>
                                <Input maxLength='15' onChange={handleFinancialInfo} value={creditRequest.contact2Phone} name='contact2Phone' placeholder={text.financingForm.contactPhone[language]}/>
                            </div>
                            <div className="multiple-inputs">
                                <Input onChange={handleFinancialInfo} value={creditRequest.contact3Name} name='contact3Name' placeholder={text.financingForm.contactName[language]}/>
                                <Input onChange={handleFinancialInfo} value={creditRequest.contact3Address} name='contact3Address' placeholder={text.financingForm.contactAddress[language]}/>
                                <Input maxLength='15' onChange={handleFinancialInfo} value={creditRequest.contact3Phone} name='contact3Phone' placeholder={text.financingForm.contactPhone[language]}/>
                            </div>
                            <div className="multiple-inputs">
                                <Input onChange={handleFinancialInfo} value={creditRequest.contact4Name} name='contact4Name' placeholder={text.financingForm.contactName[language]}/>
                                <Input onChange={handleFinancialInfo} value={creditRequest.contact4Address} name='contact4Address' placeholder={text.financingForm.contactAddress[language]}/>
                                <Input maxLength='15' onChange={handleFinancialInfo} value={creditRequest.contact4Phone} name='contact4Phone' placeholder={text.financingForm.contactPhone[language]}/>
                            </div>
                        </div>
                        <div className='padded-app-container form-container condensed-inputs'>
                          <p className="finance-docs-title">{
                            params.profile === 'nat-salaried' ? 
                                text.financing.nationalSalaried[language]
                            : params.profile === 'nat-independent' ? 
                                text.financing.nationalIndependent[language]
                            : params.profile === 'ame-salaried' ? 
                                text.financing.northAmericanSalaried[language]
                            : params.profile === 'ame-independent' ? 
                                text.financing.northAmericanIndependent[language]
                            : params.profile === 'foreign' ? 
                                text.financing.foreign[language]
                            : params.profile === 'company' ? 
                                text.financing.company[language]
                            :
                                null
                          }</p>
                          <ol className='finance-docs-list'>
                            {
                                params.profile === 'nat-salaried' ? 
                                    text.financing.nationalSalariedDocs.map((doc, ix) => 
                                        <li key={ix}>
                                            {doc[language]} {
                                                info.files && info.files[doc['id']] ?
                                                    <p>{info.files[doc['id']]} <img onClick={() => downloadFile(info.files[doc['id']])} src="/img/files/open.svg" alt="Open"/> <img onClick={() => deleteFile(info.files[doc['id']])} src="/img/files/delete.svg" alt="Delete"/></p>
                                                :
                                                    <input onChange={handleFile} name={doc['id']} type="file" accept='application/pdf, image/*'/>
                                            }
                                        </li>
                                    )
                                : params.profile === 'nat-independent' ? 
                                    text.financing.nationalIndependentDocs.map((doc, ix) => 
                                        <li key={ix}>
                                            {doc[language]} {
                                                info.files && info.files[doc['id']] ?
                                                    <p>{info.files[doc['id']]} <img onClick={() => downloadFile(info.files[doc['id']])} src="/img/files/open.svg" alt="Open"/> <img onClick={() => deleteFile(info.files[doc['id']])} src="/img/files/delete.svg" alt="Delete"/></p>
                                                :
                                                    <input onChange={handleFile} name={doc['id']} type="file" accept='application/pdf, image/*'/>
                                            }
                                        </li>
                                    )
                                : params.profile === 'ame-salaried' ? 
                                    text.financing.americanSalariedDocs.map((doc, ix) => 
                                        <li key={ix}>
                                            {doc[language]} {
                                                info.files && info.files[doc['id']] ?
                                                    <p>{info.files[doc['id']]} <img onClick={() => downloadFile(info.files[doc['id']])} src="/img/files/open.svg" alt="Open"/> <img onClick={() => deleteFile(info.files[doc['id']])} src="/img/files/delete.svg" alt="Delete"/></p>
                                                :
                                                    <input onChange={handleFile} name={doc['id']} type="file" accept='application/pdf, image/*'/>
                                            }
                                        </li>
                                    )
                                : params.profile === 'ame-independent' ? 
                                    text.financing.americanIndependentDocs.map((doc, ix) => 
                                        <li key={ix}>
                                            {doc[language]} {
                                                info.files && info.files[doc['id']] ?
                                                    <p>{info.files[doc['id']]} <img onClick={() => downloadFile(info.files[doc['id']])} src="/img/files/open.svg" alt="Open"/> <img onClick={() => deleteFile(info.files[doc['id']])} src="/img/files/delete.svg" alt="Delete"/></p>
                                                :
                                                    <input onChange={handleFile} name={doc['id']} type="file" accept='application/pdf, image/*'/>
                                            }
                                        </li>
                                    )
                                : params.profile === 'company' ? 
                                    text.financing.companyDocs.map((doc, ix) => 
                                        <li key={ix}>
                                            {doc[language]} {
                                                info.files && info.files[doc['id']] ?
                                                    <p>{info.files[doc['id']]} <img onClick={() => downloadFile(info.files[doc['id']])} src="/img/files/open.svg" alt="Open"/> <img onClick={() => deleteFile(info.files[doc['id']])} src="/img/files/delete.svg" alt="Delete"/></p>
                                                :
                                                    <input onChange={handleFile} name={doc['id']} type="file" accept='application/pdf, image/*'/>
                                            }
                                        </li>
                                    )
                                :
                                    null
                            }
                          </ol>
                        </div>
                        {info.agentNotified && <small>{text.financingForm.agent[language]}</small>}
                        <div className="multiple-buttons small-buttons">
                            <Button onClick={changeProfile} className='nav-button'>{text.button.returnCaps[language]}</Button>
                            <Button onClick={saveData} className='nav-button'>{text.button.save[language]}</Button>
                            <Button onClick={() => setVisible(true)} className='nav-button'>{text.button.preview[language]}</Button>
                            <Button disabled={info.agentNotified} onClick={handleSubmit} loading={fetching} className='nav-button'>{text.button.continue[language]}</Button>
                            <Button onClick={toggleDocs} className='nav-button'>{text.button.print[language]}</Button>
                        </div>
                        <DocHandler setVisible={setVisibleDocs} visible={visibleDocs} />
                    </div>
            }
            <div >
                <Modal
                    title={''}
                    visible={docModal}
                    onCancel={() => setDocModal(false)}
                    okText={'OK'}
                    onOk={() => setDocModal(false)}
                    className='doc-modal'
                >
                    <div className="modal-container-doc">
                    <PDFViewer>
                        <Document>
                        <Page size="A4" style={styles.page}>
                            <View style={styles.section}>
                                <Text style={styles.title}>PUNTA PIEDRA, {text.financingForm.docTitle[language]}</Text>
                            </View>
                            <View style={styles.section}>
                                <Text style={styles.text}><Text style={styles.firstText}>{text.financingForm.officePhone[language]}: </Text>{creditRequest.officePhone}</Text>
                                <Text style={styles.text}><Text style={styles.firstText}>{text.financingForm.homePhone[language]}: </Text>{creditRequest.homePhone}</Text>
                                <Text style={styles.text}><Text style={styles.firstText}>{text.financingForm.rfc[language]}: </Text>{creditRequest.rfc}</Text>
                                <Text style={styles.text}><Text style={styles.firstText}>{text.financingForm.cellPhone[language]}: </Text>{creditRequest.cellPhone}</Text>
                                <Text style={styles.text}><Text style={styles.firstText}>{text.financingForm.livingTime[language]}: </Text>{creditRequest.livingTime}</Text>
                                <Text style={styles.text}><Text style={styles.firstText}>{text.financingForm.occupation[language]}: </Text>{creditRequest.occupation}</Text>
                                <Text style={styles.text}><Text style={styles.firstText}>{text.financingForm.address[language]}: </Text>{creditRequest.address}</Text>
                                <Text style={styles.text}><Text style={styles.firstText}>{text.financingForm.companyName[language]}: </Text>{creditRequest.companyName}</Text>
                                <Text style={styles.text}><Text style={styles.firstText}>{text.financingForm.companyField[language]}: </Text>{creditRequest.companyField}</Text>
                                <Text style={styles.text}><Text style={styles.firstText}>{text.financingForm.companyAddress[language]}: </Text>{creditRequest.companyAddress}</Text>
                                <Text style={styles.text}><Text style={styles.firstText}>{text.financingForm.companyTime[language]}: </Text>{creditRequest.companyTime}</Text>
                                <Text style={styles.text}><Text style={styles.firstText}>{text.financingForm.functionsDescription[language]}: </Text>{creditRequest.functionsDescription}</Text>
                                <Text style={styles.text}><Text style={styles.firstText}>{text.financingForm.companyRole[language]}: </Text>{creditRequest.companyRole}</Text>
                                <Text style={styles.text}><Text style={styles.firstText}>{text.financingForm.academicLevel[language]}: </Text>{creditRequest.academicLevel}</Text>
                                <Text style={styles.text}><Text style={styles.firstText}>{text.financingForm.economicDependants[language]}: </Text>{creditRequest.economicDependants}</Text>
                                <Text style={styles.text}><Text style={styles.firstText}>{text.financingForm.height[language]}: </Text>{creditRequest.height}</Text>
                                <Text style={styles.text}><Text style={styles.firstText}>{text.financingForm.weight[language]}: </Text>{creditRequest.weight}</Text>
                                <Text style={styles.text}><Text style={styles.firstText}>{text.financingForm.ssn[language]}: </Text>{creditRequest.ssn}</Text>
                                <Text style={styles.text}><Text style={styles.firstText}>{text.financingForm.carBrand[language]}: </Text>{creditRequest.carBrand}</Text>
                                <Text style={styles.text}><Text style={styles.firstText}>{text.financingForm.carValue[language]}: </Text>{creditRequest.carValue}</Text>
                                <Text style={styles.text}><Text style={styles.firstText}>{text.financingForm.realStateNumber[language]}: </Text>{creditRequest.realStateNumber}</Text>
                                <Text style={styles.text}><Text style={styles.firstText}>{text.financingForm.realStateValue[language]}: </Text>{creditRequest.realStateValue}</Text>
                                <Text style={styles.subtitle}>{text.financingForm.docReferenceTitle[language]}</Text>
                                <Text style={styles.text}><Text style={styles.firstText}>{text.financingForm.contactName[language]}: </Text>{creditRequest.contact1Name}</Text>
                                <Text style={styles.text}><Text style={styles.firstText}>{text.financingForm.contactAddress[language]}: </Text>{creditRequest.contact1Address}</Text>
                                <Text style={styles.text}><Text style={styles.firstText}>{text.financingForm.contactPhone[language]}: </Text>{creditRequest.contact1Phone}</Text>
                                <Text style={styles.text}><Text style={styles.firstText}>{text.financingForm.contactName[language]}: </Text>{creditRequest.contact2Name}</Text>
                                <Text style={styles.text}><Text style={styles.firstText}>{text.financingForm.contactAddress[language]}: </Text>{creditRequest.contact2Address}</Text>
                                <Text style={styles.text}><Text style={styles.firstText}>{text.financingForm.contactPhone[language]}: </Text>{creditRequest.contact2Phone}</Text>
                                <Text style={styles.text}><Text style={styles.firstText}>{text.financingForm.contactName[language]}: </Text>{creditRequest.contact3Name}</Text>
                                <Text style={styles.text}><Text style={styles.firstText}>{text.financingForm.contactAddress[language]}: </Text>{creditRequest.contact3Address}</Text>
                                <Text style={styles.text}><Text style={styles.firstText}>{text.financingForm.contactPhone[language]}: </Text>{creditRequest.contact3Phone}</Text>
                            </View>
                        </Page>
                        </Document>
                    </PDFViewer>
                    </div>
                </Modal>
            </div>
            <div className='preview-modal'>
                <PreviewModal visible={visible} setVisible={setVisible}>
                    <div className="modal-container-confirm">
                        <p>{text.financingForm.officePhone[language]}: <span>{creditRequest.officePhone}</span></p>
                        <p>{text.financingForm.homePhone[language]}: <span>{creditRequest.homePhone}</span></p>
                        <p>{text.financingForm.rfc[language]}: <span>{creditRequest.rfc}</span></p>
                        <p>{text.financingForm.cellPhone[language]}: <span>{creditRequest.cellPhone}</span></p>
                        <p>{text.financingForm.livingTime[language]}: <span>{creditRequest.livingTime}</span></p>
                        <p>{text.financingForm.occupation[language]}: <span>{creditRequest.occupation}</span></p>
                        <p>{text.financingForm.address[language]}: <span>{creditRequest.address}</span></p>
                        <p>{text.financingForm.companyName[language]}: <span>{creditRequest.companyName}</span></p>
                        <p>{text.financingForm.companyField[language]}: <span>{creditRequest.companyField}</span></p>
                        <p>{text.financingForm.companyAddress[language]}: <span>{creditRequest.companyAddress}</span></p>
                        <p>{text.financingForm.companyTime[language]}: <span>{creditRequest.companyTime}</span></p>
                        <p>{text.financingForm.functionsDescription[language]}: <span>{creditRequest.functionsDescription}</span></p>
                        <p>{text.financingForm.companyRole[language]}: <span>{creditRequest.companyRole}</span></p>
                        <p>{text.financingForm.academicLevel[language]}: <span>{creditRequest.academicLevel}</span></p>
                        <p>{text.financingForm.economicDependants[language]}: <span>{creditRequest.economicDependants}</span></p>
                        <p>{text.financingForm.height[language]}: <span>{creditRequest.height}</span></p>
                        <p>{text.financingForm.weight[language]}: <span>{creditRequest.weight}</span></p>
                        <p>{text.financingForm.ssn[language]}: <span>{creditRequest.ssn}</span></p>
                        <p>{text.financingForm.carBrand[language]}: <span>{creditRequest.carBrand}</span></p>
                        <p>{text.financingForm.carValue[language]}: <span>{creditRequest.carValue}</span></p>
                        <p>{text.financingForm.realStateNumber[language]}: <span>{creditRequest.realStateNumber}</span></p>
                        <p>{text.financingForm.realStateValue[language]}: <span>{creditRequest.realStateValue}</span></p>
                        <p>{text.financingForm.contactName[language]}: <span>{creditRequest.contact1Name}</span></p>
                        <p>{text.financingForm.contactAddress[language]}: <span>{creditRequest.contact1Address}</span></p>
                        <p>{text.financingForm.contactPhone[language]}: <span>{creditRequest.contact1Phone}</span></p>
                        <p>{text.financingForm.contactName[language]}: <span>{creditRequest.contact2Name}</span></p>
                        <p>{text.financingForm.contactAddress[language]}: <span>{creditRequest.contact2Address}</span></p>
                        <p>{text.financingForm.contactPhone[language]}: <span>{creditRequest.contact2Phone}</span></p>
                        <p>{text.financingForm.contactName[language]}: <span>{creditRequest.contact3Name}</span></p>
                        <p>{text.financingForm.contactAddress[language]}: <span>{creditRequest.contact3Address}</span></p>
                        <p>{text.financingForm.contactPhone[language]}: <span>{creditRequest.contact3Phone}</span></p>
                    </div>
                </PreviewModal>
            </div>
            <ConfirmationModal confirmAction={confirmAction}>
                <div className="modal-container-confirm">
                    <p>{text.financingForm.officePhone[language]}: <span>{creditRequest.officePhone}</span></p>
                    <p>{text.financingForm.homePhone[language]}: <span>{creditRequest.homePhone}</span></p>
                    <p>{text.financingForm.rfc[language]}: <span>{creditRequest.rfc}</span></p>
                    <p>{text.financingForm.cellPhone[language]}: <span>{creditRequest.cellPhone}</span></p>
                    <p>{text.financingForm.livingTime[language]}: <span>{creditRequest.livingTime}</span></p>
                    <p>{text.financingForm.occupation[language]}: <span>{creditRequest.occupation}</span></p>
                    <p>{text.financingForm.address[language]}: <span>{creditRequest.address}</span></p>
                    <p>{text.financingForm.companyName[language]}: <span>{creditRequest.companyName}</span></p>
                    <p>{text.financingForm.companyField[language]}: <span>{creditRequest.companyField}</span></p>
                    <p>{text.financingForm.companyAddress[language]}: <span>{creditRequest.companyAddress}</span></p>
                    <p>{text.financingForm.companyTime[language]}: <span>{creditRequest.companyTime}</span></p>
                    <p>{text.financingForm.functionsDescription[language]}: <span>{creditRequest.functionsDescription}</span></p>
                    <p>{text.financingForm.companyRole[language]}: <span>{creditRequest.companyRole}</span></p>
                    <p>{text.financingForm.academicLevel[language]}: <span>{creditRequest.academicLevel}</span></p>
                    <p>{text.financingForm.economicDependants[language]}: <span>{creditRequest.economicDependants}</span></p>
                    <p>{text.financingForm.height[language]}: <span>{creditRequest.height}</span></p>
                    <p>{text.financingForm.weight[language]}: <span>{creditRequest.weight}</span></p>
                    <p>{text.financingForm.ssn[language]}: <span>{creditRequest.ssn}</span></p>
                    <p>{text.financingForm.carBrand[language]}: <span>{creditRequest.carBrand}</span></p>
                    <p>{text.financingForm.carValue[language]}: <span>{creditRequest.carValue}</span></p>
                    <p>{text.financingForm.realStateNumber[language]}: <span>{creditRequest.realStateNumber}</span></p>
                    <p>{text.financingForm.realStateValue[language]}: <span>{creditRequest.realStateValue}</span></p>
                    <p>{text.financingForm.contactName[language]}: <span>{creditRequest.contact1Name}</span></p>
                    <p>{text.financingForm.contactAddress[language]}: <span>{creditRequest.contact1Address}</span></p>
                    <p>{text.financingForm.contactPhone[language]}: <span>{creditRequest.contact1Phone}</span></p>
                    <p>{text.financingForm.contactName[language]}: <span>{creditRequest.contact2Name}</span></p>
                    <p>{text.financingForm.contactAddress[language]}: <span>{creditRequest.contact2Address}</span></p>
                    <p>{text.financingForm.contactPhone[language]}: <span>{creditRequest.contact2Phone}</span></p>
                    <p>{text.financingForm.contactName[language]}: <span>{creditRequest.contact3Name}</span></p>
                    <p>{text.financingForm.contactAddress[language]}: <span>{creditRequest.contact3Address}</span></p>
                    <p>{text.financingForm.contactPhone[language]}: <span>{creditRequest.contact3Phone}</span></p>
                </div>
            </ConfirmationModal>
        </div>
    )
}

let mapState = ({
    app: {language},
    user: {fetching},
    data: {info, dataUpdated, notificationSent, maskedCreditSSN}
}) => ({
    language,
    fetching,
    info,
    dataUpdated,
    notificationSent,
    maskedCreditSSN
})

export default connect(
    mapState,
    {
        getData: getDataAction,
        handleInfo: handleInfoAction,
        updateUser: updateUserAction,
        handleFile: handleFileAction,
        getFile: getFileAction,
        deleteFile: deleteFileAction,
        handleFinancialInfo: handleFinantialDataAction,
        toggleModal: handleConfirmationBoxAction,
        sendNotification: sendNotificationAction,
        handleStep: handleStepAction,
        clearSSN: clearSSNAction
    }
)(FinanctialProfilesData)
