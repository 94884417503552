import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getDataAction, getHouseDataAction, handleStepAction, sendNotificationAction, updateUserAction } from '../../redux/dataDuck'
import { Button, Input, message, Spin } from 'antd'
import { Link } from 'react-router-dom'
import text from '../text'
import BuyHouseMap from './buyHouseComponents/BuyHouseMap'
import ConfirmationModal from '../common/ConfirmationModal'
import { handleConfirmationBoxAction } from '../../redux/appDuck'

const BuyHouse = ({sendNotification, history,info, language, fetchingData, fetchingHouse, house, getHouse, getData, toggleModal, handleStep, updateUser}) => {


    let handleSubmit = () => {
        if(!info.villaApproved || !info.priceApproved) return message.warning(text.messages.needApprove[language])
        toggleModal()
    }

    let confirmAction = async () => {
        await updateUser()
        toggleModal()
        if(info.step === 3 || info.step === 4) {
            sendNotification('buyHouse')
            await handleStep(5)
        }
        history.push('/interior-design')
    }

    useEffect(() => {
        if(info.lotId >= 0) getHouse()
    }, [info.lotId])

    useEffect(() => {
        getData()
    }, [])

    return (
        <div className='application-container'>
            {
                !info.block ? 
                    <div className='spinner-full-page'><p>{text.buyHouse.chooseFirst[language]}</p></div>
                : fetchingData || fetchingHouse ? 
                    <div className='spinner-full-page'><Spin size='large'/></div>
                :
                    <div className="application-content no-justify">
                        <div>
                            <div className="app-top-icon">
                                <img src="/img/sidebar/buy.svg" alt="profile"/>
                                <p>{text.sidebar.buyHouse[language]}</p>
                            </div>
                            <h2>{text.sidebarSubtitle.buyHouse[language]}</h2>
                        </div>
                        <div className='padded-app-container form-container'>
                            <div className="multiple-inputs map-inputs">
                                <Input disabled value={`${text.chooseHouse.inputLotNumber[language]}: ${house.lotNumber}`}/>
                                <Input disabled value={`${text.chooseHouse.inputLotSize[language]}: ${house.lotSize}`} suffix={<small>m<sup>2</sup></small>}/>
                                <Input disabled value={`${text.chooseHouse.inputLotModel[language]}: ${house.villa}`} />
                            </div>
                        </div>
                        {house.images && <BuyHouseMap language={language} house={house}/>}
                        <div className='multiple-buttons small-buttons bottom-buttons'>
                            <Link to={info.wantFinance ? '/financing' : '/choose'}><Button loading={false} className='nav-button'>{text.button.returnCaps[language]}</Button></Link>
                            <Button onClick={handleSubmit} loading={false} className='nav-button'>{text.button.approve[language]}</Button>
                        </div>
                    </div>
            }
            <ConfirmationModal confirmAction={confirmAction}>
                <div className="modal-container-confirm">
                    <p>{text.chooseHouse.block[language]}: <span>{info.block}</span></p>
                    <p>{text.chooseHouse.inputLotNumber[language]}: <span>{house.lotNumber}</span></p>
                    <p>{text.chooseHouse.inputLotSize[language]}: <span>{house.lotSize}</span> <small>m<sup>2</sup></small></p>
                    <p>{text.chooseHouse.inputLotModel[language]}: <span>{house.villa}</span></p>
                </div>
            </ConfirmationModal>
        </div>
    )
}

let mapState = ({
    app: {language},
    data: {fetchingData, info, house, fetchingHouse}
}) => ({
    language,
    fetchingData,
    fetchingHouse,
    house,
    info
})

export default connect(
    mapState,
    {
        getData: getDataAction,
        getHouse: getHouseDataAction,
        toggleModal: handleConfirmationBoxAction,
        handleStep: handleStepAction,
        updateUser: updateUserAction,
        sendNotification: sendNotificationAction
    }
)(BuyHouse)
