import { Button, Input, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getClientDataAction, getHousesAction } from '../../../redux/dataDuck'
import text from '../../text'

const AdminFinishes = ({houses, language, fetching, selectedUserData, error, getData, getHouses}) => {

    useEffect(() => {
        getData()
    }, [])

    return (
        <div className='application-container'>
             {
                fetching ? 
                    <div className='spinner-full-page'><Spin size='large'/></div>
                    :
                    <div className="application-content">
                        <div>
                            <div className="app-top-icon">
                                <img src="/img/sidebar/intDesign.svg" alt="profile"/>
                                <p>{text.sidebar.clientFinishes[language]}</p>
                            </div>
                            <h2>{selectedUserData.name} {selectedUserData.lastName}</h2>
                        </div>
                        <div className="admin-house-choose">
                            <p>{text.interiorDesign.kitchen[language]}:</p>
                            <p className='admin-response'>{selectedUserData.kitchenCabinet}</p>
                            <p>{text.interiorDesign.closet[language]}:</p>
                            <p className='admin-response'>{selectedUserData.closetCabinet}</p>
                            <p>{text.interiorDesign.floors[language]}:</p>
                            <p className='admin-response'>{selectedUserData.floor}</p>
                            <p>{text.interiorDesign.showerTile[language]}:</p>
                            <p className='admin-response'>{selectedUserData.showerTile}</p>
                        </div>
                        <div className="multiple-buttons">
                            <Link to='/admin/buy'><Button loading={fetching} className='nav-button'>{text.button.returnCaps[language]}</Button></Link>
                        </div>
                    </div>
            }
        </div>
    )
}

let mapState = ({
    app: {language},
    data: {fetching, selectedUserData, error, houses}
}) => ({
    language,
    fetching,
    selectedUserData,
    error,
    houses
})

export default connect(
    mapState,
    {
        getData: getClientDataAction,
        getHouses: getHousesAction
    }
)(AdminFinishes)
