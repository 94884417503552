import React, { useEffect, useState } from 'react'
import { Input, Button, message, Select, Checkbox, Modal } from 'antd'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { signupAction } from '../../../redux/userDuck'
import text from '../../text'
import { changeLangAction } from '../../../redux/appDuck'
import { MaskedInput } from 'antd-mask-input'
import { providerCodes } from './codes'

let { Option } = Select

let ProviderData = ({fetching, language, error, info, signup, history, changeLanguage}) => {

    let [code, setCode] = useState('')
    let [data, setData] = useState({
        userType: 'provider'
    })

    let handleData = e => {
        setData({...data, [e.target.name]: e.target.value})
    }

    let showTerms = () => {
        Modal.info({
            content: (
                <div className='info-div'>
                    {text.terms[language]}
                </div>
            ),
            width: '90%',
            className:'info-modal',
            okText: text.register.agreeTerms[language],
            onOk: () => signup(data)
        })
    }

    let handleSubmit = () => {
        if(!data.name ||
            !data.lastName ||
            (!data.secondLastName && language === 'español') ||
            !data.company ||
            !data.email ||
            !data.phone
        ) 
        return message.warning(text.messages.missingFields[language])
        let rex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!rex.test(data.email.toLowerCase())) return message.warn(text.messages.incorrectEmail[language])
        if(!data.password || data.password.length < 6) return message.warn(text.messages.incorrectPassword[language])
        if(data.password !== data.repeatPassword) return message.warn(text.messages.repeatedPasswordError[language])
        if(!providerCodes.find(el => el === code)) return message.error(text.register.codeErrorProvider[language])
        if(!data.consent) return message.warn(text.messages.consent[language])
        showTerms()
    }

    let handleCode = e => {
        setCode(e.target.value)
    }

    useEffect(() => {
        if(error.status === 500) return message.error('Ingresa todos los campos')
        if(info.name) return history.push('/admin/select-client')
    }, [error, info])

    return (
        <div className='form-container'>
            <div className="multiple-inputs">
                <Input onChange={handleData} value={data.name} name='name' placeholder={text.register.firstName[language]}/>
                <Input className={`${language === 'english' && 'last-input'}`} onChange={handleData} value={data.lastName} name='lastName' placeholder={text.register.lastName[language]}/>
                {language === 'español' && <Input required onChange={handleData} value={data.secondLastName} name='secondLastName' placeholder={text.register.secondLastName[language]}/>}
            </div>
            <Input onChange={handleData} value={data.company} name='company' placeholder={text.register.company[language]}/>
            <div className="multiple-inputs">
                <Input onChange={handleData} value={data.email} name='email' placeholder={text.register.email[language]}/>
                {
                    language === 'english' ?
                        <MaskedInput className='last-input' mask="(111) 111-1111" name="phone" onChange={handleData} value={data.phone} placeholder={text.register.phone[language]}/>
                    :
                        <MaskedInput className='last-input' mask="(11) 1111-1111" name="phone" onChange={handleData} value={data.phone} placeholder={text.register.phone[language]}/>
                }
            </div>
            <div className="multiple-inputs">
                <Select className='agent-selector' onChange={value => {setData({...data, language: value}); changeLanguage(value)}} placeholder={text.register.language[language]} value={data.language} defaultValue={data.language}>
                    <Option value='english'>English</Option>
                    <Option value='español'>Español</Option>
                </Select>
            </div>
            <Input.Password onChange={handleData} value={data.password} name='password' placeholder={text.register.password[language]}/>
            <Input.Password onChange={handleData} value={data.repeatPassword} name='repeatPassword' placeholder={text.register.repeatPassword[language]}/>
            <Input onChange={handleCode} value={code} name='code' placeholder={text.register.providerCode[language]}/>
            <br/><br/>
            <div className="checkbox-input">
                <Checkbox
                    checked={data.consent}
                    onChange={e => setData({...data, consent: e.target.checked})}
                >
                    {text.register.confirm[language]}
                </Checkbox>
            </div>
            <div className="checkbox-input">
                <Checkbox
                    checked={data.allowNewsletter}
                    onChange={e => setData({...data, allowNewsletter: e.target.checked})}
                >
                    {text.register.allowNewsletter[language]}
                </Checkbox>
            </div>
            <br/><br/>
            <Button loading={fetching} onClick={handleSubmit} className='nav-button'>{text.button.register[language]}</Button>
            <div className="auth-bottom-text">
                <p>{text.register.login[language]} <Link to='/login'>{text.register.loginLink[language]}</Link></p>
            </div>
        </div>
    )
}

let mapState = ({
    user: {fetching, error, info},
    app: {language}
}) => ({
    fetching,
    error, 
    info,
    language
})

export default connect(
    mapState,
    {
        signup: signupAction,
        changeLanguage: changeLangAction
    }
)(ProviderData)