import { addHouse, sendQuestion, getMessages, getSingleMessage, sendMessage, getAllMessages, sendAdminMessage } from "../services/api"
import { Cookies } from 'react-cookie'

let initialData = {
    houseData: {},
    fetching: false,
    error: {},
    houseUploaded: false,
    messages: [],
    messageError: false,
    messageSent: false,
    currentMessage: {}
}

let ADD_HOUSE = 'ADD_HOUSE'
let ADD_HOUSE_SUCCESS = 'ADD_HOUSE_SUCCESS'
let ADD_HOUSE_ERROR = 'ADD_HOUSE_ERROR'

let GET_MESSAGE = 'GET_MESSAGE'
let GET_MESSAGE_SUCCESS = 'GET_MESSAGE_SUCCESS'
let GET_SINGLE_MESSAGE_SUCCESS = 'GET_SINGLE_MESSAGE_SUCCESS'
let GET_MESSAGE_ERROR = 'GET_MESSAGE_ERROR'

let SEND_MESSAGE = 'SEND_MESSAGE'
let SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS'
let SEND_MESSAGE_ERROR = 'SEND_MESSAGE_ERROR'

let SEND_QUESTION = 'SEND_QUESTION'
let SEND_QUESTION_SUCCESS = 'SEND_QUESTION_SUCCESS'
let SEND_QUESTION_ERROR = 'SEND_QUESTION_ERROR'

let HANDLE_DATA = 'HANDLE_DATA'

let reducer = (state = initialData, action) => {
    switch(action.type){
        case ADD_HOUSE:
            return {...state, houseUploaded: false, fetching: true, error: {}}
        case ADD_HOUSE_SUCCESS:
            return {...state, fetching: false, houseUploaded: true, houseData: {}}
        case ADD_HOUSE_ERROR:
            return {...state, fetching: false, error: action.payload}

        case GET_MESSAGE:
            return {...state, messageError: false}
        case GET_MESSAGE_SUCCESS:
            return {...state, messages: action.payload}
        case GET_SINGLE_MESSAGE_SUCCESS:
            return {...state, currentMessage: action.payload}
        case GET_MESSAGE_ERROR:
            return {...state, messageError: action.payload}

        case SEND_MESSAGE:
            return {...state, messageError: false, messageSent: false}
        case SEND_MESSAGE_SUCCESS:
            return {...state, messageSent: true}
        case SEND_MESSAGE_ERROR:
            return {...state, messageError: action.payload}

        case SEND_QUESTION:
            return {...state, messageError: false, messageSent: false}
        case SEND_QUESTION_SUCCESS:
            return {...state, messageSent: true}
        case SEND_QUESTION_ERROR:
            return {...state, messageError: action.payload}

        case HANDLE_DATA:
            return {...state, houseData: action.payload}

        default:
            return {...state}
    }
}

export default reducer

export let getMessageAction = () => (dispatch, getState) => {
    dispatch({type: GET_MESSAGE})
    let { info: {email} } = getState().user
    getMessages(email)
        .then(res => dispatch({
            type: GET_MESSAGE_SUCCESS,
            payload: res.data.messages
        }))
        .catch(err => dispatch({
            type: GET_MESSAGE_ERROR,
            payload: err.response
        }))
}

export let getAdminMessageAction = id => (dispatch, getState) => {
    dispatch({type: GET_MESSAGE})
    getAllMessages()
        .then(res => dispatch({
            type: GET_MESSAGE_SUCCESS,
            payload: res.data
        }))
        .catch(err => dispatch({
            type: GET_MESSAGE_ERROR,
            payload: err.response
        }))
}

export let getSingleMessageAction = id => (dispatch, getState) => {
    dispatch({type: GET_MESSAGE})
    getSingleMessage(id)
        .then(res => dispatch({
            type: GET_SINGLE_MESSAGE_SUCCESS,
            payload: res.data
        }))
        .catch(err => dispatch({
            type: GET_MESSAGE_ERROR,
            payload: err.response
        }))
}

export let sendAdminMessageAction = (id, message) => (dispatch, getState) => {
    dispatch({type: SEND_MESSAGE})
    sendAdminMessage(id, message)
        .then(res => {
            dispatch({
                type: SEND_MESSAGE_SUCCESS
            })
            getSingleMessage(id)
                .then(res => dispatch({
                    type: GET_SINGLE_MESSAGE_SUCCESS,
                    payload: res.data
                }))
                .catch(err => dispatch({
                    type: GET_MESSAGE_ERROR,
                    payload: err.response
                }))
        })
        .catch(err => dispatch({
            type: SEND_MESSAGE_ERROR,
            payload: err.response
        }))
}

export let sendMessageAction = (id, message) => (dispatch, getState) => {
    dispatch({type: SEND_MESSAGE})
    let { info: {email} } = getState().user
    sendMessage(id, email, message)
        .then(res => {
            dispatch({
                type: SEND_MESSAGE_SUCCESS
            })
            getSingleMessage(id)
                .then(res => dispatch({
                    type: GET_SINGLE_MESSAGE_SUCCESS,
                    payload: res.data
                }))
                .catch(err => dispatch({
                    type: GET_MESSAGE_ERROR,
                    payload: err.response
                }))
        })
        .catch(err => dispatch({
            type: SEND_MESSAGE_ERROR,
            payload: err.response
        }))
}

export let sendQuestionAction = question => (dispatch, getState) => {
    dispatch({type: SEND_QUESTION})
    let { info: {email} } = getState().user
    sendQuestion(email, question)
        .then(res => dispatch({
            type: SEND_QUESTION_SUCCESS
        }))
        .catch(err => dispatch({
            type: SEND_QUESTION_ERROR,
            payload: err.response
        }))
}

export let addHouseAction = () => (dispatch, getState) => {
    let id = new Cookies().get('id')
    if(!id) return dispatch({
        type: ADD_HOUSE_ERROR,
        payload: {status: 403}
    })
    dispatch({
        type: ADD_HOUSE
    })
    let { houseData } = getState().admin
    addHouse({id, houseData})
        .then(res => dispatch({
            type: ADD_HOUSE_SUCCESS
        }))
        .catch(err => dispatch({
            type: ADD_HOUSE_ERROR,
            payload: err.response
        }))
}

export let handleHouseDataAction = (event, images, lotId) => (dispatch, getState) => {
    let { houseData } = getState().admin
    if(!event && !images){
        dispatch({
            type: HANDLE_DATA,
            payload: { ...houseData, lotId}
        })
        return
    }
    if(!event){
        dispatch({
            type: HANDLE_DATA,
            payload: { ...houseData, images}
        })
        return
    }
    dispatch({
        type: HANDLE_DATA,
        payload: { ...houseData, [event.target.name]:event.target.value}
    })
}