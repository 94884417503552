import { Button, Input, message, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { changeLangAction } from '../../redux/appDuck'
import { signupAction } from '../../redux/userDuck'
import text from '../text'

let { Option } = Select

let AdminRegister = ({history, fetching, language, error, info, signup, changeLanguage}) => {

    let [data, setData] = useState({
        userType: 'admin'
    })

    let handleData = e => {
        setData({...data, [e.target.name]: e.target.value})
    }

    let handleSubmit = () => {
        if(!data.name ||
             !data.lastName ||
             (!data.secondLastName && language === 'español') ||
             !data.email
            ) 
            return message.warning(text.messages.missingFields[language])
        let rex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!rex.test(data.email.toLowerCase())) return message.warn(text.messages.incorrectEmail[language])
        if(!data.password || data.password.length < 6) return message.warn(text.messages.incorrectPassword[language])
        if(data.password !== data.repeatPassword) return message.warn(text.messages.repeatedPasswordError[language])
        signup(data)
    }

    useEffect(() => {
        if(error.status === 500) return message.error('Ingresa todos los campos')
        if(info.name) return history.push('/admin/select-client')
    }, [error, info])
    
    return (
        <div className='padded-container register-container'>
            <div className="left-register register-form-bg">
                <img src="/img/common/logo-white.png" alt="punta piedra logo"/>
            </div>
            <div className="right-register-block">
                <img className='title-icon' src="/img/register/profile.png" alt="Perfil"/>
                <p className='title'>{text.register.adminPortal[language]}</p>
                <p className='subtitle'>{text.register.adminPortalRegister[language]}</p>
                <div className='form-container'>
                    <div className="multiple-inputs">
                        <Input onChange={handleData} value={data.name} name='name' placeholder={text.register.firstName[language]}/>
                        <Input className={`${language === 'english' && 'last-input'}`} onChange={handleData} value={data.lastName} name='lastName' placeholder={text.register.lastName[language]}/>
                        {language === 'español' && <Input required onChange={handleData} value={data.secondLastName} name='secondLastName' placeholder={text.register.secondLastName[language]}/>}
                    </div>
                    <div className="multiple-inputs">
                        <Input onChange={handleData} value={data.email} name='email' placeholder={text.register.email[language]}/>
                        <Input maxLength='15' className='last-input' onChange={handleData} value={data.phone} name='phone' placeholder={text.register.phone[language]}/>
                    </div>
                    <div className="multiple-inputs">
                        <Select className='agent-selector' onChange={value => {setData({...data, language: value}); changeLanguage(value)}} placeholder={text.register.language[language]} value={data.language} defaultValue={data.language}>
                            <Option value='english'>English</Option>
                            <Option value='español'>Español</Option>
                        </Select>
                    </div>
                    <Input.Password onChange={handleData} value={data.password} name='password' placeholder={text.register.password[language]}/>
                    <Input.Password onChange={handleData} value={data.repeatPassword} name='repeatPassword' placeholder={text.register.repeatPassword[language]}/>
                    <br/><br/>
                    <Button loading={fetching} onClick={handleSubmit} className='nav-button'>{text.button.register[language]}</Button>
                    <div className="auth-bottom-text">
                        <p>{text.register.login[language]} <Link to='/login'>{text.register.loginLink[language]}</Link></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

let mapState = ({
    user: {fetching, error, info},
    app: {language}
}) => ({
    language,
    fetching,
    error, 
    info
})

export default connect(
    mapState,
    {
        signup: signupAction,
        changeLanguage: changeLangAction
    }
)(AdminRegister)