import { Button, Input, message, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getClientDataAction, getHousesAction, approveFinancingAction } from '../../../redux/dataDuck'
import text from '../../text'

const AdminFinance = ({dataUpdated, name, lastName, language, fetching, creditRequest = {}, error, getData, approveFinance}) => {

    useEffect(() => {
        if(dataUpdated) return message.success(text.admin.approved[language])
    }, [dataUpdated])

    useEffect(() => {
        getData()
    }, [])

    return (
        <div className='application-container'>
             {
                fetching ? 
                    <div className='spinner-full-page'><Spin size='large'/></div>
                    :
                    <div className="application-content no-justify">
                        <div>
                            <div className="app-top-icon">
                                <img src="/img/sidebar/finance.svg" alt="profile"/>
                                <p>{text.sidebar.userFinance[language]}</p>
                            </div>
                            <h2>{name} {lastName}</h2>
                        </div>
                        <div className='padded-app-container form-container condensed-inputs'>
                            <div className="multiple-inputs">
                                <Input maxLength='15' value={creditRequest.officePhone} name='officePhone' placeholder={text.financingForm.officePhone[language]}/>
                                <Input maxLength='15' value={creditRequest.homePhone} name='homePhone' placeholder={text.financingForm.homePhone[language]}/>
                                <Input value={creditRequest.rfc} name='rfc' placeholder={text.financingForm.rfc[language]}/>
                                <Input maxLength='15' value={creditRequest.cellPhone} name='cellPhone' placeholder={text.financingForm.cellPhone[language]}/>
                            </div>
                            <div className="multiple-inputs">
                                <Input value={creditRequest.email} name='email' placeholder={text.financingForm.email[language]}/>
                                <Input value={creditRequest.livingTime} name='livingTime' placeholder={text.financingForm.livingTime[language]}/>
                                <Input value={creditRequest.occupation} name='occupation' placeholder={text.financingForm.occupation[language]}/>
                            </div>
                            <p className='finance-form-subtitle2'>{text.financingForm.livesInHouse[language]}</p>
                            <div className="multiple-inputs">
                                <Input value={creditRequest.address} name='address' placeholder={text.financingForm.address[language]}/>
                            </div>
                            <div className="multiple-inputs">
                                <Input value={creditRequest.companyName} name='companyName' placeholder={text.financingForm.companyName[language]}/>
                                <Input value={creditRequest.companyField} name='companyField' placeholder={text.financingForm.companyField[language]}/>
                            </div>
                            <div className="multiple-inputs">
                                <Input value={creditRequest.companyAddress} name='companyAddress' placeholder={text.financingForm.companyAddress[language]}/>
                                <Input value={creditRequest.companyTime} name='companyTime' placeholder={text.financingForm.companyTime[language]}/>
                            </div>
                            <div className="multiple-inputs">
                                <Input value={creditRequest.functionsDescription} name='functionsDescription' placeholder={text.financingForm.functionsDescription[language]}/>
                            </div>
                            <div className="multiple-inputs">
                                <Input value={creditRequest.companyRole} name='companyRole' placeholder={text.financingForm.companyRole[language]}/>
                                <Input value={creditRequest.academicLevel} name='academicLevel' placeholder={text.financingForm.academicLevel[language]}/>
                                <Input value={creditRequest.economicDependants} name='economicDependants' placeholder={text.financingForm.economicDependants[language]}/>
                            </div>
                            <div className="multiple-inputs">
                                <Input value={creditRequest.height} name='height' placeholder={text.financingForm.height[language]}/>
                                <Input value={creditRequest.weight} name='weight' placeholder={text.financingForm.weight[language]}/>
                                <Input value={creditRequest.ssn} name='ssn' placeholder={text.financingForm.ssn[language]}/>
                            </div>
                            <div className="multiple-inputs">
                                <Input value={creditRequest.carBrand} name='carBrand' placeholder={text.financingForm.carBrand[language]}/>
                                <Input value={creditRequest.carValue} name='carValue' placeholder={text.financingForm.carValue[language]}/>
                            </div>
                            <div className="multiple-inputs">
                                <Input value={creditRequest.realStateNumber} name='realStateNumber' placeholder={text.financingForm.realStateNumber[language]}/>
                                <Input value={creditRequest.realStateValue} name='realStateValue' placeholder={text.financingForm.realStateValue[language]}/>
                            </div>
                            <p className='finance-form-subtitle1'>{text.financingForm.referenceTitle[language]}</p>
                            <div className="multiple-inputs">
                                <Input value={creditRequest.contact1Name} name='contact1Name' placeholder={text.financingForm.contactName[language]}/>
                                <Input value={creditRequest.contact1Address} name='contact1Address' placeholder={text.financingForm.contactAddress[language]}/>
                                <Input maxLength='15' value={creditRequest.contact1Phone} name='contact1Phone' placeholder={text.financingForm.contactPhone[language]}/>
                            </div>
                            <div className="multiple-inputs">
                                <Input value={creditRequest.contact2Name} name='contact2Name' placeholder={text.financingForm.contactName[language]}/>
                                <Input value={creditRequest.contact2Address} name='contact2Address' placeholder={text.financingForm.contactAddress[language]}/>
                                <Input maxLength='15' value={creditRequest.contact2Phone} name='contact2Phone' placeholder={text.financingForm.contactPhone[language]}/>
                            </div>
                            <div className="multiple-inputs">
                                <Input value={creditRequest.contact3Name} name='contact3Name' placeholder={text.financingForm.contactName[language]}/>
                                <Input value={creditRequest.contact3Address} name='contact3Address' placeholder={text.financingForm.contactAddress[language]}/>
                                <Input maxLength='15' value={creditRequest.contact3Phone} name='contact3Phone' placeholder={text.financingForm.contactPhone[language]}/>
                            </div>
                            <div className="multiple-inputs">
                                <Input value={creditRequest.contact4Name} name='contact4Name' placeholder={text.financingForm.contactName[language]}/>
                                <Input value={creditRequest.contact4Address} name='contact4Address' placeholder={text.financingForm.contactAddress[language]}/>
                                <Input maxLength='15' value={creditRequest.contact4Phone} name='contact4Phone' placeholder={text.financingForm.contactPhone[language]}/>
                            </div>
                            <Button onClick={approveFinance} className='nav-button'>{text.admin.approveFinancing[language]}</Button>
                        </div>
                        <div className="multiple-buttons">
                            <Link to='/admin/choose'><Button loading={fetching} className='nav-button'>{text.button.returnCaps[language]}</Button></Link>
                            <Link to='/admin/buy'><Button className='nav-button'>{text.button.continue[language]}</Button></Link>
                        </div>
                    </div>
            }
        </div>
    )
}

let mapState = ({
    app: {language},
    data: {fetching, selectedUserData: {creditRequest, name, lastName}, error, dataUpdated}
}) => ({
    language,
    fetching,
    creditRequest,
    error,
    dataUpdated,
    name,
    lastName
})

export default connect(
    mapState,
    {
        getData: getClientDataAction,
        getHouses: getHousesAction,
        approveFinance: approveFinancingAction
    }
)(AdminFinance)
