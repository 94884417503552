import React from 'react'
import { Button } from 'antd'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import LoginForm from './LoginForm'
import text from '../text'

let Login = ({language}) => {
    return (
        <div className='padded-container'>
            <div className='auth-container'>
                <div className="left-register register-bg">
                    <img src="/img/common/logo-white.png" alt="punta piedra logo"/>
                </div>
                <div className="auth-right-content">
                    <img className='title-icon' src="/img/register/profile.png" alt="Perfil"/>
                    <p className='title'>{text.login.portalTitle[language]}</p>
                    <p className='subtitle'>{text.login.portalSubtitle[language]}</p>
                    <br/>
                    <LoginForm/>
                    <div className="auth-bottom-text">
                        <p>{text.form.forgotPassword[language]} <Link to='/recover-password'>{text.form.forgotPasswordLink[language]}</Link></p>
                        <p>{text.form.register[language]} <Link to='/register'>{text.form.registerLink[language]}</Link></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

let mapState = ({
    app: {language}
}) => ({
    language
})

export default connect(
    mapState,
    {
        
    }
)(Login)