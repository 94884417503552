export default [
    {
        question: 'Where are the closest ATMs to Punta Piedra?',
        answers: [
            {
                text: "Gary's La Fonda - La mision off main free road - Exit Alisitos if on toll road - https://goo.gl/maps/hz1vpUpFMZtDj5tP6",
                location: 'https://goo.gl/maps/hz1vpUpFMZtDj5tP6',
                phone: ''
            },
            {
                text: "Farmacia Roma - Primo Tapia off main free road, small town north of La Mision - Exit Cantamar if on toll road - https://g.page/romaprimotapia?share",
                location: 'https://g.page/romaprimotapia?share',
                phone: ''
            },
            {
                text: "El Jefe  - Primo Tapia off main free road, small town north of La Mision - Exit Cantamar if on toll road - https://goo.gl/maps/NRq5YE4rjANyxZpU8",
                location: 'https://goo.gl/maps/NRq5YE4rjANyxZpU8',
                phone: ''
            },
            {
                text: "Calimax - Primo Tapia/ Puerto Nuevo off main free road - Exit Puerto Nuevo if on toll road - https://goo.gl/maps/hbj4i1TZGm5ZtEBJ9",
                location: 'https://goo.gl/maps/hbj4i1TZGm5ZtEBJ9',
                phone: ''
            },
            {
                text: "BP Gas station - Primo Tapia off main free road - Exit Cantamar if on toll road - https://goo.gl/maps/Z3w1iPcM4VuzNGT76",
                location: 'https://goo.gl/maps/Z3w1iPcM4VuzNGT76',
                phone: ''
            },

        ],
    },
    {
        question: 'Where are the closest gas stations to Punta Piedra?',
        answers: [
            {
                text: "Arco - Off the Punta Piedra exit on toll road - https://goo.gl/maps/7c9CGvPepWYrRt9f9",
                location: 'Off the Punta Piedra Exit',
                phone: ''
            },
            {
                text: "BP - Primo Tapia off free main road - Exit Cantamar if on toll road - https://goo.gl/maps/kXXPqXvreczd3LUF8",
                location: 'Primo Tapia/ Cantamar Exit',
                phone: ''
            },
            {
                text: "Chevron - Primo Tapia off free main road - Exit Puerto Nuevo if on toll road - https://g.page/chevron-puertonuevo?share ",
                location: 'Primo Tapia/Puerto Nuevo',
                phone: ''
            }
        ],
    },
    {
        question: 'Where are the closest department or clothing stores to Punta Piedra?',
        answers: [
            {
                text: "Walmart - North of Rosarito in the shopping mall known as Pabellon Rosarito - https://goo.gl/maps/Z3w1iPcM4VuzNGT76 ",
                location: 'https://goo.gl/maps/Z3w1iPcM4VuzNGT76',
                phone: ''
            },
            {
                text: "Suburbia - North of Rosarito in the shopping mall known as Pabellon Rosarito - https://goo.gl/maps/S2WPmeZxYQwG4v4D8",
                location: 'https://goo.gl/maps/S2WPmeZxYQwG4v4D8',
                phone: ''
            },
            {
                text: "Coppell - North of Rosarito in the shopping mall known as Pabellon Rosarito - https://goo.gl/maps/bTVTk9Sif1Nfh8wq9",
                location: 'https://goo.gl/maps/bTVTk9Sif1Nfh8wq9',
                phone: ''
            },
            {
                text: "Walmart - South of Ensenada in the shopping mall known as Macroplaza- https://goo.gl/maps/5FpnfjBHGrqSuBBx9",
                location: 'https://goo.gl/maps/5FpnfjBHGrqSuBBx9',
                phone: ''
            },
            {
                text: "Costco - South of Ensenada, across the street from mall known as Macroplaza - https://goo.gl/maps/HKrHciNr84At7bH66",
                location: 'https://goo.gl/maps/HKrHciNr84At7bH66',
                phone: ''
            }
        ],
    },
    {
        question: 'What is the closest supermarket to Punta Piedra?',
        answers: [
            {
                text: "Calimax - Primo Tapia / Puerto Nuevo off main free road, Exit Puerto Nuevo if on toll road - https://goo.gl/maps/hbj4i1TZGm5ZtEBJ9",
                location: 'https://goo.gl/maps/hbj4i1TZGm5ZtEBJ9',
                phone: ''
            },
            {
                text: "Costco - South of Ensenada, across the street from mall known as Macroplaza - https://goo.gl/maps/HKrHciNr84At7bH66",
                location: 'https://goo.gl/maps/HKrHciNr84At7bH66',
                phone: ''
            }
        ],
    },
    {
        question: 'What are the closest convenience stores and/or mom and pop shops?',
        answers: [
            {
                text: "Oxxo - Off Punta Piedra Exit off of Toll Road - https://goo.gl/maps/vEUomEjasWFPpfH89",
                location: 'https://goo.gl/maps/vEUomEjasWFPpfH89',
                phone: ''
            },
            {
                text: "Oxxo - Alisitos off main free road exit off toll road, - https://goo.gl/maps/fe9NWzdKq6vCTzfW9",
                location: 'https://goo.gl/maps/fe9NWzdKq6vCTzfW9',
                phone: ''
            },
            {
                text: "Sub agencia y abarrotes- Ejido La mision off free road  https://g.page/Abarroteslamision?share",
                location: 'https://g.page/Abarroteslamision?share',
                phone: ''
            },
            {
                text: "Oxxo - Ejido La mision off free road https://goo.gl/maps/RKiXy1uTiJoDHrna6",
                location: 'https://goo.gl/maps/RKiXy1uTiJoDHrna6',
                phone: ''
            }
        ],
    },
    {
        question: 'What are the closest liquor stores?',
        answers: [
            {
                text: "Oxxo - Off Punta Piedra Exit off of Toll Road - https://goo.gl/maps/vEUomEjasWFPpfH89",
                location: 'https://goo.gl/maps/vEUomEjasWFPpfH89',
                phone: ''
            },
            {
                text: "Oxxo - Alisitos off main free road exit off toll road, - https://goo.gl/maps/fe9NWzdKq6vCTzfW9",
                location: 'https://goo.gl/maps/fe9NWzdKq6vCTzfW9',
                phone: ''
            },
            {
                text: "Sub agencia y abarrotes- Ejido La mision off free road  https://g.page/Abarroteslamision?share",
                location: 'https://g.page/Abarroteslamision?share',
                phone: ''
            },
            {
                text: "Oxxo - Ejido La mision off free road https://goo.gl/maps/RKiXy1uTiJoDHrna6",
                location: 'https://goo.gl/maps/RKiXy1uTiJoDHrna6',
                phone: ''
            }
        ],
    },
    {
        question: 'How do I get gas?',
        answers: [
            {
                text: "At Punta Piedra East and West you need to call HOA first (646)155-0494, if problem is not general contact gas company Baja Gas (661) 612-1012 on other comunities You can contract with a propane company for delivery to your home or, if using the 5 gal. tanks, have them filled or exchanged locally yourself.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: 'Can I use my US barbecue here?',
        answers: [
            {
                text: "Absolutely!  In some cases you may need to buy an adaptor for a US-manufactured BBQ hose.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: 'How do I pay property taxes?',
        answers: [
            {
                text: "Property taxes are paid yearly at the municipal government offices, and may also be paid online.  The annual fees, of course, depend on the size and development of your property.",
                location: 'https://goo.gl/maps/B3Ft9FzNc5jHhchZ7',
                phone: ''
            }
        ],
    },
    {
        question: 'What are the taxes here?',
        answers: [
            {
                text: "The annual property taxes, as in the US and other countries, are based on the size and development of your property.  They are substantially lower than in the US.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: 'What will my monthly water bills look like?',
        answers: [
            {
                text: "Depende del consumo. The State Water Comission (Comisión Estatal del Agua) is the water provider.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: 'What will my monthly gas bills look like?',
        answers: [
            {
                text: "Natural gas is not used in our area, so we are talking about propane gas.  And, again, it will depend on usage.  A typical refill of a 5 gal. tank ('mina') runs around 225 pesos.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: 'What will my monthly electric bills look like?',
        answers: [
            {
                text: "CFE is the government agency who provides electricity services.  They are responsible for maintaining power to your meter, and you are responsible for the lines from the meter to your property.  Fees, of course, depend on usage, and if you keep usage within a certain parameter, cost is less than going over that quota. Tipically, the electricity service for residencial use, is billed every two (2) months.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: 'What will my monthly telephone bills look like?',
        answers: [
            {
                text: "Telephone services in most areas are provided by TelNor.  They can bill you online, or by paper.  ",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: 'How do I pay them?',
        answers: [
            {
                text: "People pay their bills at the offices in Rosarito or Ensenada, or else at authorised locations, such as the Roma Pharmacy in Primo Tapia, they can also be paid in convenience stores such as Oxxo and 7-eleven. All payments must be made in pesos. Some can also be paid online if you register to do so.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: 'Can we drink the water?',
        answers: [
            {
                text: "The local water varies in quality, depending on whre you are.  In most cases, it is used just for laundry, dishwashing, and bathing.  Bottled water and dispensers are recommended for drinking and cooking.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: 'What about local vegetables?',
        answers: [
            {
                text: "Luckily, local vegetables are mostly grown organically, since Monsanto products are not authorised in Mexico. There are many fruterias (produce markets) in the area, including the local mom and pop shops.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: 'How often do power outages happen?',
        answers: [
            {
                text: "This happens on occasion, and rarely. Usually it is due to severe storms or auto accidents involving power poles.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: 'How often to telephone lines go down?',
        answers: [
            {
                text: "This happens on occasion, but more frequently than power outages. Usually it is due to severe storms or auto accidents involving power poles.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: 'How can I get US TV channels?',
        answers: [
            {
                text: "Some expats from the US are contracted with Netflix or other digital TV in the States, and bring their dishes or boxes down with them. ",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: 'How can I get a cell phone?',
        answers: [
            {
                text: "If you already have a cellphone from another country you should have service in Mexico -- check with your provider about out-of-country access and fees.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: 'Is this property in Rosarito or Ensenada?',
        answers: [
            {
                text: "Your agent and property deed will clarify which municipality (county) your property is in.  ",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: 'What emergency agencies can I count on? Police? ',
        answers: [
            {
                text: "La Mision and Primo Tapia both have local police stations, and there is a reassuring presence in our area of state, federal, and military authorities as well.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: 'What emergency agencies can I count on? Firemen? ',
        answers: [
            {
                text: "In our area, fire safety is provided by a volunteer organisation.  Give generously!",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: 'What emergency agencies can I count on? Ambulances?',
        answers: [
            {
                text: "Ambulance services are best contracted through yearly membership in Cruz Roja, who can arrange ambulance transport to the US border.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: 'What do non-Mexicans do about healthcare services?',
        answers: [
            {
                text: "This area of Baja is popular for US and Canadian citizens because of access to San Diego transportation and healthcare.  For Mexico Permanent Residents, nationalised healthcare services are available.  You can also 'pay as you go' for dental and medical care much more affordably in Mexico.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: 'Veterinary care?',
        answers: [
            {
                text: "Besides vet hospitals in the cities of Rosarito and Ensenada, there is a very good vet in the Puerto Nuevo/El Pescador area.  There are also many compassionate expats who provide rescue and adoption services.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: 'Psychiatric services?',
        answers: [
            {
                text: "As with medical services, US citizens are best served in San Diego.  There are, however, very good, bilingual psychiatrists and therapists in the area.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: 'I have young children/grandchildren. Are there reputable schools they can attend here?',
        answers: [
            {
                text: "For families moving to the area, just as in other parts of the world, there are both public and private schools.  Some private schools, which are in Rosarito and Ensenada, offer bilingual and English instruction.  There are also very reputable online school systems set up specifically to serve students abroad.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: 'Should I get a residency visa, or a tourist visa?',
        answers: [
            {
                text: "Tourist visas are valid for six (6) months; if you intend to live in Mexico full time, securing a Residency Visa is not only recommended, but required.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: 'How do I get a residency visa?',
        answers: [
            {
                text: "Check out the Secretaría de Relaciones Exteriores (Mministry of Foreing Affairs) website to learn about the requirements (page in Spanish)  https://www.gob.mx/tramites/ficha/visa-de-residencia-temporal/SRE260",
                location: 'https://www.gob.mx/tramites/ficha/visa-de-residencia-temporal/SRE260',
                phone: ''
            }
        ],
    },
    {
        question: 'How do I get a permanent visa?',
        answers: [
            {
                text: "Check out the Secretaría de Relaciones Exteriores (Mministry of Foreing Affairs) website to learn about the requirements (page in Spanish)   https://www.gob.mx/sre/acciones-y-programas/visa-de-residencia-permanente",
                location: 'https://www.gob.mx/sre/acciones-y-programas/visa-de-residencia-permanente',
                phone: ''
            }
        ],
    },
    {
        question: 'How does owning property affect here if I have a resdency or a visa?',
        answers: [
            {
                text: "Property ownership is based on having -- or eventually acquiring -- permanent residency status or citizenship.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: 'What about repairs on the house?',
        answers: [
            {
                text: "Your HOA, realtor, neighbours, and new friends can help you find the right workers to support you in your new home.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: 'How can I get a good plumber/electrician?',
        answers: [
            {
                text: "Your HOA, realtor, neighbours, and new friends can help you find the right workers to support you in your new home.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: 'Can I get a housekeeper?',
        answers: [
            {
                text: "Housekeeping, gardening, and handiman services are plentiful.Your HOA, realtor, neighbours, and new friends can help you find the right workers to support you in your new home. ",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: 'Gardener?',
        answers: [
            {
                text: "Housekeeping, gardening, and handiman services are plentiful.Your HOA, realtor, neighbours, and new friends can help you find the right workers to support you in your new home.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: 'What are the going rates?',
        answers: [
            {
                text: "It is recommended that you arrange a fee with workers based on the job, not by the hour or by the day.  It is also recommended that you pay at the end of a job only, except for supplies.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: 'What about auto mechanics?',
        answers: [
            {
                text: "One caveat about Mexico is that friendliness sometimes means people often saying 'I can do that for you'.  Not everyone who calls himself a mechanic is really competent, so check with neighbours and friends for personal referrals.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: 'RV repair?',
        answers: [
            {
                text: "There is an RV resort very close by, and they should have referrals of RV-specific mechanics.",
                location: 'https://www.campgroundreviews.com/regions/baja-california/ensenada',
                phone: ''
            }
        ],
    },
    {
        question: 'Where are the supermarkets?',
        answers: [
            {
                text: "For people if the greater La Mision area, the nearest supermarket is in Puerto Nuevo.  A variety of shopping options is available in both Ensenada and Rosarito, and there are several smaller 'mom & pop' grocery stores locally, in addition to the prevalent OXXO convenience stores.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: 'Do I have to use pesos all the time, or can I use dollars?',
        answers: [
            {
                text: "Most businesses will accept both currencies as of this writing.  Although each business chooses its own exchange rate, they generally adhere to the daily national standard.  In Tijuana, Rosarito, Ensenada, and nearby Primo Tapia there are exchange kiosks -- casas de cambio -- that will convert your dollars to pesos and vice versa.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: 'Are there English-speaking churches or services in my area?',
        answers: [
            {
                text: "Yes.  There are English-speaking Catholic, Episcopalian, and evangelical services in the area, and also JW, Buddhist, and LDS facilities in other parts of the state, which may or may not be English-capable.",
                location: '',
                phone: ''
            }
        ],
    },
    {
        question: 'What about AA?',
        answers: [
            {
                text: "There are AA and NA groups in the cities and in Primo Tapia.",
                location: '',
                phone: ''
            }
        ],
    },
]