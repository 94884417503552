import React, { useEffect, useState } from 'react'
import { Button, Input, message, Select, Spin, Modal } from 'antd'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { handleConfirmationBoxAction } from '../../redux/appDuck'
import { getDataAction, getHousesAction, handleAdditionalHouseAction, handleHouseAction, handleStepAction, sendNotificationAction, updateUserAction } from '../../redux/dataDuck'
import ConfirmationModal from '../common/ConfirmationModal'
import text from '../text'
import { ChooseHouseMap } from './chooseHouseComponents/ChooseHouseMap'

let { Option } = Select

const ChooseHouse = ({sendNotification, houses, dataUpdated, history, language, fetching, info, getData, handleHouse, updateUser, toggleModal, getHouses, handleStep, handleAddHouse}) => {

    let [additionalLot, setAdditionalLot] = useState(false)
    let [selectedHouse, setSelectedHouse] = useState({})
    let [selectedAdditional, setSelectedAdditional] = useState({})

    let handleSubmit = () => {
        if(info.lotId === null || info.lotId === undefined || !info.block) return message.warning(text.messages.missingFields[language])
        if(additionalLot){
            if(info.additionalLotId === null || info.additionalLotId === undefined || !info.additionalBlock) return message.warning(text.messages.missingFields[language])
            toggleModal()
            return
        }
        toggleModal()
    }

    let confirmAction = async () => {
        await updateUser()
        toggleModal()
        if(info.wantFinance){
            return history.push('/financing')
        }
        return history.push('/buy')
        // if(info.step === 2){
        //     sendNotification('chooseHouse')
        //     if(info.wantFinance){
        //         await handleStep(3)
        //         return history.push('/financing')
        //     }
        //     await handleStep(4)
        // }
        // if(info.step === 3) history.push('/financing')
        // if(info.step === 4) history.push('/buy')
    }

    let saveData = () => {
        updateUser()
        handleModal()
    }

    let handleModal = () =>{
        Modal.success({
            title: text.messages.gotIt[language]
        })
    }

    useEffect(() => {
        if(info.lotId !== null && info.lotId !== undefined && houses.length) setSelectedHouse(houses[info.lotId])
        if(info.additionalLotId !== null && info.additionalLotId !== undefined && houses.length) {
            setAdditionalLot(true)
            setSelectedAdditional(houses[info.additionalLotId])
        }
    }, [info, houses])

    useEffect(() => {
        if(dataUpdated) return message.success(text.messages.dataUpdated[language])
    }, [dataUpdated])
    
    useEffect(() => {
        getData()
        getHouses()
    }, [])

    return (
        <div className='application-container'>
            {
                fetching ? 
                    <div className='spinner-full-page'><Spin size='large'/></div>
                :
                    <div className="application-content no-justify">
                        <div>
                            <div className="app-top-icon">
                                <img src="/img/sidebar/choose.png" alt="profile"/>
                                <p>{text.sidebar.chooseHouse[language]}</p>
                            </div>
                            <h2>{text.sidebarSubtitle.chooseHouse[language]}</h2>
                        </div>
                        <div className='padded-app-container form-container'>
                            <div className="multiple-inputs map-inputs">
                                <Select
                                    style={{width: '350px'}}
                                    className='select-input'
                                    value={info.block}
                                    placeholder={text.chooseHouse.block[language]}
                                    onChange={e => {handleHouse(e); setSelectedHouse({})}}
                                >
                                    {
                                        houses.filter((lot,index,self)=> self.findIndex(l=>(l.block === lot.block)) === index).map(lot => <Option key={lot.lotNumber} value={lot.block}>{text.chooseHouse.block[language]} {lot.block}</Option>)
                                    }
                                </Select>
                                <Select
                                    style={{width: '300px'}}
                                    className='select-input'
                                    value={info.lotId}
                                    placeholder={text.chooseHouse.inputLotNumber[language]}
                                    onChange={e => handleHouse(null, e)}
                                >
                                    {
                                        houses.filter(lot=> lot.block === info.block).map(lot => <Option key={lot.lotId} value={lot.lotId}>{text.chooseHouse.inputLotNumber[language]} {lot.lotNumber}</Option>)
                                    }
                                </Select>
                                <Input style={{width: '320px'}} value={selectedHouse.lotSize} suffix={<small>m<sup>2</sup></small>} name='lotSize' placeholder={text.chooseHouse.inputLotSize[language]}/>
                                <Input style={{width: '320px'}} value={selectedHouse.villa ? `Villa ${selectedHouse.villa}` : null} name='villa' placeholder={text.chooseHouse.inputLotModel[language]}/>
                                {!additionalLot && <Button onClick={() => setAdditionalLot(true)} className='solid-button'>+ {text.chooseHouse.addLot[language]}</Button>}
                            </div>
                            {
                                additionalLot ? 
                                    <div className="multiple-inputs map-inputs">
                                        <Select
                                            style={{width: '350px'}}
                                            className='select-input'
                                            value={info.additionalBlock}
                                            placeholder={text.chooseHouse.block[language]}
                                            onChange={e => {handleAddHouse(e); setSelectedAdditional({})}}
                                        >
                                            {
                                                houses.filter((lot,index,self)=> self.findIndex(l=>(l.block === lot.block)) === index).map(lot => <Option key={lot.lotNumber} value={lot.block}>{text.chooseHouse.block[language]} {lot.block}</Option>)
                                            }
                                        </Select>
                                        <Select
                                            style={{width: '300px'}}
                                            className='select-input'
                                            value={info.additionalLotId}
                                            placeholder={text.chooseHouse.inputLotNumber[language]}
                                            onChange={e => handleAddHouse(null, e)}
                                        >
                                            {
                                                houses.filter(lot=> lot.block === info.additionalBlock).map(lot => <Option key={lot.lotId} value={lot.lotId}>{text.chooseHouse.inputLotNumber[language]} {lot.lotNumber}</Option>)
                                            }
                                        </Select>
                                        <Input style={{width: '320px'}} value={selectedAdditional.lotSize} suffix={<small>m<sup>2</sup></small>} name='lotSize' placeholder={text.chooseHouse.inputLotSize[language]}/>
                                        <Input style={{width: '320px'}} value={selectedAdditional.villa ? `Villa ${selectedAdditional.villa}` : null} name='villa' placeholder={text.chooseHouse.inputLotModel[language]}/>
                                        {additionalLot && <Button onClick={() => {setAdditionalLot(false); handleAddHouse('none'); setSelectedAdditional({})}} className='solid-button'>- {text.chooseHouse.addLot[language]}</Button>}
                                    </div>
                                :
                                    null
                            }
                        </div>
                        <ChooseHouseMap language={language} selectedHouse={selectedHouse}/>
                        <div className='multiple-buttons small-buttons bottom-buttons'>
                            <Link to='/profile'><Button loading={fetching} className='nav-button'>{text.button.returnCaps[language]}</Button></Link>
                            <Button onClick={saveData} className='nav-button'>{text.button.save[language]}</Button>
                            <Button onClick={handleSubmit} loading={fetching} className='nav-button'>{text.button.continue[language]}</Button>
                        </div>
                    </div>
            }
            <ConfirmationModal confirmAction={confirmAction}>
                <div className="modal-container-confirm">
                    <p>{text.chooseHouse.block[language]}: <span>{info.block}</span></p>
                    <p>{text.chooseHouse.inputLotNumber[language]}: <span>{selectedHouse.lotNumber}</span></p>
                    <p>{text.chooseHouse.inputLotSize[language]}: <span>{selectedHouse.lotSize}</span> <small>m<sup>2</sup></small></p>
                    <p>{text.chooseHouse.inputLotModel[language]}: <span>{selectedHouse.villa}</span></p>
                    <p>{text.chooseHouse.addLot[language]}: <span>{additionalLot ? '' : 'No'}</span></p>
                    {
                        additionalLot ? 
                            <>
                                <p>{text.chooseHouse.block[language]}: <span>{info.additionalBlock}</span></p>
                                <p>{text.chooseHouse.inputLotNumber[language]}: <span>{selectedAdditional.lotNumber}</span></p>
                                <p>{text.chooseHouse.inputLotSize[language]}: <span>{selectedAdditional.lotSize}</span> <small>m<sup>2</sup></small></p>
                            </>
                            :
                            null
                    }
                </div>
            </ConfirmationModal>
        </div>
    )
}

let mapState = ({
    app: {language},
    user: {fetching},
    data: {info, dataUpdated, houses}
}) => ({
    language,
    fetching,
    info,
    dataUpdated,
    houses
})

export default connect(
    mapState,
    {
        getData: getDataAction,
        handleHouse: handleHouseAction,
        handleAddHouse: handleAdditionalHouseAction,
        updateUser: updateUserAction,
        toggleModal: handleConfirmationBoxAction,
        getHouses: getHousesAction,
        handleStep: handleStepAction,
        sendNotification: sendNotificationAction
    }
)(ChooseHouse)
