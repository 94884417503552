import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { changeLangAction } from '../../redux/appDuck'
import { logoutAction } from '../../redux/userDuck'
import { withRouter } from 'react-router-dom'
import text from '../text'
import { Dropdown, Menu, PageHeader } from 'antd'
import { useMediaQuery } from 'react-responsive'
import { HamburgerCollapse } from 'react-animated-burgers'

const AuthNav = ({location, language, info, logout}) => {

    let [activeBurger, setActiveBurger] = useState(false)

    let isDesktop = useMediaQuery({query: '(min-device-width: 950px)'})
    let hamburgerMenu = (
        <Menu onClick={() => setActiveBurger(false)}>
            <Menu.Item key='0'>
                <Link to='/profile' className={`mobile-sidebar-element${location.pathname === '/profile' || location.pathname === '/' ? '-active' : ''}`}>
                    <img src={`/img/sidebar/profile${location.pathname === '/profile' || location.pathname ===  '/' ? '' : '-gray'}.png`} alt="nav element"/>
                    <p>{text.sidebar.profile[language]}</p>
                </Link>
            </Menu.Item>
            <Menu.Item key='1'>
                <Link to='/documentation' className={`mobile-sidebar-element${location.pathname === '/documentation' ? '-active' : ''}`}>
                    <img src={`/img/sidebar/documents${location.pathname === '/documentation' ? '' : '-gray'}.svg`} alt="nav element"/>
                    <p>{text.sidebar.documentation[language]}</p>
                </Link>
            </Menu.Item>
            <Menu.Item key='2'>
                <Link to='/choose' className={`mobile-sidebar-element${location.pathname === '/choose' ? '-active' : ''}`}>
                    <img src={`/img/sidebar/choose${location.pathname === '/choose' ? '' : '-gray'}.png`} alt="nav element"/>
                    <p>{text.sidebar.chooseHouse[language]}</p>
                </Link>
            </Menu.Item>
            {
                info.wantFinance &&
                <Menu.Item key='3'>
                    <Link to='/financing' className={`mobile-sidebar-element${location.pathname.indexOf('/financing') !== -1 ? '-active' : ''}`}>
                        <img src={`/img/sidebar/finance${location.pathname.indexOf('/financing') !== -1 ? '' : '-gray'}.svg`} alt="nav element"/>
                        <p>{text.sidebar.financing[language]}</p>
                    </Link>
                </Menu.Item>
            }
            <Menu.Item key='4'>
                <Link to='/buy' className={`mobile-sidebar-element${location.pathname === '/buy' ? '-active' : ''}`}>
                    <img src={`/img/sidebar/buy${location.pathname === '/buy' ? '' : '-gray'}.svg`} alt="nav element"/>
                    <p>{text.sidebar.buyHouse[language]}</p>
                </Link>
            </Menu.Item>
            <Menu.Item key='5'>
                <Link to='/interior-design' className={`mobile-sidebar-element${location.pathname === '/interior-design' ? '-active' : ''}`}>
                    <img src={`/img/sidebar/intDesign${location.pathname === '/interior-design' ? '' : '-gray'}.svg`} alt="nav element"/>
                    <p>{text.sidebar.interiorDesign[language]}</p>
                </Link>
            </Menu.Item>
            <Menu.Item key='6'>
                <Link to='/propietary-service' className={`mobile-sidebar-element${location.pathname.indexOf('/propietary-service') !== -1 ? '-active' : ''}`}>
                    <img src={`/img/sidebar/ownerServ${location.pathname.indexOf('/propietary-service') !== -1 ? '' : '-gray'}.svg`} alt="nav element"/>
                    <p>{text.sidebar.propietaryService[language]}</p>
                </Link>
            </Menu.Item>
            <Menu.Item key='7'>
                <Link to='/help' className={`mobile-sidebar-element${location.pathname.indexOf('/help') !== -1 ? '-active' : ''}`}>
                    <img src={`/img/sidebar/help${location.pathname.indexOf('/help') !== -1 ? '' : '-gray'}.svg`} alt="nav element"/>
                    <p>{text.sidebar.help[language]}</p>
                </Link>
            </Menu.Item>
        </Menu>
    )

    let menu = () => (
        <Menu>
            <Menu.Item>
                <p className='menu-item-p' onClick={logout}>{text.navbar.logout[language]}</p>
            </Menu.Item>
        </Menu>
    )

    return (
        <div className='auth-nav'>
            <div className="top-nav">
                {
                    !isDesktop ?
                        <>
                        <Link className='top-nav-logo' to='/profile'><img src="/img/common/logo-dark.png" alt="Logo"/></Link>
                        <div className='mobile-right-nav'>
                            <Dropdown overlay={hamburgerMenu} trigger={['click']}>
                                <HamburgerCollapse isActive={activeBurger} onClick={() => setActiveBurger(!activeBurger)} className='hamburger' buttonWidth={30}/>
                            </Dropdown>
                            <Dropdown overlay={menu} trigger={['click']}>
                                <img style={{cursor: 'pointer'}} src="/img/navbar/profile.svg" alt="profile"/>
                            </Dropdown>
                        </div>
                        </>
                    :
                        <Dropdown overlay={menu} trigger={['click']}>
                            <img style={{cursor: 'pointer'}} src="/img/navbar/profile.svg" alt="profile"/>
                        </Dropdown>
                }
            </div>
            <div className="left-nav">
                <img onClick={logout} className='sidebar-logo' src="/img/common/logo-dark.png" alt="PP logo black"/>
                <div className="sidebar-nav">
                    {/* UNCOMMENT TO ALLOW AUTH */}
                    {/* <Link to='/profile' className={`sidebar-element${location.pathname === '/profile' || location.pathname === '/' ? '-active' : ''}`}>
                        <img src={`/img/sidebar/profile${location.pathname === '/profile' || location.pathname ===  '/' ? '' : '-gray'}.png`} alt="nav element"/>
                        <p>{text.sidebar.profile[language]}</p>
                    </Link>
                    <Link to='/documentation' className={`${info.step > 0 ? '' : 'link-disabled'} sidebar-element${location.pathname === '/documentation' ? '-active' : ''}`}>
                        <img src={`/img/sidebar/documents${location.pathname === '/documentation' ? '' : '-gray'}.svg`} alt="nav element"/>
                        <p>{text.sidebar.documentation[language]}</p>
                    </Link>
                    <Link to='/choose' className={`${info.step > 1 ? '' : 'link-disabled'} sidebar-element${location.pathname === '/choose' ? '-active' : ''}`}>
                        <img src={`/img/sidebar/choose${location.pathname === '/choose' ? '' : '-gray'}.png`} alt="nav element"/>
                        <p>{text.sidebar.chooseHouse[language]}</p>
                    </Link>
                    {
                        info.wantFinance &&
                            <Link to='/financing' className={`${info.step > 2 ? '' : 'link-disabled'} sidebar-element${location.pathname.indexOf('/financing') !== -1 ? '-active' : ''}`}>
                                <img src={`/img/sidebar/finance${location.pathname.indexOf('/financing') !== -1 ? '' : '-gray'}.svg`} alt="nav element"/>
                                <p>{text.sidebar.financing[language]}</p>
                            </Link>
                    }
                    <Link to='/buy' className={`${info.step > 3 ? '' : 'link-disabled'} sidebar-element${location.pathname === '/buy' ? '-active' : ''}`}>
                        <img src={`/img/sidebar/buy${location.pathname === '/buy' ? '' : '-gray'}.svg`} alt="nav element"/>
                        <p>{text.sidebar.buyHouse[language]}</p>
                    </Link>
                    <Link to='/interior-design' className={`sidebar-element${location.pathname === '/interior-design' ? '-active' : ''}`}>
                        <img src={`/img/sidebar/intDesign${location.pathname === '/interior-design' ? '' : '-gray'}.svg`} alt="nav element"/>
                        <p>{text.sidebar.interiorDesign[language]}</p>
                    </Link>
                    <Link to='/propietary-service' className={`sidebar-element${location.pathname.indexOf('/propietary-service') !== -1 ? '-active' : ''}`}>
                        <img src={`/img/sidebar/ownerServ${location.pathname.indexOf('/propietary-service') !== -1 ? '' : '-gray'}.svg`} alt="nav element"/>
                        <p>{text.sidebar.propietaryService[language]}</p>
                    </Link>
                    <Link to='/help' className={`sidebar-element${location.pathname.indexOf('/help') !== -1 ? '-active' : ''}`}>
                        <img src={`/img/sidebar/help${location.pathname.indexOf('/help') !== -1 ? '' : '-gray'}.svg`} alt="nav element"/>
                        <p>{text.sidebar.help[language]}</p>
                    </Link> */}
                    <Link to='/profile' className={`sidebar-element${location.pathname === '/profile' || location.pathname === '/' ? '-active' : ''}`}>
                        <img src={`/img/sidebar/profile${location.pathname === '/profile' || location.pathname ===  '/' ? '' : '-gray'}.png`} alt="nav element"/>
                        <p>{text.sidebar.profile[language]}</p>
                    </Link>
                    <Link to='/documentation' className={`sidebar-element${location.pathname === '/documentation' ? '-active' : ''}`}>
                        <img src={`/img/sidebar/documents${location.pathname === '/documentation' ? '' : '-gray'}.svg`} alt="nav element"/>
                        <p>{text.sidebar.documentation[language]}</p>
                    </Link>
                    <Link to='/choose' className={`sidebar-element${location.pathname === '/choose' ? '-active' : ''}`}>
                        <img src={`/img/sidebar/choose${location.pathname === '/choose' ? '' : '-gray'}.png`} alt="nav element"/>
                        <p>{text.sidebar.chooseHouse[language]}</p>
                    </Link>
                    {
                        info.wantFinance &&
                            <Link to='/financing' className={`sidebar-element${location.pathname.indexOf('/financing') !== -1 ? '-active' : ''}`}>
                                <img src={`/img/sidebar/finance${location.pathname.indexOf('/financing') !== -1 ? '' : '-gray'}.svg`} alt="nav element"/>
                                <p>{text.sidebar.financing[language]}</p>
                            </Link>
                    }
                    <Link to='/buy' className={`sidebar-element${location.pathname === '/buy' ? '-active' : ''}`}>
                        <img src={`/img/sidebar/buy${location.pathname === '/buy' ? '' : '-gray'}.svg`} alt="nav element"/>
                        <p>{text.sidebar.buyHouse[language]}</p>
                    </Link>
                    <Link to='/interior-design' className={`sidebar-element${location.pathname === '/interior-design' ? '-active' : ''}`}>
                        <img src={`/img/sidebar/intDesign${location.pathname === '/interior-design' ? '' : '-gray'}.svg`} alt="nav element"/>
                        <p>{text.sidebar.interiorDesign[language]}</p>
                    </Link>
                    <Link to='/propietary-service' className={`sidebar-element${location.pathname.indexOf('/propietary-service') !== -1 ? '-active' : ''}`}>
                        <img src={`/img/sidebar/ownerServ${location.pathname.indexOf('/propietary-service') !== -1 ? '' : '-gray'}.svg`} alt="nav element"/>
                        <p>{text.sidebar.propietaryService[language]}</p>
                    </Link>
                    <Link to='/help' className={`sidebar-element${location.pathname.indexOf('/help') !== -1 ? '-active' : ''}`}>
                        <img src={`/img/sidebar/help${location.pathname.indexOf('/help') !== -1 ? '' : '-gray'}.svg`} alt="nav element"/>
                        <p>{text.sidebar.help[language]}</p>
                    </Link>
                </div>
            </div>
        </div>
    )
}

let mapState = ({
    app: {language},
    data: {info}
}) => ({
    language,
    info
})

export default connect(
    mapState,
    {
        toggleLanguage: changeLangAction,
        logout: logoutAction
    }
)(withRouter(AuthNav))
