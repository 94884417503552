import React, { useState } from 'react'
import { Checkbox } from 'antd'
import ReactImageMagnify from 'react-image-magnify'
import text from '../../text'
import { connect } from 'react-redux'
import { handleHouseApprovalsAction } from '../../../redux/dataDuck'

const BuyHouseMap = ({info, language, house, handleInfo}) => {

    let [selectedImage, setSelectedImage] = useState(house.images[0])
    let [showImage, setShowImage] = useState(false)

    return (
        <div className='buy-map-container'>
            <div className="left-buy-map">
                <div className="description-thumbs">
                    {
                        house.images.map((imageLink, ix) => 
                            <div style={{backgroundImage: `url(${imageLink})`}} key={ix} className={selectedImage === imageLink ? 'thumb-img-active' : 'thumb-img'} onClick={() => setSelectedImage(imageLink)} />
                        )
                    }
                </div>
                <div onClick={() => setShowImage(true)} className="description-big-image">
                    <ReactImageMagnify
                        onClick={() => setShowImage(true)}
                        {...{
                            smallImage: {
                                alt: 'Map',
                                isFluidWidth: true,
                                src: selectedImage
                            },
                            largeImage: {
                                src: selectedImage,
                                width: 1200,
                                height: 1200
                            },
                            enlargedImageContainerDimensions: {
                                width: 400,
                                height: '100%'
                            }
                        }} 
                    />
                </div>
                <div className="description-attributes">
                    <div className="attributes-title-container">
                        <div className="house-title">
                            <h2>{house.name}</h2>
                            <p>{text.buyHouse.titlePrice[language]}: {house.price.toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</p>
                        </div>
                        <div className="house-availability">
                            <p>{house.available ? text.buyHouse.available[language] : text.buyHouse.notAvailable[language]}</p>
                        </div>
                    </div>
                    <hr/>
                    <div className="house-properties-container">
                        <div className="house-property">
                            <img src="/img/buyHouse/ruler.svg" alt="ruler"/>
                            <p>{house.lotSize} m<sup>2</sup></p>
                        </div>
                        <div className="house-property">
                            <img src="/img/buyHouse/bed.svg" alt="ruler"/>
                            <p>{house.bedrooms} {text.buyHouse.bedrooms[language]}</p>
                        </div>
                        <div className="house-property">
                            <img src="/img/buyHouse/bathtub.svg" alt="ruler"/>
                            <p>{house.bathrooms} {text.buyHouse.bathrooms[language]}</p>
                        </div>
                        <div className="house-property">
                            <img src="/img/buyHouse/car.svg" alt="ruler"/>
                            <p>{house.cars} {text.buyHouse.cars[language]}</p>
                        </div>
                    </div>
                    {/* <div className="price">
                        <p>{text.buyHouse.price[language]}</p>
                        <h2>{house.price.toLocaleString('en-US', {style: 'currency', currency: 'USD'})} USD</h2>
                    </div> */}
                </div>
            </div>
            <div className="right-buy-map">
                <div className="remember-container">
                    <div className="remember-title">
                        <img src="/img/buyHouse/bulb.svg" alt="bulb"/>
                        <p>{text.buyHouse.remember[language]}</p>
                    </div>
                    <div className="remember-description">
                        <p>{text.buyHouse.docsDescription[language]}</p>
                        <ul>
                            <li>{text.buyHouse.doc1[language]}</li>
                            <li>{text.buyHouse.doc2[language]}</li>
                            <li>{text.buyHouse.doc3[language]}</li>
                            <li>{text.buyHouse.doc4[language]}</li>
                        </ul>
                    </div>
                </div>
                <div className="remember-container">
                    <div className="remember-title">
                        <img src="/img/buyHouse/bulb.svg" alt="bulb"/>
                        <p>{text.buyHouse.approveTitle[language]}</p>
                    </div>
                    <div className="remember-description">
                        <p>{text.buyHouse.approvalDescription[language]}</p>
                    </div>
                    <div className="checkbox-input">
                        <Checkbox
                            onChange={e => handleInfo(e)}
                            checked={info.villaApproved}
                        >
                            {text.buyHouse.firstApproval[language]}
                        </Checkbox>
                    </div>
                    <div className="checkbox-input">
                        <Checkbox
                            onChange={e => handleInfo(null, e)}
                            checked={info.priceApproved}
                        >
                            {text.buyHouse.secondApproval[language]}
                        </Checkbox>
                    </div>
                </div>
            </div>
            {
                showImage && 
                <div className='image-dialog'>
                    <div onClick={() => setShowImage(false)} className="close-button">
                        <p>x</p>
                    </div>
                    <img src={selectedImage} alt="full-map"/>
                </div>
            }
        </div>
    )
}

let mapState = ({
    data: {info}
}) => ({
    info
})

export default connect(
    mapState,
    {
        handleInfo: handleHouseApprovalsAction
    }
)(BuyHouseMap)