import { Button, Input, Spin } from 'antd'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getClientDataAction } from '../../../redux/dataDuck'
import text from '../../text'

const AdminDocuments = ({language, fetching, selectedUserData, error, getData}) => {

    useEffect(() => {
        getData()
    }, [])
    return (
        <div className='application-container'>
             {
                fetching ? 
                    <div className='spinner-full-page'><Spin size='large'/></div>
                    :
                    <div className="application-content">
                        <div>
                            <div className="app-top-icon">
                                <img src="/img/sidebar/documents.svg" alt="profile"/>
                                <p>{text.sidebar.clientDocuments[language]}</p>
                            </div>
                            <h2>{selectedUserData.name} {selectedUserData.lastName}</h2>
                        </div>
                        <div className='padded-app-container form-container'>
                            <div className="file-manager">
                                <p><img src="/img/files/folder.svg" alt="folder"/> {text.documents.genDocs[language]}</p>
                                <ul className='file-manager-files'>
                                    <li><p>{text.documentation.firstDoc.title[language]}: {selectedUserData.files && <span>{selectedUserData.files.passport} <img src="/img/files/open.svg" alt="open"/></span>}</p></li>
                                    <li><p>{text.documentation.secondDoc.title[language]}: {selectedUserData.files && <span>{selectedUserData.files.driversLicence} <img src="/img/files/open.svg" alt="open"/></span>}</p></li>
                                    <li><p>{text.documentation.thirdDoc.title[language]}: {selectedUserData.files && <span>{selectedUserData.files.addressProof} <img src="/img/files/open.svg" alt="open"/></span>}</p></li>
                                </ul>
                                <br/>
                                <p><img src="/img/files/folder.svg" alt="folder"/> {text.documents.financialDocs[language]}</p>
                                <ul className='file-manager-files'>
                                    {
                                        !selectedUserData.wantFinance ? 
                                            <li><p>{text.documents.noFinancial[language]}</p></li>
                                        : 
                                            null
                                    }
                                    {
                                        selectedUserData.economicProfile === 'nat-salaried' ? 
                                            text.financing.nationalSalariedDocs.map(doc => 
                                                <li key={doc.id}><span>{selectedUserData.files && selectedUserData.files[doc.id]} {selectedUserData.files && selectedUserData.files[doc.id] && <img src="/img/files/open.svg" alt="open"/>}</span></li>
                                            )
                                        :
                                            null
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="small-buttons multiple-buttons">
                            <Link to='/admin/data'><Button loading={fetching} className='nav-button'>{text.button.returnCaps[language]}</Button></Link>
                            <Link to='/admin/choose'><Button className='nav-button'>{text.button.continue[language]}</Button></Link>
                        </div>
                    </div>
            }
        </div>
    )
}

let mapState = ({
    app: {language},
    data: {fetching, selectedUserData, error}
}) => ({
    language,
    fetching,
    selectedUserData,
    error
})

export default connect(
    mapState,
    {
        getData: getClientDataAction
    }
)(AdminDocuments)
