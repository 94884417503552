import Modal from 'antd/lib/modal/Modal'
import React from 'react'
import { connect } from 'react-redux'
import { handleConfirmationBoxAction } from '../../redux/appDuck'
import text from '../text'

const PreviewModal = ({language, visible, children, setVisible, okText}) => {
    return (
        <Modal
            title={okText ? '' : text.modal.confirmText[language]}
            visible={visible}
            onCancel={() => setVisible(false)}
            okText={okText ? okText : text.modal.okButton[language]}
            onOk={() => setVisible(false)}
            className='preview-modal'
        >
            {children}
        </Modal>
    )
}

let mapState = ({
    app: {language}
}) => ({
    language
})

export default connect(
    mapState,
    {
        toggleBox: handleConfirmationBoxAction
    }
)(PreviewModal)
