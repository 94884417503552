import React, { useEffect, useState } from 'react'
import { Button, message, Spin, Upload } from 'antd'
import { connect } from 'react-redux'
import { deleteFileAction, getDataAction, getFileAction, handleDocAction, handleFileAction, handleInfoAction, handleStepAction, sendNotificationAction, updateUserAction } from '../../redux/dataDuck'
import text from '../text'
import { Link } from 'react-router-dom'

let { Dragger } = Upload

const Documentation = ({step, sendNotification, history, files={}, fetching, dataUpdated, language, getData, handleFile, getFile, deleteFile, handleStep}) => {

    let handleSubmit = async () => {
        if(!files.passport || !files.driversLicence || !files.addressProof) return message.error(text.messages.missingFiles[language])
        if(step === 1) {
            sendNotification('finishProfile')
            await handleStep(2)
        }
        history.push('/choose')
    }

    let handleUpload = (info, type) => {
        sendNotification(type)
        handleFile(info.file.originFileObj, type)
    }

    useEffect(() => {
        if(dataUpdated) return message.success(text.messages.fileUploaded[language])
    }, [dataUpdated])

    useEffect(() => {
        getData()
    }, [])

    return (
        <div className='application-container'>
             {
                fetching ? 
                    <div className='spinner-full-page'><Spin size='large'/></div>
                    :
                    <div className="application-content no-justify">
                        <div>
                            <div className="app-top-icon">
                                <img src="/img/sidebar/documents.svg" alt="profile"/>
                                <p>{text.sidebar.documentation[language]}</p>
                            </div>
                            <h2>{text.sidebarSubtitle.documentation[language]}</h2>
                        </div>
                        <div className='padded-app-container form-container'>
                            <div className="documents-box-container">
                                <div className="doc-box">
                                    <Dragger
                                        accept='image/png, image/jpeg, .pdf'
                                        onChange={info => handleUpload(info, 'passport')}
                                        fileList={[]}
                                        name='passport'
                                    >
                                        <div className='doc-box-elements'>
                                            <p className='doc-box-title'>{text.documentation.firstDoc.title[language]}</p>
                                            <img src={`/img/documentation/upload${files.passport ? 'ed' : ''}.png`} alt="upload"/>
                                            <p className='doc-box-instructions'>{text.documentation.firstDoc.inst[language]}</p>
                                            <p className='doc-box-mb'>{text.documentation.mbText[language]}</p>
                                            <Button className='upload-button'> <img src='/img/documentation/folder.svg' alt='folder'/> {text.documentation.blowseButon[language]}</Button>
                                        </div>
                                    </Dragger>
                                </div>
                                <div className="doc-box">
                                    <Dragger
                                        accept='image/png, image/jpeg, .pdf'
                                        onChange={info => handleUpload(info, 'driversLicence')}
                                        fileList={[]}
                                        name='driversLicence'
                                    >
                                        <div className='doc-box-elements'>
                                            <p className='doc-box-title'>{text.documentation.secondDoc.title[language]}</p>
                                            <img src={`/img/documentation/upload${files.driversLicence ? 'ed' : ''}.png`} alt="upload"/>
                                            <p className='doc-box-instructions'>{text.documentation.secondDoc.inst[language]}</p>
                                            <p className='doc-box-mb'>{text.documentation.mbText[language]}</p>
                                            <Button className='upload-button'> <img src='/img/documentation/folder.svg' alt='folder'/> {text.documentation.blowseButon[language]}</Button>
                                        </div>
                                    </Dragger>
                                </div>
                                <div className="doc-box">
                                    <Dragger
                                        accept='image/png, image/jpeg, .pdf'
                                        onChange={info => handleUpload(info, 'addressProof')}
                                        fileList={[]}
                                        name='addressProof'
                                    >
                                        <div className='doc-box-elements'>
                                            <p className='doc-box-title'>{text.documentation.thirdDoc.title[language]}</p>
                                            <img src={`/img/documentation/upload${files.addressProof ? 'ed' : ''}.png`} alt="upload"/>
                                            <p className='doc-box-instructions'>{text.documentation.thirdDoc.inst[language]}</p>
                                            <p className='doc-box-mb'>{text.documentation.mbText[language]}</p>
                                            <Button className='upload-button'> <img src='/img/documentation/folder.svg' alt='folder'/> {text.documentation.blowseButon[language]}</Button>
                                        </div>
                                    </Dragger>
                                </div>
                            </div>
                        </div>
                        <ul className='docs-list-ul'>
                            {files.passport && <li>{text.documentation.firstDoc.title[language]}: <strong>{files.passport}</strong> <img onClick={() => deleteFile(files.passport)} src='/img/files/delete.svg' alt='delete'/></li>}
                            {files.driversLicence && <li>{text.documentation.secondDoc.title[language]}: <strong>{files.driversLicence}</strong> <img onClick={() => deleteFile(files.driversLicence)} src='/img/files/delete.svg' alt='delete'/></li>}
                            {files.addressProof && <li>{text.documentation.thirdDoc.title[language]}: <strong>{files.addressProof}</strong> <img onClick={() => deleteFile(files.addressProof)} src='/img/files/delete.svg' alt='delete'/></li>}
                        </ul>
                        <div className="bottom-buttons small-buttons multiple-buttons">
                            <Link to='/profile'><Button className='nav-button'>{text.button.returnCaps[language]}</Button></Link>
                            <Button onClick={handleSubmit} loading={fetching} className='nav-button'>{text.button.continue[language]}</Button>
                        </div>
                    </div>
            }
        </div>
    )
}

let mapState = ({
    app: {language},
    user: {fetching},
    data: {info: {files, step}, dataUpdated}
}) => ({
    language,
    fetching,
    files,
    dataUpdated,
    step
})

export default connect(
    mapState,
    {
        getData: getDataAction,
        handleInfo: handleInfoAction,
        updateUser: updateUserAction,
        handleFile: handleDocAction,
        getFile: getFileAction,
        deleteFile: deleteFileAction,
        handleStep: handleStepAction,
        sendNotification: sendNotificationAction
    }
)(Documentation)
