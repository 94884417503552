import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import userReducer from './userDuck'
import appReducer from './appDuck'
import dataReducer from './dataDuck'
import adminReducer from './adminDuck'
import generalReducer from './generalDataDuck'

let rootReducer = combineReducers({
    app: appReducer,
    user: userReducer,
    data: dataReducer,
    admin: adminReducer,
    general: generalReducer
})

let composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

let middlewares = [thunk]

let generateStore = () => createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middlewares))
)

let generateStoreProduction = () => createStore(
    rootReducer,
    applyMiddleware(...middlewares)
)

export default process.env.NODE_ENV === 'development' ? generateStore : generateStoreProduction