import React, { useState } from 'react'
import { Button, message } from 'antd'
import { connect } from 'react-redux'
import text from '../text'
import { Link } from 'react-router-dom'

let Register = ({history, language}) => {

    let [role, setRole] = useState(null)

    let selectRole = role => {
        return history.push(`/register/${role}`)
    }

    return (
        <div className='register-container'>
            <div className="left-register register-bg">
                <img src="/img/common/logo-white.png" alt="punta piedra logo"/>
            </div>
            <div className="right-register">
                <img className='title-icon' src="/img/register/profile.png" alt="Perfil"/>
                <p className='title'>{text.register.portalTitle[language]}</p>
                <div className="roles-boxes">
                    <div onClick={() => selectRole('buyer')} className={`role-box ${role === 'buyer' && 'rb-active'}`}>
                        <p>{text.roles.buyer[language]}</p>
                        <img src={role === 'buyer' ? "/img/register/buyer-active.png" : "/img/register/buyer.png"} alt="Buyer"/>
                    </div>
                    <div onClick={() => selectRole('agent')} className={`role-box ${role === 'agent' && 'rb-active'}`}>
                        <p>{text.roles.agent[language]}</p>
                        <img src={role === 'agent' ? "/img/register/agent-active.png" : "/img/register/agent.png"} alt="agent"/>
                    </div>
                    <div onClick={() => selectRole('provider')} className={`role-box ${role === 'provider' && 'rb-active'}`}>
                        <p>{text.roles.serviceProvider[language]}</p>
                        <img src={role === 'provider' ? "/img/register/provider-active.png" : "/img/register/provider.png"} alt="provider"/>
                    </div>
                </div>
                <br/>
                <div className="auth-bottom-text">
                    <p>{text.register.login[language]} <Link to='/login'>{text.register.loginLink[language]}</Link></p>
                </div>
            </div>
        </div>
    )
}

let mapState = ({app: {language}}) => ({language})

export default connect(
    mapState,
    {
        
    }
)(Register)