export let buyerCodes = [
    '148760',
    '148773',
    '148786',
    '148799',
    '148812',
    '148825',
    '148838',
    '148851',
    '148864',
    '148877',
    '148890',
    '148903',
    '148916',
    '148929',
    '148942',
    '148955',
    '148968',
    '148981',
    '148994',
    '149007',
    '149020',
    '149033',
    '149046',
    '149059',
    '149072',
    '149085',
    '149098',
    '149111',
    '149124',
    '149137',
    '149150',
    '149163',
    '149176',
    '149189',
    '149202',
    '149215',
    '149228',
    '149241',
    '149254',
    '149267',
    '149280',
    '149293',
    '149306',
    '149319',
    '149332',
    '149345',
    '149358',
    '149371',
    '149384',
    '149397',
    '149410'
]

export let providerCodes = [
    '26489',
    '26529',
    '26569',
    '26609',
    '26649',
    '26689',
    '26729',
    '26769',
    '26809',
    '26849',
    '26889'
]

export let agentCodes = [
    '38925',
    '39092',
    '39259',
    '39426',
    '39593',
    '39760',
    '39927',
    '40094',
    '40261',
    '40428',
    '40595',
    '40762',
    '40929',
    '41096',
    '41263',
    '41430',
    '41597',
    '41764',
    '41931',
    '42098',
    '42265',
    '42432',
    '42599',
    '42766',
    '42933',
    '43100',
    '43267',
    '43434',
    '43601',
    '43768',
    '43935',
    '44102',
    '44269',
    '44436',
    '44603',
    '44770',
    '44937',
    '45104',
    '45271',
    '45438',
    '45605',
    '45772',
    '45939',
    '46106',
    '46273',
    '46440',
    '46607',
    '46774',
    '46941',
    '47108',
    '47275',
    '47442',
    '47609',
    '47776',
    '47943',
    '48110',
    '48277',
    '48444',
    '48611',
    '48778',
    '48945',
    '49112',
    '49279',
    '49446',
    '49613',
    '49780',
    '49947',
    '50114',
    '50281',
    '50448',
    '50615',
    '50782',
    '50949',
    '51116',
    '51283',
    '51450',
    '51617',
    '51784',
    '51951',
    '52118',
    '52285',
    '52452',
    '52619',
    '52786',
    '52953',
    '53120',
    '53287',
    '53454',
    '53621',
    '53788',
    '53955',
    '54122',
    '54289',
    '54456',
    '54623',
    '54790',
    '54957',
    '55124',
    '55291'
]