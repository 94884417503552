import React, { useEffect, useState } from 'react'
import { Input, Button, message, Select, Checkbox, Modal } from 'antd'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { checkEmailAction, signupAction } from '../../../redux/userDuck'
import { getAgentsAction } from '../../../redux/generalDataDuck'
import text from '../../text'
import { changeLangAction } from '../../../redux/appDuck'
import { MaskedInput } from 'antd-mask-input'
import { buyerCodes } from './codes'

let { Option } = Select

let BasicData = ({fetching, language, error, info, fetchingGeneral, errorGeneral, agents, signup, history, getAgents, changeLanguage, checkEmail, notRepeatedEmail}) => {

    let [code, setCode] = useState('')
    let [data, setData] = useState({
        userType: 'buyer'
    })

    let handleData = e => {
        setData({...data, [e.target.name]: e.target.value})
    }

    let handleSubmit = () => {
        if(!data.name ||
             !data.lastName ||
             (!data.secondLastName && language === 'español') ||
             !data.email ||
             !data.phone
            ) 
            return message.warning(text.messages.missingFields[language])
        if(data.phone.length < 10) return message.warning(text.messages.wrongInfo[language])
        let rex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!rex.test(data.email.toLowerCase())) return message.warn(text.messages.incorrectEmail[language])
        if(!data.password || data.password.length < 6) return message.warn(text.messages.incorrectPassword[language])
        if(data.password !== data.repeatPassword) return message.warn(text.messages.repeatedPasswordError[language])
        if(!buyerCodes.find(el => el === code)) return message.error(text.register.codeError[language])
        if(!data.consent) return message.warn(text.messages.consent[language])
        checkEmail(data.email.toLowerCase())
    }

    let handleModal = () => {
        Modal.success({
            title: text.messages.thanks[language],
            onOk() {
                signup(data)
            }
        })
    }

    let handleCode = e => {
        setCode(e.target.value)
    }

    useEffect(() => {
        if(notRepeatedEmail) return handleModal()
        if(error.status === 200) return message.error('A user with the given username is already registered')
        if(error.status === 500) return message.error('Server error, try again later')
        if(info.name) return history.push('/profile')
    }, [error, info, notRepeatedEmail])

    useEffect(() => {
        getAgents()
    }, [])

    return (
        <div className='form-container'>
            <div className="multiple-inputs">
                <Input onChange={handleData} value={data.name} name='name' placeholder={text.register.firstName[language]}/>
                <Input className={`${language === 'english' && 'last-input'}`} onChange={handleData} value={data.lastName} name='lastName' placeholder={text.register.lastName[language]}/>
                {language === 'español' && <Input required onChange={handleData} value={data.secondLastName} name='secondLastName' placeholder={text.register.secondLastName[language]}/>}
            </div>
            <div className="multiple-inputs">
                <Input onChange={handleData} value={data.email} name='email' placeholder={text.register.email[language]}/>
                {
                    language === 'english' ?
                        <MaskedInput className='last-input' mask="(111) 111-1111" name="phone" onChange={handleData} value={data.phone} placeholder={text.register.phone[language]}/>
                    :
                        <MaskedInput className='last-input' mask="(11) 1111-1111" name="phone" onChange={handleData} value={data.phone} placeholder={text.register.phone[language]}/>
                }   
            </div>
            <div className="multiple-inputs">
                <Select className='agent-selector' loading={fetchingGeneral} onChange={value => setData({...data, customerAgent: value})} placeholder={text.register.selectAgent[language]} value={data.customerAgent}>
                    {agents.map(
                        agent => 
                            <Option key={agent._id} value={agent._id}>
                                {agent.name}
                            </Option>
                    )}
                </Select>
                &nbsp;
                <Select className='agent-selector' onChange={value => {setData({...data, language: value}); changeLanguage(value)}} placeholder={text.register.language[language]} value={data.language} defaultValue={data.language}>
                    <Option value='english'>English</Option>
                    <Option value='español'>Español</Option>
                </Select>
            </div>
            <Input.Password onChange={handleData} value={data.password} name='password' placeholder={text.register.password[language]}/>
            <Input.Password onChange={handleData} value={data.repeatPassword} name='repeatPassword' placeholder={text.register.repeatPassword[language]}/>
            <Input onChange={handleCode} value={code} name='code' placeholder={text.register.code[language]}/>
            <div className="align-checkbox">
                <div className="checkbox-input">
                    <Checkbox
                        checked={data.wantFinance}
                        onChange={e => setData({...data, wantFinance: e.target.checked})}
                    >
                        {text.register.wantFinance[language]}
                    </Checkbox>
                </div>
                <div className="checkbox-input">
                    <Checkbox
                        checked={data.consent}
                        onChange={e => setData({...data, consent: e.target.checked})}
                    >
                        {text.register.confirm[language]}
                    </Checkbox>
                </div>
                <div className="checkbox-input">
                    <Checkbox
                        checked={data.allowNewsletter}
                        onChange={e => setData({...data, allowNewsletter: e.target.checked})}
                    >
                        {text.register.allowNewsletter[language]}
                    </Checkbox>
                </div>
            </div>
            <Button loading={fetching} onClick={handleSubmit} className='nav-button'>{text.button.register[language]}</Button>
            <div className="auth-bottom-text">
                <p>{text.register.login[language]} <Link to='/login'>{text.register.loginLink[language]}</Link></p>
            </div>
        </div>
    )
}

let mapState = ({
    user: {fetching, error, info, notRepeatedEmail},
    app: {language},
    general: {fetchingGeneral, errorGeneral, agents}
}) => ({
    fetching,
    error, 
    info,
    language,
    fetchingGeneral,
    errorGeneral,
    agents,
    notRepeatedEmail
})

export default connect(
    mapState,
    {
        signup: signupAction,
        getAgents: getAgentsAction,
        changeLanguage: changeLangAction,
        checkEmail: checkEmailAction
    }
)(BasicData)