import axios from 'axios'

// let url = 'http://localhost:3000'
let url = 'https://app.puntapiedra.com'

// AUTH
export let signup = data => axios.post(`${url}/auth/signup`, data)
export let login = data => axios.post(`${url}/auth/login`, data)
export let token = data => axios.post(`${url}/auth/token`, data)
export let checkCode = data => axios.post(`${url}/auth/checkCode`, data)
export let changePassword = data => axios.post(`${url}/auth/changePassword`, data)
export let updateUser = data => axios.post(`${url}/auth/updateUser`, data)
export let linkAgent = (agentId, userId) => axios.post(`${url}/auth/linkAgent`, ({agentId, userId}))
export let logout = () => axios.get(`${url}/auth/logout`)
export let checkEmail = email => axios.post(`${url}/auth/checkEmail`, ({email}))

// ADMIN
export let addHouse = data => axios.post(`${url}/admin/addHouse`, data)
export let getHouse = data => axios.post(`${url}/admin/getHouse`, data)
export let sendQuestion = (email, question) => axios.post(`${url}/admin/sendQuestion`, ({email, question}))
export let getAllMessages = () => axios.post(`${url}/admin/getAllMessages`)
export let getMessages = email => axios.post(`${url}/admin/getMessages`, ({email}))
export let getSingleMessage = id => axios.post(`${url}/admin/getSingleMessage`, ({id}))
export let sendMessage = (id, email, message) => axios.post(`${url}/admin/sendMessage`, ({id, email, message}))
export let sendAdminMessage = (id, message) => axios.post(`${url}/admin/sendAdminMessage`, ({id, message}))

// DATA
export let getData = data => axios.post(`${url}/data/getData`, data)
export let getHouseData = data => axios.post(`${url}/data/getHouse`, data)
export let getUserDocs = data => axios.post(`${url}/data/getUserDocs`, data)
export let uploadFile = (file, email) => axios.post(`${url}/data/uploadFile/${email}`, file)
export let getFile = (name, email) => axios.post(`${url}/data/getFile/${email}`, {name}, {responseType: 'arraybuffer'})
export let deleteFile = (name, email) => axios.post(`${url}/data/deleteFile/${email}`, {name})

// GENERAL CONSULTS
export let getAgents = () => axios.get(`${url}/consults/getAgents`)
export let getLanguage = data => axios.post(`${url}/consults/getLanguage`, data)
export let getHouses = () => axios.get(`${url}/consults/getHouses`)

//NOTIFICATONS
export let sendNotification = (email, data) => axios.post(`${url}/notifications/send`, ({email, data}))

// ADMIN
export let getUsers = () => axios.get(`${url}/data/getUsers`)
export let getClientData = data => axios.post(`${url}/data/getClientData`, data)
export let updateClient = (id, data) => axios.post(`${url}/data/updateClient`, ({id, data}))
