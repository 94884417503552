import React, { useState } from 'react'
import ReactImageMagnify from 'react-image-magnify'
import text from '../../text'

export const ChooseHouseMap = ({selectedHouse, language}) => {
    let [showImage, setShowImage] = useState(false)
    return (
        <>
        <div className='house-map-container'>
            <div onClick={() => setShowImage(true)} className="left-house-map">
                <ReactImageMagnify
                {...{
                    smallImage: {
                        alt: 'Map',
                        isFluidWidth: true,
                        src: `/img/chooseHouse/${
                            selectedHouse.villa ?
                                selectedHouse.villa.includes('Crux') || selectedHouse.villa.includes('Aries') ? 
                                    'map'
                                    :
                                    'map'
                            :   
                                'map'
                        }.png`
                    },
                    largeImage: {
                        src: `/img/chooseHouse/${
                            selectedHouse.villa ?
                                selectedHouse.villa.includes('Crux') || selectedHouse.villa.includes('Aries') ? 
                                    'map'
                                    :
                                    'map'
                            :   
                                'map'
                        }.png`,
                        width: 1200,
                        height: 1800
                    },
                    enlargedImageContainerDimensions: {
                        width: '40%',
                        height: '100%'
                    }
                }} 
                />
            </div>
            {
                showImage && 
                <div className='image-dialog'>
                    <div onClick={() => setShowImage(false)} className="close-button">
                        <p>x</p>
                    </div>
                    <img src={`/img/chooseHouse/${
                                selectedHouse.villa ?
                                    selectedHouse.villa.includes('Crux') || selectedHouse.villa.includes('Aries') ? 
                                        'map'
                                        :
                                        'map'
                                :   
                                    'map'
                            }.png`} alt="full-map"/>
                </div>
            }
            <div className="right-house-map">
                <div className="right-house-map--img">
                    <div style={selectedHouse.villa ? {backgroundImage: `url(/img/chooseHouse/${selectedHouse.villa.toLowerCase()}1.jpg)`} : null} className="no-image"/>
                </div>
                <div>
                    <div className="map-color-description">
                        <div className="occupied-box"/>
                        <p>{text.chooseHouse.forSale[language]}</p>
                    </div>
                    <div className="map-color-description">
                        <div className="available-box"/>
                        <p>{text.chooseHouse.occupied[language]}</p>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
